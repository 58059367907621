import React, { useEffect, useState } from 'react';
import { Button } from "bootstrap-4-react/lib/components";
import { useNavigate } from 'react-router';
import UploadCsvBarcode from './uploadCsv';
import { Table } from "bootstrap-4-react/lib/components";
import { useDispatch, useSelector } from 'react-redux';
import { barCodeUploadBackDetails, getAllBarcodeColumnsList } from '../../../store/actions/barcode.action';
import LoaderSpinner from '../../common/Loader';
import moment from 'moment';
import BatchPagination from '../../batch/BatchPagination';
import { barCode, featureSubmodulePermission, numOfPage, permssionCreate, permssionView, SUBF_BARCODEUPLOAD } from '../../../constant';
import { checkPermission, renderTableRow } from '../../../config/utils';
import ActionItems from '../../common/showActions';
import _ from 'lodash';

const BarCodeList = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [totalPages, setTotalPages] = useState(1);
    let dataLoad = {
        currentPage: 1,
        numberOfPage: 30,
        sortOrder: 'DESCENDING',
        sortColumn: '',
        totalPages: 0,
    }
    const [listDetails, setListDetails] = useState({});

    const { barCodeListingStatus, barCodeListing, csvUploadBackDetails } = useSelector((state) => state.barcodeReducer);

    useEffect(() => {
        if (!_.isEmpty(csvUploadBackDetails)) {
            setListDetails({ ...listDetails, ...csvUploadBackDetails });
        } else {
            setListDetails(dataLoad);
        }
    }, []);

    useEffect(() => {
        if (barCodeListingStatus === 'pending') {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [barCodeListingStatus]);

    useEffect(() => {
        if (barCodeListing?.TotalRecords) {
            setTotalPages(Math.ceil(barCodeListing?.TotalRecords / listDetails.numberOfPage));
        }
    }, [barCodeListing]);

    const handleSortingCoulmn = (event, sortColumn) => {
        event.preventDefault();
        let newSortOrder = listDetails.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
        let data = { ...listDetails, sortColumn: sortColumn, sortOrder: newSortOrder }
        setListDetails(data);
    };

    useEffect(() => {
        if (!_.isEmpty(listDetails)) {
            fetchAllColumnsList();
        }
    }, [listDetails.numberOfPage, listDetails.currentPage, listDetails.sortOrder]);

    const fetchAllColumnsList = () => {
        let data = {
            pageIndex: listDetails.currentPage,
            pageSize: Number(listDetails.numberOfPage),
            sortColumn: listDetails.sortColumn,
            sortOrder: listDetails.sortOrder
        }
        dispatch(getAllBarcodeColumnsList(data));
    }

    const resetListing = () => {
        let data = {
            pageIndex: 1,
            pageSize: Number(listDetails.numberOfPage),
            sortColumn: '',
            sortOrder: 'DESCENDING'
        }
        setListDetails({ ...listDetails, currentPage: 1, sortColumn: '', sortOrder: 'DESCENDING' });
        dispatch(getAllBarcodeColumnsList(data));
    }

    const handleSortingClass = (columnName, isArrow = false) => {
        if (barCodeListing?.TotalRecords) {
            if (isArrow) {
                return listDetails.sortColumn === columnName && listDetails.sortOrder === "ASCENDING" ? "fa fa-arrow-up" : "fa fa-arrow-down";
            }
            return listDetails.sortColumn === columnName ? 'active' : 'sortColumn'
        }
        return listDetails.sortColumn;
    };

    const handleNavigateToBarcodeListing = (integrationId, type = '', isEditable = false) => {
        if (type === 'viewAll') {
            navigate(`/barcode/barcode-upload/${integrationId}`, {
                state: {
                    Id: integrationId,
                    isEdit: isEditable,
                }
            });
        } else {
            navigate(`/barcode/mappings/bar-${integrationId}`, {
                state: {
                    data: integrationId,
                    backUrl: `/barcode/barcode-upload`
                }
            });
        }
        dispatch(barCodeUploadBackDetails(listDetails));
    }

    const setCurrentPage = (page) => {
        setListDetails({ ...listDetails, currentPage: page });
    }

    const setNumberOfPage = (page) => {
        setListDetails({ ...listDetails, numberOfPage: page });
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    Barcode Upload
                    <div className="top_right_button_container">
                        {checkPermission(barCode, SUBF_BARCODEUPLOAD, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
                            <Button
                                className="cus-seconday-bg-btn top-btn uploadCsvBtn"
                                onClick={() => setModalShow(true)}
                            >
                                Upload CSV
                            </Button>
                        )}
                        {checkPermission(barCode, SUBF_BARCODEUPLOAD, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                            <button
                                className="btn cus-seconday-bg-btn top-btn viewAll"
                                onClick={() => handleNavigateToBarcodeListing(0, 'viewAll')}
                            >
                                View All Data
                            </button>
                        )}
                        <Button
                            className="cus-primary-transparent-btn"
                            onClick={() => {
                                navigate("/barcode");
                                dispatch(barCodeUploadBackDetails({}));
                            }}
                        >
                            Back
                        </Button>
                    </div>
                </div>

                {showLoader && <LoaderSpinner />}
                <div className="main_content cus-page-content-sec">
                    <div className="main_content_table incomingTbl">
                        <div className='tableFixHead'>
                            <Table className={`table keyGenerate ${showLoader ? 'disableTble' : ''}`}>
                                <thead>
                                    <tr className="table-rw-batch">
                                        <th className="tbl-btch-hd" width="20%">
                                            <div
                                                className={handleSortingClass('fileName')}
                                                onClick={(e) => handleSortingCoulmn(e, "fileName")}
                                            >
                                                File Name
                                                <i
                                                    className={handleSortingClass('fileName', true)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="tbl-btch-hd" width="10%">
                                            <div
                                                className={`${handleSortingClass('status')} statusCol`}
                                                onClick={(e) => handleSortingCoulmn(e, "status")}
                                            >
                                                Status
                                                <i
                                                    className={handleSortingClass('status', true)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="tbl-btch-hd" width="20%">
                                            <div
                                                className={`${handleSortingClass('template')} templateMapping`}
                                                onClick={(e) => handleSortingCoulmn(e, "template")}
                                            >
                                                Template Mapping Name
                                                <i
                                                    className={handleSortingClass('template', true)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>

                                        </th>
                                        <th className="tbl-btch-hd" width="15%">
                                            <div
                                                className={handleSortingClass('uploadDateTime')}
                                                onClick={(e) => handleSortingCoulmn(e, "uploadDateTime")}
                                            >
                                                Upload Date|Time
                                                <i
                                                    className={handleSortingClass('uploadDateTime', true)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="tbl-btch-hd" width="15%" align='center'>
                                            <div
                                                className={`${handleSortingClass('rowsSubmitted')} alignCenter`}
                                                onClick={(e) => handleSortingCoulmn(e, "rowsSubmitted")}
                                            >
                                                Rows Submitted
                                                <i
                                                    className={handleSortingClass('rowsSubmitted', true)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="tbl-btch-hd" width="10%" align='center'>
                                            <div
                                                className={`${handleSortingClass('rowsAccepted')} alignCenter`}
                                                onClick={(e) => handleSortingCoulmn(e, "rowsAccepted")}
                                            >
                                                Rows Accepted
                                                <i
                                                    className={handleSortingClass('rowsAccepted', true)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="trows-s" width="20%">Action</th>
                                    </tr>
                                </thead>
                                <tbody className='tbl-bt-body'>
                                    {barCodeListing?.BarCodeList?.length > 0 && barCodeListing?.BarCodeList.map((item) => (
                                        <tr key={item.id}>
                                            <td className="batch-td-data">{item.fileName}</td>
                                            <td className="batch-td-data">
                                                <lable
                                                    className={
                                                        item.status === "Error"
                                                            ? "errorStatus"
                                                            : "activeStatus"
                                                    }
                                                >
                                                    {item.status}
                                                </lable>
                                            </td>
                                            {checkPermission(barCode, SUBF_BARCODEUPLOAD, permssionView, featureSubmodulePermission, props.employeePermissions) ? (
                                                <td className="batch-td-data td-data-csv" align='center'>
                                                    <span className='link v' onClick={() => handleNavigateToBarcodeListing(item.integrationId)}>
                                                        {item.template}
                                                    </span>
                                                </td>
                                            ) : (
                                                <td className="batch-td-data td-data-csv" align='center'>
                                                    {item.template}
                                                </td>
                                            )}

                                            <td className="batch-td-data">
                                                {moment(item.uploadDateTime).format(
                                                    "MM-DD-YYYY | h:mm:ss A"
                                                )}
                                            </td>
                                            <td align='center'>{item.rowsSubmitted}</td>
                                            <td align='center'>{item.rowsAccepted}</td>
                                            <td>
                                                {checkPermission(barCode, SUBF_BARCODEUPLOAD, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                                                    <ActionItems type={'View'} item={item} handleAction={() => handleNavigateToBarcodeListing(item.id, 'viewAll', false)} />
                                                )}
                                                <ActionItems type={'Edit'} item={item} handleAction={() => handleNavigateToBarcodeListing(item.id, 'viewAll', true)} />
                                            </td>
                                        </tr>
                                    ))}
                                    {barCodeListing?.BarCodeList?.length === 0 && barCodeListingStatus === 'success' && (
                                        <>{renderTableRow(6, 'No Record Found')}</>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                {barCodeListing?.BarCodeList?.length > 0 && (
                    <BatchPagination
                        gridData={barCodeListing?.BarCodeList}
                        totalRecords={barCodeListing?.TotalRecords}
                        numberOfPage={listDetails.numberOfPage}
                        numOfPage={numOfPage}
                        loader={showLoader}
                        currentPage={listDetails.currentPage}
                        totalPages={totalPages || 0}
                        setCurrentPage={setCurrentPage}
                        setNumberOfPage={setNumberOfPage}
                    />
                )}
            </div>
            {modalShow && (
                <UploadCsvBarcode show={modalShow} handleCancel={() => setModalShow(false)} resetListing={resetListing} />
            )}
        </main>
    );
}

export default BarCodeList;