import { useState } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { toastConfig } from "react-simple-toasts";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { getAppConfiguration, saveUser } from "./store/actions/auth";
import AuthService from "./services/auth.service";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./config/Routes";
import axios from "axios";
import { getUserToken, logout } from "./config/auth";
import { getLocalStorageItem, setLocalStorageItem } from "./services/StorageService";

const App = () => {
  const [sidebarOpen, setsidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const openSidebar = () => {
    setsidebarOpen(true);
  };
  const closeSidebar = () => {
    setsidebarOpen(false);
  };

  toastConfig({
    time: 3000,
    position: "right",
  });

  const { user: currentUser } = useSelector((state) => state.auth);
  const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);
  let user = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    if (!currentUser && user) {
      dispatch(saveUser(user));
      dispatch(getAppConfiguration());
    }
  }, []);

  const updateToken = (userToken) => {
    let getUserDetails = JSON.parse(getLocalStorageItem('userDetails'));
    getUserDetails.Value.Token = userToken;
    setLocalStorageItem('userDetails', JSON.stringify(getUserDetails));
  }

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    })

    failedQueue = [];
  }

  axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + getUserToken();
          return axios(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        })
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        AuthService.refreshTokenService(getUserToken()).then(({ data }) => {
          updateToken(data?.Value?.Token);
          originalRequest.headers['Authorization'] = 'Bearer ' + data?.Value?.Token;
          processQueue(null, data?.Value?.Token);
          resolve(axios(originalRequest));
        }).catch((err) => {
          processQueue(err, null);
          reject(err);
          logout();
        }).finally(() => { isRefreshing = false })
      })
    }

    return Promise.reject(error);
  });

  axios.interceptors.request.use(async (req) => {
    if (!req.url.includes('login') && !req.url.includes('printers')) {
      req.headers['Authorization'] = 'Bearer ' + getUserToken();
      req.headers['AccessTokenId'] = 'AccessTokenId';
    }
    return req
  }, error => {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 401) {
      logout();
    }
    return error;
  });

  return (
    <div className={currentUser ? "containers" : ""}>
      <AppRoutes
        currentUser={currentUser}
        sidebarOpen={sidebarOpen}
        openSidebar={openSidebar}
        closeSidebar={closeSidebar}
        employeePermissions={employeePermissions}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        theme="dark"
      />
    </div>
  );
};

export default App;
