import React from "react";
import { Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateSkipDuplicate, uploadCsv } from "../../store/actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useState } from "react";

const UploadCSVModal = (props) => {
  const { modalClass, setOpenCsvModal, setModalShow, modalShow, gridData } = props;
  const [data, setData] = useState([]);
  useEffect(() => {
    if (gridData && gridData.length) {
      setData(gridData);
    }
  }, [gridData]);
  const dispatch = useDispatch();
  const createdBy = useSelector((state) => state.auth.user);
  /* istanbul ignore next */
  const handleUploadClick = async (event, Id) => {
    const formdata = new FormData();
    formdata.append("FromFile", event.target.files[0]);
    formdata.append("IntegrationId", Id);
    formdata.append("CreatedBy", createdBy?.Value?.employeeId);
    formdata.append("SellerId", createdBy?.Value?.sellerId);
    formdata.append("CompanyId", createdBy?.Value?.companyId);

    let uploadResponse = await Promise.resolve(dispatch(uploadCsv(formdata)));
    if (uploadResponse.data.Status === 200) {/* istanbul ignore next */
      toast.success(uploadResponse.data.Entity);
    }
  };
  const handleAutGenerateOrder = async (event, autGenerateId) => {
    let url = `flagStatus=${event.target.checked}&Id=${autGenerateId}`;
    let gridDataClone = [...data];
    let updateData = gridDataClone.map((item) => {
      if (item.Id === autGenerateId) {
        item.isSkipDuplicateCheck = event.target.checked;
      }
      return item;
    })
    setData(updateData);
    let response = dispatch(updateSkipDuplicate(url));
    if (response.data && response.data.Status === 200) {
      /* istanbul ignore next */
      toast.success("Saved Successfully");
    }
  };

  return (
    <Modal
      className={modalClass || ""}
      size="lg"
      show={modalShow}
      onHide={/* istanbul ignore next */ () => {
        setOpenCsvModal(false);
      }}
    >
      <Modal.Header closeButton>
        <div className="modal-main-div">
          <text className="modal-m-text"> Upload CSV</text>
        </div>
        <svg
          className="btn-cclose close"
          id="hhd"
          onClick={() => setModalShow(false)}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          style={{ marginRight: "-520px" }}
          cursor="pointer"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-m-h">
          <text className="modal-m-h-text"> Your CSV Integrations: </text>
        </div>
        <Table className="table table-borderless border-top-0 border-left-0 border-right-0 border-bottom-0 ">
          <thead>
            <tr className="table-heading">
              <th className="m-h-1 wid_first">Name</th>
              <th className="m-h-1 wid">Skip Header Row</th>
              <th className="m-h-1 wid">Skip Duplicate Address Check</th>
              <th className="m-h-1 wid">Delimiter</th>
              <th></th>
            </tr>
            <hr className="hrdivup"></hr>
          </thead>
          <tbody className="table-sb">
            {data && data.map((i) => (
              <tr key={i.id}>
                <td className="td-m-2 wid">{i.Name}</td>
                <td className="td-m-1 wid">
                  {i.IsHeaderRowToImport ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  )}
                </td>
                <td className="td-m-1 wid">
                  {i.IsAutGenerateOrderId ? (
                    <input
                      type="checkbox"
                      checked={i.isSkipDuplicateCheck}
                      onClick={(e) => handleAutGenerateOrder(e, i.Id)}
                    />
                  ) : (
                    <div>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip>
                            Enable CSV setting *Auto generate unique order
                            ID if not provided* to see this option
                          </Tooltip>
                        }
                      >
                        {
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-info"
                            viewBox="0 0 16 16"
                          >
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                        }
                      </OverlayTrigger>
                    </div>
                  )}
                </td>
                <td className="td-m-1 wid">
                  <label
                    style={{
                      marginLeft: "0px",
                      marginTop: "-14px",
                      lineHeight: "10px",
                    }}
                  >
                    .
                  </label>
                </td>
                <td className="td-m-1 wid">
                  <button>
                    <label
                      className="success"
                      htmlFor={`actual-btn-${i.Id}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-upload"
                        viewBox="0 0 16 16"
                        cursor="pointer"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                      </svg>{" "}
                      &nbsp; Upload CSV{" "}
                    </label>
                  </button>
                  <input
                    type="file"
                    name="file"
                    //accept=".csv"
                    id={`actual-btn-${i.Id}`}
                    onChange={(e) => {
                      handleUploadClick(e, i.Id);
                    }}
                    hidden
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default UploadCSVModal;
