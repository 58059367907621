import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { v4 as uuidv4 } from 'uuid';
import { fetchAllEmployeePermission, fetchRoleList } from '../../store/actions/auth';
import _ from 'lodash';
import { findPermissionByRole } from '../../config/utils';

const PermissionTable = (props) => {
    const { dispatch } = props;
    const { roleList, allEmployeePermission } = useSelector((state) => state?.auth);
    const [permissionList, setPermissionList] = useState([]);

    useEffect(() => {
        if (!allEmployeePermission.length) {
            dispatch(fetchAllEmployeePermission());
        } else {
            setPermissionList(_.cloneDeep(allEmployeePermission));
        }
    }, [allEmployeePermission]);

    useEffect(() => {
        if (!roleList.length) {
            dispatch(fetchRoleList());
        }
    }, []);

    const handleExpand = (index, subFeatureindex = null) => {
        let permissionListClone = _.cloneDeep(permissionList);
        if (subFeatureindex !== null) {
            permissionListClone[index].SubFeatures[subFeatureindex].showSubPermission = !permissionListClone[index].SubFeatures[subFeatureindex].showSubPermission;
        } else {
            permissionListClone[index].showSubPermission = !permissionListClone[index].showSubPermission;
        }
        setPermissionList(permissionListClone);
    }

    const findPermission = (role, permissions) => {
        if (roleList.length) {
            return findPermissionByRole(role, permissions, roleList);
        }
    }

    return (
        <div className="scrollableXAxis">
            <div className='permissionTbl' key={uuidv4()}>
                <div className='row mb-4' >
                    <div className='col-3'></div>
                    {roleList.map((role, index) => (
                        <div key={role.id} className={`${index === 0 ? 'col-1' : 'col-2'} heading alignCenter`} id={role.id}>{role.name}</div>
                    ))}
                </div>

                {permissionList.map((permission, index) => (
                    <div className={`${permission.showSubPermission ? 'expanded' : ''}  row permissionRow`} key={permission.id}>
                        <div className='col-3 pt-2 pb-2 moduleBar cursorPointer' onClick={() => handleExpand(index)}>
                            <span className='module'>
                                <i className={`${permission.showSubPermission ? 'fa-angle-down' : 'fa-angle-right'} fa mr-2`} aria-hidden="true"></i>
                                {permission.featureName}
                            </span>
                        </div>
                        <div className='col-1 pt-2 pb-2 alignCenter'>
                            <Form.Check
                                type={'checkbox'}
                                checked={findPermission('Admin', permission.roleDetails)}
                                className='ml-0 moduleEnable'
                            />
                        </div>
                        <div className='col-2 pt-2 pb-2 alignCenter'>
                            <Form.Check
                                type={'checkbox'}
                                checked={findPermission('Seller', permission.roleDetails)}
                                className='ml-0'
                            />
                        </div>
                        <div className='col-2 pt-2 pb-2 alignCenter'>
                            <Form.Check
                                type={'checkbox'}
                                checked={findPermission('Super Admin', permission.roleDetails)}
                                className='ml-0'
                            />
                        </div>
                        <div className='col-2 pt-2 pb-2 alignCenter'>
                            <Form.Check
                                type={'checkbox'}
                                checked={findPermission('Warehouse Manager', permission.roleDetails)}
                                className='ml-0'
                            />
                        </div>
                        <div className='col-2 pt-2 pb-2 alignCenter'>
                            <Form.Check
                                type={'checkbox'}
                                checked={findPermission('Warehouse Worker', permission.roleDetails)}
                                className='ml-0'
                            />
                        </div>
                        {permission.showSubPermission && (
                            <div className='col-12'>
                                {permission.SubFeatures.map((subfeature, subFeatureindex) => (
                                    <div key={uuidv4()}>
                                        <div className='row permissionRow'>
                                            <div className='col-3 pt-2 pb-2 pl-5 expandSubFeature cursorPointer' onClick={() => handleExpand(index, subFeatureindex)}>
                                                <span className='module'>
                                                    <i className={`${subfeature.showSubPermission ? 'fa-angle-down' : 'fa-angle-right'} fa mr-2`} aria-hidden="true"></i>
                                                    {subfeature.subFeatureName}
                                                </span>
                                            </div>
                                            <div className='col-1 pt-2 pb-2 alignCenter'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    checked={findPermission('Admin', subfeature.roleDetails)}
                                                    className='ml-0'
                                                />
                                            </div>
                                            <div className='col-2 pt-2 pb-2 alignCenter'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    checked={findPermission('Seller', subfeature.roleDetails)}
                                                    className='ml-0'
                                                />
                                            </div>
                                            <div className='col-2 pt-2 pb-2 alignCenter'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    checked={findPermission('Super Admin', subfeature.roleDetails)}
                                                    className='ml-0'
                                                />
                                            </div>
                                            <div className='col-2 pt-2 pb-2 alignCenter'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    checked={findPermission('Warehouse Manager', subfeature.roleDetails)}
                                                    className='ml-0'
                                                />
                                            </div>
                                            <div className='col-2 pt-2 pb-2 alignCenter'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    checked={findPermission('Warehouse Worker', subfeature.roleDetails)}
                                                    className='ml-0'
                                                />
                                            </div>
                                        </div>
                                        {subfeature.showSubPermission && (
                                            <>
                                                {subfeature.Permissions.map((userPermission) => (
                                                    <div className='row permissionRow' key={uuidv4()}>
                                                        <div className='col-3 pt-2 pb-2 pl-5'>
                                                            <b className='ml-permission'>
                                                                {userPermission.permissionName}
                                                            </b>
                                                        </div>
                                                        <div className='col-1 pt-2 pb-2 alignCenter'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                checked={findPermission('Admin', userPermission.roleDetails)}
                                                                className='ml-0'
                                                            />
                                                        </div>
                                                        <div className='col-2 pt-2 pb-2 alignCenter'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                checked={findPermission('Seller', userPermission.roleDetails)}
                                                                className='ml-0'
                                                            />
                                                        </div>
                                                        <div className='col-2 pt-2 pb-2 alignCenter'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                checked={findPermission('Super Admin', userPermission.roleDetails)}
                                                                className='ml-0'
                                                            />
                                                        </div>
                                                        <div className='col-2 pt-2 pb-2 alignCenter'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                checked={findPermission('Warehouse Manager', userPermission.roleDetails)}
                                                                className='ml-0'
                                                            />
                                                        </div>
                                                        <div className='col-2 pt-2 pb-2 alignCenter'>
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                checked={findPermission('Warehouse Worker', userPermission.roleDetails)}
                                                                className='ml-0'
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PermissionTable;