import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../fundManagement/fundmanagement.css";
import { useDispatch, useSelector } from "react-redux";
import { SellerManagement } from "../AddSeller";
import { UserManagement } from "../UserManagement/UserTable";
import {
  GetCompanyAndAddress,
} from "../../store/actions/rateCard";
import { Button } from "bootstrap-4-react/lib/components";
import {
  addNewCompany,
  deleteCompanyAddress,
  getAllCountries,
  makeDefaultAddress,
  userListBackDetails,
} from "../../store/actions";
import AddAddressModal from "../modal/AddAddressModal/index";
import AlertDialogModal from "../common/AlertDialog";
import { checkPermission, handleInputValueTypes, validateEmail, validatePhoneNumber, validateZipCode } from "../../config/utils";
import CompanyDetails from "./CompanyDetails";
import { accountsManagement, featureSubmodulePermission, permissionAssociatedUser, permissionCompanyAssociatedSeller, SUBF_COMPANY } from "../../constant";

export function EditCompany(props) {
  const navigate = useNavigate();
  const { rowId } = useParams();
  const companyId = parseInt(rowId, 10);
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = React.useState(1);
  const [countryList, setcountryList] = useState();
  const [errors, setErrors] = useState({});
  const [isDefault, setisDefault] = useState(false);
  const [addressModalShow, setaddressModalShow] = useState(false);
  const associatedSellerTab = location?.state?.associatedSeller;
  const associatedUserTab = location?.state?.associatedUser;
  const [statusModalShow, setStatusModalShow] = useState(false);
  const [addressId, setaddressId] = useState();
  const [stateList, setstateList] = useState();
  const getAddressCompanyValue = useSelector(
    (state) => state.company?.getAddressCompanyData
  );
  const countries = useSelector((state) => state.shipment.countries);
  const rowData = location?.state?.data;
  const editValue = location?.state?.editable;

  const [addressData, setaddressData] = useState([]);
  const [addresp, setaddresp] = useState();
  const [formData, setFormData] = useState({
    createdDate: new Date(),
    modifiedDate: new Date(),
    addressModels: [],
    isActive: true,
  });
  const [sellerState, setSellerState] = useState(false)
  const [associatedUser, setAssociatedUser] = useState(false);

  useEffect(() => {
    if (!countries?.length) {
      dispatch(getAllCountries());
    }
  }, [])

  useEffect(() => {
    setFormData({
      ...formData,
      email: getAddressCompanyValue?.Entity?.email,
      name: getAddressCompanyValue?.Entity?.name,
      isInventoryManagementAccess: getAddressCompanyValue?.Entity?.isInventoryManagementAccess,
      id: getAddressCompanyValue?.Entity?.Id,
    });
  }, [getAddressCompanyValue?.Entity]);

  useEffect(() => {
    if (associatedSellerTab) {
      setActiveTab(2);
    }
    if (associatedUserTab) {
      setActiveTab(3);
    }
  }, [associatedSellerTab, associatedUserTab]);

  useEffect(() => {
    dispatch(GetCompanyAndAddress(companyId));
  }, [companyId]);

  useEffect(() => {
    if (getAddressCompanyValue?.Entity?.AddressModels) {
      setaddressData(getAddressCompanyValue?.Entity?.AddressModels);
    }
  }, [getAddressCompanyValue, getAddressCompanyValue?.Entity?.AddressModels]);

  const handleCancelAlert = () => {
    setStatusModalShow(false);
    setTimeout(() => {
      setisDefault(false);
    }, 500);
  };

  const handleYesDeleteAlert = () => {
    dispatch(deleteCompanyAddress(addressId, companyId));
    setStatusModalShow(false);
    setisDefault(false);
  };

  const handleDeleteAddress = (d) => {
    if (d?.isDefault == 1) {
      setisDefault(true);
      setStatusModalShow(true);
      setaddressId(d?.id);
    } else {
      setStatusModalShow(true);
      setaddressId(d?.id);
    }
  };

  const handleChange = (index, field, value) => {
    const newErrors = { ...errors };
    if (newErrors[`addressModels_${index}`]) {
      delete newErrors[`addressModels_${index}`][field];
    }
    if (field === 'country_Id') {
      let allCountries = [...countries];
      let countryIndex = allCountries.findIndex(
        (item) => item.id === value.value
      );
      const updatedAddress = { ...addressData[index], [field]: value.value, country: value.label, phoneNumber: `+(${countries[countryIndex].phoneCountryCode}) ` };
      const updatedFormData = [...addressData];
      updatedFormData[index] = updatedAddress;
      setaddressData(updatedFormData);
      setErrors(newErrors);
    } else {
      const updatedAddress = { ...addressData[index], [field]: value };
      const updatedFormData = [...addressData];
      updatedFormData[index] = updatedAddress;
      setaddressData(updatedFormData);
      setErrors(newErrors);
    }
  };

  const TopUpForm = (item, name, sellerdetail) => {
    setFormData({ ...formData, [name]: item });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  useEffect(() => {
    if (countries && countries.length) {
      let data = countries.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setcountryList(data);
    } else {
      setcountryList([]);
    }
  }, [countries]);

  const handleEditable = (e) => {
    navigate(`/editCompany/comp-${rowData?.Id}`, {
      state: {
        data: rowData,
        editable: false,
      },
    });
  };

  const handleDefaultAddress = (Id) => {
    dispatch(makeDefaultAddress(Id, companyId));
  };

  const saveTopUpData = () => {
    const requiredFields = ["name", "email"];
    let isValid = true;
    let newErrors = {};

    // Email validation regular expression
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        isValid = false;
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    if (!validateEmail(formData.email)) {
      isValid = false;
      newErrors.email = "Invalid email address";
    }

    // Validate addressModels
    let validateObj = handleValidateAddress(isValid, newErrors);
    isValid = validateObj.isValid;
    newErrors = { ...newErrors, ...validateObj.newErrors };
    setaddresp(validateObj.res);
    handleIsValid(isValid, newErrors, validateObj.res);
  };

  const validatePhoneNumberFormat = (address, isValid, addressErrors) => {
    if (validatePhoneNumber(address.phoneNumber)) {
      isValid = false;
      addressErrors.phoneNumber = "Phone number should have a minimum of 10 digits";
    }
    return isValid;
  };

  const validateZipCodeForCountry = (address, isValid, addressErrors) => {
    if (address.country_Id === 218) {
      if (!address.zipCode) {
        isValid = false;
        addressErrors.zipCode = "Zip code is required";
      } else if (!validateZipCode(address.zipCode)) {
        isValid = false;
        addressErrors.zipCode = "Zip code should be in the format xxxxx or xxxxx-xxxx";
      }
    } else {
      if (address.zipCode && address.zipCode.length < 4) {
        isValid = false;
        addressErrors.zipCode = "Zip code should have a minimum length of 4";
      }
    }
    return isValid;
  };

  const handleValidateAddress = (isValid, newErrors) => {
    let res = addressData?.forEach((address, index) => {
      const addressErrors = {};
      if (!address.state) {
        isValid = false;
        addressErrors.state = "State is required";
      }

      validateZipCodeForCountry(address, isValid, addressErrors);

      if (!address.city) {
        isValid = false;
        addressErrors.city = "City is required";
      }
      if (!address.phoneNumber) {
        isValid = false;
        addressErrors.phoneNumber = "phoneNumber is required";
      }

      validatePhoneNumberFormat(address, isValid, addressErrors);

      if (!address.addressFirst) {
        isValid = false;
        addressErrors.addressFirst = "Address 1 is required";
      }
      if (!address.addressSecond) {
        isValid = false;
        addressErrors.addressSecond = "Address 2 is required";
      }
      newErrors[`addressModels_${index}`] = addressErrors;
    });
    return { res, newErrors, isValid }
  }

  const handleIsValid = (isValid, newErrors, res) => {
    if (isValid) {
      const changedAddresses = addressData.filter((address, addressIndex) => {
        const originalAddress =
          getAddressCompanyValue?.Entity?.AddressModels[addressIndex];
        return JSON.stringify(address) !== JSON.stringify(originalAddress);
      });

      if (changedAddresses?.length) {
        dispatch(
          addNewCompany(
            { ...formData, addressModels: changedAddresses },
            navigate,
            true,
            formData?.id
          )
        );
      } else {
        dispatch(addNewCompany(formData, navigate, true, formData?.id));
      }
    } else {
      setErrors(newErrors);
      setaddresp(res);
    }
  }

  const handleBackNavigation = () => {
    if (!sellerState && !associatedUser) {
      setaddressData(null);
      navigate("/CompanyManagement");
    } else {
      setSellerState(sellerState => sellerState ? false : sellerState);
      setAssociatedUser(associatedUser => associatedUser ? false : associatedUser);
    }
  };

  return (
    <main>
      {addressModalShow && (
        <AddAddressModal
          show={addressModalShow}
          addresp={addresp}
          companyData={{
            name: formData?.name,
            email: formData?.email,
            phoneNumber: formData?.phoneNumber,
            companyId: formData?.id,
          }}
          onHide={() => setaddressModalShow(false)}
        />
      )}
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          <div className="Back-btn Back-btn-mobRes">
            <Button
              className="cus-primary-transparent-btn backBtn"
              onClick={() => {
                handleBackNavigation();
                dispatch(userListBackDetails({}))
              }}
            >
              Back
            </Button>
          </div>
        </div>

        <div className="container-fluid">
          <div className="cus-page-content-sec">
            <div className="cus-tab-heading-sec">
              <div className="tabs">
                {
                  <>
                    <button
                      className={`${activeTab === 1 ? "cus-tab-btn active" : "cus-tab-btn"
                        }`}
                      onClick={() => setActiveTab(1)}
                    >
                      Company Details
                    </button>
                    {checkPermission(accountsManagement, SUBF_COMPANY, permissionCompanyAssociatedSeller, featureSubmodulePermission, props.employeePermissions) && (
                      <button
                        className={`${activeTab === 2 ? "cus-tab-btn active" : "cus-tab-btn"
                          }`}
                        onClick={() => { setActiveTab(2); setSellerState(false); }}
                      >
                        Associated Sellers
                      </button>
                    )}
                    {checkPermission(accountsManagement, SUBF_COMPANY, permissionAssociatedUser, featureSubmodulePermission, props.employeePermissions) && (
                      <button
                        className={`${activeTab === 3 ? "cus-tab-btn active" : "cus-tab-btn"
                          }`}
                        onClick={() => { setActiveTab(3); setAssociatedUser(false); }}
                      >
                        Associated Users
                      </button>
                    )}
                  </>
                }
                {activeTab === 1 && (
                  <CompanyDetails
                    {...props}
                    editValue={editValue}
                    handleEditable={handleEditable}
                    setaddressModalShow={setaddressModalShow}
                    saveTopUpData={saveTopUpData}
                    formData={formData}
                    TopUpForm={TopUpForm}
                    errors={errors}
                    addressData={addressData}
                    setaddressData={setaddressData}
                    handleDefaultAddress={handleDefaultAddress}
                    handleDeleteAddress={handleDeleteAddress}
                    countryList={countryList}
                    handleChange={handleChange}
                    handleInputValueTypes={handleInputValueTypes}
                    stateList={stateList}
                    setstateList={setstateList}
                  />
                )}

                {activeTab === 2 && (
                  <SellerManagement
                    {...props}
                    type={"associatedSeller"}
                    companyId={companyId}
                    isTab={true}
                    setSellerState={setSellerState}
                    sellerState={sellerState}
                  />
                )}

                {activeTab === 3 && (
                  <UserManagement
                    {...props}
                    type={"associatedUser"}
                    companyId={companyId}
                    isTab={true}
                    associatedUser={associatedUser}
                    setAssociatedUser={setAssociatedUser}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertDialogModal
        modalClass={"modalDeleteWrapper"}
        modalShow={statusModalShow}
        handleCancelDeleteAlert={handleCancelAlert}
        handleYesDeleteAlert={handleYesDeleteAlert}
        default={isDefault}
        messageText={
          isDefault
            ? `You cannot Delete the default Address of company .`
            : `Are you sure you want to 
         delete this address?`
        }
        headingText={"Delete Address"}
      />
    </main>
  );
}