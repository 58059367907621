import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import pic10 from "../../assets/minus.png";
import pic9 from "../../assets/plus.png";
import uspsLogo from "../../assets/usps-logo.jpg";
import MainLogo from "../../assets/images/main-logo.png";
import MainLogoAi from "../../assets/images/dashBoardLogo2.svg";
import PremiumLogo from "../../assets/images/3pl-premium.svg";
import FedExLogo from "../../assets/images/fedex.png";
import { Tooltip as WmsTooltip } from "react-tooltip";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import { getTrackingDetails } from "../../store/actions/marketplace.action";

import {
  apiUrl,
  commodityObj,
  commonAddressObj,
  dangerousGoods,
  featureSubmodulePermission,
  orderManagement,
  permssionCreate,
  permssionDelete,
  permssionView,
  permssionEdit,
  phoneNum,
  unitList,
  validateMultipleEmails,
  validUsZip,
  shipmentModule,
  addressBook,
  addressModule,
  featureModule,
  settings,
  SUBF_MARKETPLACEINTEGRATION,
  featureSubmodule,
  permissionPrintLabel,
} from "../../constant";
import {
  createShipment,
  editShipment,
  getAllCountries,
  getAllPackagesByServiceId,
  getAllServices,
  getStates,
  validateAddress,
  getCsvActiveIntegration,
  deleteOrder,
  saveShipmentDraft,
  getSellerAddressById,
  getZipZoneRatesByServiceType,
  getPendingOrders,
  updateAddress,
  getSignatureOptionList,
  fetchAddressFromZipCode,
  getContentTypeListService,
} from "../../store/actions";
import { Spinner } from "react-bootstrap";
import "./shipment.css";
import AlertDialogModal from "../common/AlertDialog";
import UploadCSVModal from "../common/UploadCsvDialog";
import "react-toastify/dist/ReactToastify.css";
import SaveShipmentDialog from "../common/SaveShipmentDialog";
import LoadOrdersDialog from "../common/LoadSavedOrders";
import { getLoginUserDetailsonFund } from "../../store/actions/fundManagement";
import { UploadDialog } from "../mapping/csvListUploadForm";
import _, { debounce } from "lodash";
import { useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Button } from "bootstrap-4-react/lib/components";
import CommercialInvoice from "./CommercialInvoice";
import ViewParcel from "./ViewParcel";
import LoadSavedAddress from "./LoadSavedAddress";
import { createAddress } from "../../store/actions/address.action";
import { marketPlaceIntegrationListing } from "../../store/actions/marketplace.action";
import {
  checkPermission,
  convertToCm,
  convertToInch,
  findIsHostShipAi,
  formatNumberWithCommas,
} from "../../config/utils";

export function Shipment(props) {
  const dispatch = useDispatch();
  const [hasDifferentAddress, setHasDifferentAddress] = useState(false);
  const [isSenderEdit, setIsSenderEdit] = useState(true);
  const [newShipment, setNewShipMent] = useState(false);
  const [selectedService, setSelectedService] = useState();
  const [selectedPackage, setSelectedPackage] = useState();
  const [shipmentName, setShipmentName] = useState("");
  const [isPackageSelected, setIsPackageSelected] = useState({});
  const [selectAllOrders, setSelectAllOrders] = useState(false);
  const [parcels, setParcels] = useState([]);
  const [errorFields, setErrorFields] = useState({});
  const companies = useSelector((state) => state.auth.companyList);
  const employeeAddress = useSelector(
    (state) => state.shipment.employeeAddress
  );
  const services = useSelector((state) => state.shipment.services);
  const signatureList = useSelector((state) => state.shipment.signatureList);
  const contentTypeList = useSelector(
    (state) => state.shipment.contentTypeList
  );
  const packages = useSelector((state) => state.shipment.packages);
  const activeCSVList = useSelector((state) => state.shipment.activeCSVList);
  const editShipmentDetails = useSelector(
    (state) => state.shipment.editShipmentDetails
  );
  const fundDetails = useSelector(
    (state) => state?.FundManagementReducer?.getsingleFundDetails?.EntityList
  );

  const fundDetailsvalue = useSelector(
    (state) => state?.FundManagementReducer?.getWalletAmount
  );
  const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);

  const countries = useSelector((state) => state.shipment.countries);
  const USSates = useSelector((state) => state.shipment.states);
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  let validDecimalNum = /^[0-9]*(\.[0-9]{0,3})?$/;
  const [shipmentObject, setShipmentObject] = useState([]);
  const [csvModalShow, setCsvModalShow] = useState(false);
  const [validationsList, setValidationsList] = useState([]);
  const [thresholdValidation, setthresholdValidation] = useState([]);
  const [thresholdvalue, setthresholdvalue] = useState();
  const [availableAmount, setavailableAmount] = useState();
  const [saveReciever, setSaveReciever] = useState(false);
  const [showInvalidAddress, setShowInvalidAddress] = useState(false);
  const [zipZoneRates, setZipZoneRates] = useState("");
  const [packageArrivalDate, setPackageArrivalDate] = useState();
  const [orderNumberData, setOrderNumberData] = useState([]);
  const [orderNumberDataCopy, setOrderNumberDataCopy] = useState([]);
  const [shippngLabel, setShippngLabel] = useState({});
  const [orderListLoaded, setOrderListLoaded] = useState(false);
  const [showDeleteShipDialog, setShowDeleteShipDialog] = React.useState(false);
  const [currentSelectedRecord, setCurrentSelectedRecord] = React.useState({});
  const [openCsvModal, setOpenCsvModal] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState(0);
  const [showSaveShipment, setShowSaveShipment] = React.useState(false);
  const [showLoadOrders, setShowLoadOrders] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [seachTxtOrder, setSeachTxtOrder] = React.useState("");
  const [getZipZoneStatus, setGetZipZoneStatus] = React.useState(false);
  const [saveOrderLoader, setSaveOrderLoader] = React.useState(false);
  const createdBy = useSelector((state) => state.auth.user);
  const [serviceTab, setServiceTab] = React.useState(1);
  const [getError, setGetError] = React.useState("");
  const [marketIntegrationList, setMarketIntegrationList] = React.useState([]);
  const [integrationValue, setIntegrationValue] = React.useState('');
  const [showLoadAddressDialogObj, setShowLoadAddressDialogObj] =
    React.useState({ showModal: false, addressType: "" });

  useEffect(() => {
    !showSaveShipment && setShipmentName("");
  }, [showSaveShipment]);

  const [newShipmentObj, setNewShipmentObj] = useState({
    createdBy: createdBy?.Value?.employeeId,
    sellerId: createdBy?.Value?.sellerId,
    companyId: createdBy?.Value?.companyId,
    companySellerId: createdBy?.Value?.companySellerId,
    createdDate: new Date().toISOString(),
    modifiedBy: 0,
    modifiedDate: new Date().toISOString(),
    isActive: true,
    id: 0,
    employeeID: createdBy?.Value?.employeeId,
    channelID: 2335,
    cargoType: 2,
    orderID: "",
    fName: "",
    fTel: "+1",
    fCompany: "",
    fEmail: "",
    fAdd1: "",
    fAdd2: "",
    fAdd3: "",
    fState: "",
    fCity: "",
    fPostcode: "",
    fCountry: "US",
    receiverCompanyID: 0,
    receiverCountryID: 218,
    sName: "",
    printLabelCost: false,
    sCompany: "",
    senderCompanyID: 0,
    senderCountryID: 218,
    sAdd1: "",
    sAdd2: "",
    sCity: "",
    sState: "",
    sEmail: "",
    sPostcode: "",
    sTel: "+1",
    sCountry: "US",
    ckid: 0,
    parcelNum: 1,
    note: "string",
    serviceID: 0,
    packageID: 0,
    shippingLabel: "",
    totalOunceGm: "0",
    parcels: [
      {
        createdBy: createdBy?.Value?.employeeId,
        createdDate: new Date().toISOString(),
        modifiedBy: 0,
        modifiedDate: new Date().toISOString(),
        isActive: true,
        id: 0,
        shipmentID: 0,
        box: 0,
        weight: null,
        ounces: null,
        length: null,
        width: null,
        height: null,
        sku: "",
        cnName: "",
        enName: "",
        price: 0,
        num: 0,
        material: "",
        use: "",
        cWeight: 0,
        hsCode: "",
        battery: "",
      },
    ],
    shipmentName: "",
    shipedStatus: 0,
    isReadyForShipment: true,
    isDifferentAddress: false,
    isReturnAddress: false,
    contentDescription: "",
    signature: "",
    signatureId: "1",
    contenTypeId: "",
    contentType: "Merchandise",
    contentsExplanation: "",
    dangerousGoods: "1",
    sendNotification: false,
    shipmentDate: new Date(),
    isShipmentValidDate: true,
    totalWeight: 0,
    unit: 1,
    totalQuantity: 0,
    ounces: 0,
    insureAmount: 0,
    exShipmentId: "",
    senderCompanyName: "",
    senderCountryName: "",
    receiverCompanyName: "",
    receiverCountryName: "",
    serviceName: "",
    packageName: "",
    carrierCompanyName: "",
    logo: "",
    shippingreference: "",
    emailsValid: true,
    dimensionUnit: "INCH",
    commodity: JSON.parse(JSON.stringify(commodityObj)),
    sendFedExNotification: false,
    onDelivery: false,
    onException: false,
    onShipment: false,
    onTender: false,
    onEstimatedDelivery: false,
    returnCountryId: "218",
  });

  useEffect(() => {
    let findChecked = orderNumberData.filter((item) => item.isChecked);
    if (findChecked.length === orderNumberData.length) {
      setSelectAllOrders(true);
    } else {
      setSelectAllOrders(false);
    }
  }, [orderNumberData]);

  useEffect(() => {
    if (isPackageSelected?.shouldAutofill) {
      let copyShipObj = { ...shipmentObject };
      let updateParcel = copyShipObj.parcels.map((item, index) => {
        return {
          ...item,
          length: isPackageSelected.length,
          width: isPackageSelected.width,
          height: isPackageSelected.height,
        };
      });
      copyShipObj.parcels = updateParcel;
      setShipmentObject(copyShipObj);
    }
  }, [isPackageSelected, shipmentObject?.parcels?.length]);

  const fetchAddress = async (zipCode, type) => {
    let responseAddress = await dispatch(fetchAddressFromZipCode(zipCode));
    if (responseAddress?.data?.Status === 200) {
      let address = responseAddress?.data?.Entity;
      if (address && address?.Country) {
        let copyShipmentObj = { ...shipmentObject };
        if (type === "receiver") {
          copyShipmentObj.serviceName = "";
          copyShipmentObj.serviceID = 0;
          copyShipmentObj.packageID = "";
          copyShipmentObj.packageName = "";
          copyShipmentObj.receiverCountryID =
            countries.filter((country) => country.name === address?.Country)[0]
              .id || shipmentObject.receiverCountryID;
          copyShipmentObj.sCountry = countries.filter(
            (country) => country.name === address?.Country
          )[0].iSO;
          copyShipmentObj.sState =
            address.Country === "United States"
              ? USSates.filter((state) => state.StateName === address?.State)[0]
                .StateCode
              : address?.State;
          copyShipmentObj.sCity = address.City || copyShipmentObj.sCity;
          setServiceTab(1);
        } else if (type === "sender") {
          // if (zipCode !== copyShipmentObj.fPostcode) {
          copyShipmentObj.senderCountryID =
            countries.filter((country) => country.name === address?.Country)[0]
              .id || shipmentObject.senderCountryID;
          copyShipmentObj.fCountry = countries.filter(
            (country) => country.name === address?.Country
          )[0].iSO;
          copyShipmentObj.fState =
            address.Country === "United States"
              ? USSates.filter((state) => state.StateName === address?.State)[0]
                .StateCode
              : address?.State;
          copyShipmentObj.fCity = address.City || copyShipmentObj.fCity;
          // }
        } else if (type === "returnAddress") {
          copyShipmentObj.returnCountryId =
            countries.filter((country) => country.name === address?.Country)[0]
              .id || shipmentObject.returnCountryId;
          copyShipmentObj.returncountrycode = countries.filter(
            (country) => country.name === address?.Country
          )[0].iSO;
          copyShipmentObj.returnstate =
            address.Country === "United States"
              ? USSates.filter((state) => state.StateName === address?.State)[0]
                .StateCode
              : address?.State;
          copyShipmentObj.returncity = address.City || copyShipmentObj.fCity;
        }
        setShipmentObject(copyShipmentObj);
      }
    }
  };

  const validateRecieverAddress = async () => {
    let data = {
      fAdd1: shipmentObject.fAdd1,
      fAdd2: shipmentObject.fAdd2,
      fState: shipmentObject.fState,
      fCity: shipmentObject.fCity,
      fPostcode: shipmentObject.fPostcode,
      sAdd1: shipmentObject.sAdd1,
      sAdd2: shipmentObject.sAdd2,
      sCity: shipmentObject.sCity,
      sState: shipmentObject.sState,
      sPostcode: shipmentObject.sPostcode,
    };
    let validate = await dispatch(validateAddress(data));
    if (validate.data.Status === 200) {
      setShowInvalidAddress(false);
    } else {
      setShowInvalidAddress(true);
    }
  };

  useEffect(() => {
    setthresholdvalue(fundDetailsvalue?.threshold);
    setavailableAmount(fundDetailsvalue?.balanceAmount);
  }, [fundDetailsvalue?.threshold, fundDetailsvalue?.availableAmount]);

  const delayedAddressValidatorQuery = useCallback(
    debounce(validateRecieverAddress, 1000),
    [shipmentObject]
  );

  const fetchMarketPlaceListing = async () => {
    // setLoader(true);
    let data = {
      company_seller_id: String(createdBy?.Value?.companySellerId),
    };

    let resData = await marketPlaceIntegrationListing(data);
    if (resData && resData?.data?.Status === 200) {
      if (resData?.data?.EntityList?.length) {
        let getUnique = _.uniqBy(resData?.data?.EntityList, 'integration_id');
        setMarketIntegrationList(getUnique);
      }
    }
    setLoader(false);
  };

  const handleCopyClipboard = async (e, item) => {
    e.preventDefault();
    await navigator.clipboard.writeText(item);
    toast.success("Tracking Number Copied");
  };

  const getZipZonesShipmentRates = async () => {
    setGetZipZoneStatus(false);
    let data = {
      ...shipmentObject,
      employeeID: createdBy?.Value?.employeeId,
      companySellerId: createdBy?.Value?.companySellerId,
      authToken: "",
      labelType:
        parseInt(shipmentObject.receiverCountryID) !== 218
          ? "international"
          : "domestic",
    };
    let getRatesResponse = await dispatch(getZipZoneRatesByServiceType(data));
    setGetZipZoneStatus(true);
    if (getRatesResponse.data.Status === 200) {
      setZipZoneRates(getRatesResponse.data.Entity);
      setGetError("");
    } else {
      setZipZoneRates("");
      setGetError(getRatesResponse?.data?.Message?.AppStatusDescription);
      toast.error(getRatesResponse?.data?.Message?.AppStatusDescription);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(debounce(getZipZonesShipmentRates, 1000), [
    shipmentObject,
  ]);

  useEffect(() => {
    if (!validationsList.length) {
      delayedAddressValidatorQuery();
      delayedQuery();
      return delayedQuery.cancel;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationsList]);

  useEffect(() => {
    if (validationsList.length === 0) {
      delayedAddressValidatorQuery();
      return delayedAddressValidatorQuery.cancel;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentObject, delayedAddressValidatorQuery, validationsList]);

  useEffect(() => {
    if (USSates?.length) {
      let item = {
        CountryId: 218,
        Id: 0,
        StateCode: "----- Military -----",
        StateName: "",
        createdBy: null,
        createdDate: "2023-01-10T11:57:46.783",
        isActive: true,
        modifiedBy: null,
        modifiedDate: "2023-01-10T11:57:46.783",
      };
      USSates.splice(USSates?.length - 3, 0, item);
    }
  }, [USSates, createdBy]);

  useEffect(() => {
    let copyShipmentObj = JSON.parse(JSON.stringify(newShipmentObj));
    copyShipmentObj = {
      ...copyShipmentObj,
      fName: employeeAddress?.name,
      fCompany: employeeAddress?.companyName,
      senderCompanyID: employeeAddress?.companyId,
      fTel: employeeAddress?.phone,
      fAdd1: employeeAddress?.address1 || "",
      fAdd2: employeeAddress?.address2 || "",
      fAdd3: employeeAddress?.address3 || "",
      fPostcode: employeeAddress?.zip || "",
      fState: employeeAddress?.state || "",
      senderCountryID: employeeAddress?.countryId || 218,
      fCity: employeeAddress?.city || "",
      fEmail: employeeAddress?.emailId || "",
    };
    setShipmentObject(copyShipmentObj);
    setNewShipmentObj(JSON.parse(JSON.stringify(copyShipmentObj)));
  }, [employeeAddress]);

  useEffect(() => {
    if (createdBy) {
      dispatch(
        getCsvActiveIntegration(
          `?createdBy=${createdBy?.Value?.employeeId}&sellerId=${createdBy?.Value?.sellerId}&companyId=${createdBy?.Value?.companyId}&isAdmin=${createdBy?.Value?.isSuperAdmin}`
        )
      );
      setOrderListLoaded(true);
      getPendingOrderNumbers();
      dispatch(getSellerAddressById(0));
      dispatch(getAllServices(false, false));
      dispatch(getAllCountries());
      dispatch(getStates(218));
      setSaveReciever(false);
      fetchMarketPlaceListing();
    }
  }, [createdBy]);

  useEffect(() => {
    handleValidations();
  }, [
    shipmentObject,
    hasDifferentAddress,
    thresholdvalue,
    availableAmount
  ]);

  useEffect(() => {
    if (
      shipmentObject.receiverCountryID &&
      Number(shipmentObject.receiverCountryID) !== 218
    ) {
      setSelectedPackage();
      setSelectedService();
      setShipmentObject({
        ...shipmentObject,
        serviceName: "",
        serviceID: 0,
        packageID: "",
        packageName: "",
      });
      dispatch(getAllServices(true, false));
    } else {
      if (
        Number(shipmentObject.receiverCountryID) === 218 &&
        (shipmentObject.sState === "AA" ||
          shipmentObject.sState === "AE" ||
          shipmentObject.sState === "AP")
      ) {
        dispatch(getAllServices(false, true));
      } else {
        dispatch(getAllServices(false, false));
      }
    }
  }, [shipmentObject.receiverCountryID, shipmentObject.sState]);

  useEffect(() => {
    if (shipmentObject.serviceID) {
      dispatch(getSignatureOptionList(shipmentObject.serviceID));
    }
  }, [shipmentObject.serviceID]);

  useEffect(() => {
    if (shipmentObject.carrierCompanyId) {
      dispatch(getContentTypeListService(shipmentObject.carrierCompanyId));
    }
  }, [shipmentObject.carrierCompanyId]);

  useEffect(() => {
    if (
      shipmentObject?.contentType !== "Other" &&
      shipmentObject?.contentsExplanation
    ) {
      let shipObj = { ...shipmentObject };
      shipObj.contentsExplanation = "";
      setShipmentObject(shipObj);
    }
  }, [shipmentObject?.contentType]);

  const handleUnitChange = (e) => {
    const { value } = e.target;
    if (shipmentObject.carrierCompanyName === "FedEx") {
      let shipObj = { ...shipmentObject };
      let updateParcel = shipObj.parcels.map((item, index) => {
        return {
          ...item,
          length:
            value === "INCH"
              ? convertToInch(item.length)
              : convertToCm(item.length),
          width:
            value === "INCH"
              ? convertToInch(item.width)
              : convertToCm(item.width),
          height:
            value === "INCH"
              ? convertToInch(item.height)
              : convertToCm(item.height),
        };
      });
      shipObj.parcels = updateParcel;
      setShipmentObject(shipObj);
    }
    return;
  };

  useEffect(() => {
    let data = { ...shipmentObject };
    if (Boolean(shipmentObject.commodity)) {
      if (
        parseInt(shipmentObject.receiverCountryID) === 218 &&
        shipmentObject.sState !== "AA" &&
        shipmentObject.sState !== "AE" &&
        shipmentObject.sState !== "AP"
      ) {
        data.commodity.includeCommercial = false;
        setShipmentObject(data);
      } else {
        data.commodity.includeCommercial = true;
        setShipmentObject(data);
      }
    }
  }, [shipmentObject.receiverCountryID, shipmentObject.sState]);

  useEffect(() => {
    updateSenderAddress(employeeAddress);
  }, [employeeAddress, countries]);

  const handleSwipe = () => {
    if (!hasDifferentAddress && !isSenderEdit) {
      handleEditSender();
    } else {
      let senderObj = Object.assign({}, shipmentObject);
      let receiverObj = Object.assign({}, shipmentObject);
      senderObj.sName = senderObj.fName;
      senderObj.sCompany = senderObj.fCompany;
      // senderObj.senderCompanyID = senderObj.recieverCompanyID;
      senderObj.sTel = senderObj.fTel;
      senderObj.sEmail = senderObj.fEmail;
      senderObj.sAdd1 = senderObj.fAdd1 || "";
      senderObj.sAdd2 = senderObj.fAdd2 || "";
      senderObj.sPostcode = senderObj.fPostcode || "";
      senderObj.sState = senderObj.fState || "";
      senderObj.senderCountryID = senderObj.receiverCountryID || 218;
      senderObj.sCity = senderObj.fCity || "";
      senderObj.senderCompanyID = parseInt(senderObj.receiverCompanyID) || 0;

      senderObj.fName = receiverObj.sName;
      senderObj.fCompany = receiverObj.sCompany;
      // senderObj.fsenderCompanyID = receiverObj.senderCompanyID;
      senderObj.fTel = receiverObj.sTel;
      senderObj.fAdd1 = receiverObj.sAdd1 || "";
      senderObj.fEmail = receiverObj.sEmail;
      senderObj.fAdd2 = receiverObj.sAdd2 || "";
      senderObj.fPostcode = receiverObj.sPostcode || "";
      senderObj.fState = receiverObj.sState || "";
      senderObj.receiverCountryID = receiverObj.senderCountryID || 218;
      senderObj.fCity = receiverObj.sCity || "";
      senderObj.receiverCompanyID = parseInt(receiverObj.senderCompanyID) || 0;
      setShipmentObject(senderObj);
    }
  };

  const handleEditSender = () => {
    let newObj = { ...shipmentObject };
    newObj.sName = employeeAddress.name;
    newObj.sCompany = employeeAddress.companyId;
    newObj.senderCompanyID = employeeAddress.companyId;
    newObj.sTel = employeeAddress.phone;
    newObj.sAdd1 = employeeAddress.address1 || "";
    newObj.sAdd2 = employeeAddress.address2 || "";
    newObj.sPostcode = employeeAddress.zip || "";
    newObj.sState = employeeAddress.state || "";
    newObj.senderCountryID = employeeAddress.countryId || 218;
    newObj.sCity = employeeAddress.city || "";
    setShipmentObject(newObj);
    setIsSenderEdit(true);
  };

  const selectServicePackage = (value, prop) => {
    let editDetails = { ...editShipmentDetails };
    editDetails[prop] = value;
    dispatch(editShipment(editDetails));
    if (prop == "serviceID") {
      setSelectedService(value);
      setSelectedPackage(0);
      dispatch(getAllPackagesByServiceId(value));
    } else {
      setSelectedPackage(value);
    }
  };

  const updateSenderAddress = (address) => {
    let country = countries?.find((x) => x.id == address?.countryId);
    let details = {
      senderCountryID: address?.countryId || "",
      fCountry: country?.iSO || "",
      fName: address?.name || "",
      senderCompanyID: address?.companyId || "",
      fEmail: address?.email || "",
      fTel: address?.phone || "",
      fPostcode: address?.zip || "",
      fCity: address?.city || "",
      fState: address?.state || "",
      fAdd1: address?.address1 || "",
      fAdd2: address?.address2 || "",
      fadd3: address?.address3 || "",
    };
    dispatch(editShipment({ ...editShipmentDetails, ...details }));
  };

  const selectServiceAndPackageId = (
    prop,
    value,
    serviceName = "",
    carrierId = 0,
    companyName = ""
  ) => {
    let shipmentObj = { ...shipmentObject };
    shipmentObj[prop] = value;
    if (prop === "serviceID") {
      shipmentObj.packageID = 0;
      shipmentObj.serviceName = serviceName;
      shipmentObj.carrierCompanyId = carrierId;
      shipmentObj.carrierCompanyName = companyName;
    } else if (prop === "packageID") {
      shipmentObj.packageName = serviceName;
    }
    setShipmentObject(shipmentObj);
  };
  const handleReturnAddressChecked = (e, prop, relatedprop) => {
    const { checked } = e.target;
    let shipmentObj = { ...shipmentObject };
    shipmentObj[prop] = checked;
    shipmentObj[relatedprop] = checked;
    setShipmentObject(shipmentObj);
  };

  const handleTrimValue = (e, field) => {
    const { value } = e.target;
    let newValue = value.replace(/\s\s+/g, " ");
    let shipmentObj = { ...shipmentObject };
    shipmentObj[field] = newValue.trim();
    setShipmentObject(shipmentObj);
  };

  const selectIsResidential = (e) => {
    const { checked, name } = e.target;
    let shipmentObj = { ...shipmentObject };
    shipmentObj[name] = checked;
    setShipmentObject(shipmentObj);
  };
  const updateUserInput = (e, prop, index) => {
    const { value } = e.target;
    let shipmentObj = { ...shipmentObject };
    let ship = { ...editShipmentDetails };
    let editDetails = [...parcels];
    if (editDetails[index]) {
      editDetails[index][prop] = e.target.value;
    } else {
      let details = {};
      details[prop] = e.target.value;
      editDetails.push(details);
    }
    ship["parcels"] = editDetails;
    shipmentObj[prop] = value;
    if (prop === "emails") {
      shipmentObj.emailsValid = true;
    }
    if (prop === "dimensionUnit") {
      shipmentObj.weightUnit = value === "CMS" ? "KG" : "LBS";
      if (shipmentObj.carrierCompanyName === "FedEx") {
        let updateParcel = shipmentObj.parcels.map((item) => {
          return {
            ...item,
            length:
              value === "INCH"
                ? convertToInch(item.length)
                : convertToCm(item.length),
            width:
              value === "INCH"
                ? convertToInch(item.width)
                : convertToCm(item.width),
            height:
              value === "INCH"
                ? convertToInch(item.height)
                : convertToCm(item.height),
          };
        });
        shipmentObj.parcels = updateParcel;
      }
    }
    if (prop === "senderCompanyID") {
      let companyIndex = companies.findIndex(
        (item) => item.Id.toString() == value
      );
      shipmentObj.sCompany = companies[companyIndex].name;
    }
    if (prop === "receiverCompanyID") {
      let companyIndex = companies.findIndex(
        (item) => item.Id.toString() == value
      );
      shipmentObj.fCompany = companies[companyIndex].name;
    }
    if (prop === "senderCountryID") {
      let allCountries = [...countries];
      let countryIndex = allCountries.findIndex(
        (item) => item.id.toString() == value
      );
      shipmentObj.fTel = `+(${countries[countryIndex].phoneCountryCode}) `;
      shipmentObj.fCountry = countries[countryIndex]?.iSO;
    }
    if (prop === "receiverCountryID") {
      let allCountries = [...countries];
      let countryIndex = allCountries.findIndex(
        (item) => item.id.toString() == value
      );
      shipmentObj.sCountry = countries[countryIndex].iSO;
      shipmentObj.sTel = `+(${countries[countryIndex].phoneCountryCode}) `;
      shipmentObj.serviceName = "";
      shipmentObj.serviceID = 0;
      shipmentObj.packageID = "";
      shipmentObj.packageName = "";
      setServiceTab(1);
    }

    if (prop === "returnCountryId" || prop === "returncountyrcode") {
      let allCountries = [...countries];
      let countryIndex = allCountries.findIndex(
        (item) => item.id.toString() == value
      );
      shipmentObj.returncountrycode = countries[countryIndex].iSO;
      shipmentObj.returnphone = `+(${countries[countryIndex].phoneCountryCode}) `;
    }
    setParcels(editDetails);
    setShipmentObject(shipmentObj);
    dispatch(editShipment(ship));
  };

  const saveShipment = async (type) => {
    let copyShipmentObject = { ...shipmentObject };
    copyShipmentObject.shipmentDate = new Date(
      copyShipmentObject.shipmentDate
    ).toISOString();
    copyShipmentObject.createdDate = new Date().toISOString();
    copyShipmentObject.createdDate = new Date().toISOString();
    copyShipmentObject.employeeID = createdBy?.Value?.employeeId;
    copyShipmentObject.companySellerId = createdBy?.Value?.companySellerId;
    copyShipmentObject.modifiedDate = new Date().toISOString();
    copyShipmentObject.orderID = copyShipmentObject?.ordernumber;
    copyShipmentObject.createdBy = createdBy?.Value?.employeeId;
    copyShipmentObject.shipmentName = shipmentName;
    copyShipmentObject.sTel = copyShipmentObject.sTel
      ? copyShipmentObject.sTel
      : phoneNum;
    copyShipmentObject.fTel = copyShipmentObject.fTel
      ? copyShipmentObject.fTel
      : phoneNum;
    copyShipmentObject.receiverCountryID =
      copyShipmentObject.receiverCountryID.toString();
    copyShipmentObject.senderCountryID =
      copyShipmentObject.senderCountryID.toString();
    copyShipmentObject.authToken = "";
    copyShipmentObject.labelType =
      parseInt(shipmentObject.receiverCountryID) !== 218
        ? "international"
        : "domestic";

    // copyShipmentObject.orderID = copyShipmentObject.orderID +';
    copyShipmentObject.parcels.map((item, index) => {
      if (item.id === null || item.id === "") {
        item.id = 0;
      }
    });

    if (type === "save") {
      copyShipmentObject.shipedStatus = 1;
      copyShipmentObject.isReadyForShipment = false;
      copyShipmentObject.sellerId = createdBy?.Value?.sellerId;
      copyShipmentObject.sellerId = createdBy?.Value?.sellerId;
      copyShipmentObject.companyId = createdBy?.Value?.companyId;
      copyShipmentObject.createdBy = parseInt(createdBy?.Value?.employeeId);
      // copyShipmentObject.weightUnit = copyShipmentObject.dimensionUnit;
      copyShipmentObject.weightUnit =
        shipmentObject.dimensionUnit === "CMS" ? "KG" : "LBS";
      copyShipmentObject.employeeId =
        createdBy?.Value?.employeeId == null ||
          createdBy?.Value?.employeeId == ""
          ? copyShipmentObject.employeeId
          : createdBy?.Value?.employeeId;
    } else {
      copyShipmentObject.shipedStatus = 2;
      copyShipmentObject.isReadyForShipment = true;
      copyShipmentObject.totalAmount = zipZoneRates;
      copyShipmentObject.sellerId = createdBy?.Value?.sellerId;
      copyShipmentObject.companyId = createdBy?.Value?.companyId;
      // copyShipmentObject.weightUnit = copyShipmentObject.dimensionUnit;
      copyShipmentObject.weightUnit =
        shipmentObject.dimensionUnit === "CMS" ? "KG" : "LBS";
    }

    setLoader(true);
    let request = await dispatch(
      type === "save"
        ? saveShipmentDraft(copyShipmentObject)
        : createShipment(copyShipmentObject)
    );

    if (type === "save") {
      toggleShipmentSave();
    }
    if (request.data.Status === 200) {
      setShowInvalidAddress(false);
      if (type === "save") {
        toast.success("Shipment has been saved");
      } else {
        toast.success(request.data.Message.AppStatusDescription);
        setLoader(false);
        window.open(request.data.Entity.shippingLabel);
        setShippngLabel(request.data.Entity);
        getPendingOrderNumbers();
        dispatch(
          getLoginUserDetailsonFund({
            creditCompanySellerId: createdBy?.Value?.companySellerId,
          })
        );

        if (shipmentObject.marketplaceIntegrationId != "") {
          let data = {
            tracking_details: [
              {
                orderNumber: request.data.Entity.orderNumber,
                carrierCompanyName: request.data.Entity.carrierCompanyName,
                carrierCompanyId: request.data.Entity.carrierCompanyId,
                trackingUrl: request.data.Entity.exTrackingUrl,
                trackingNumber: request.data.Entity.trackingNumber,
                action_type: "shipment"
              },
            ]
          }
          await getTrackingDetails(data);
        }
      }
    } else if (request.data.Status === 402) {
      toast.error(request?.data?.Message?.AppStatusDescription);
    } else {
      toast.error(request?.data?.Entity?.errorMessage, 1500);
    }
    setLoader(false);
  };

  const handleShipDate = (value) => {
    let selectedDate = new Date(value);
    let currentDate = new Date();
    selectedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    setShipmentObject({
      ...shipmentObject,
      shipmentDate: value,
    });
    let shipDate = new Date(shipmentObject.shipmentDate);
    shipDate.setHours(0, 0, 0, 0);
    if (moment(shipDate).isBefore(currentDate)) {
      setShipmentObject({
        ...shipmentObject,
        shipmentDate: moment(value).utc(true).format(),
        isShipmentValidDate: false,
      });
    } else {
      setShipmentObject({
        ...shipmentObject,
        shipmentDate: moment(value).utc(true).format(),
        isShipmentValidDate: true,
      });
    }
  };

  const handleValidations = () => {
    let errorArray = [];
    let threshholdErrors = [];
    let fieldsError = {};
    if (shipmentObject.shipmentDate) {
      let shipDate = new Date(shipmentObject.shipmentDate);
      shipDate.setHours(0, 0, 0, 0);
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if (!shipmentObject.serviceID) {
        let obj = {
          name: "serviceID",
          errorName: "Service Type is required.",
        };
        errorArray.push(obj);
      }
      if (!shipmentObject.packageID) {
        let obj = {
          name: "packageID",
          errorName: "Package Type is required.",
        };
        errorArray.push(obj);
      }
      if (moment(shipDate).isBefore(currentDate)) {
        let obj = {
          name: "shipmentDate",
          errorName: "Shipment Date cannot be in the past",
        };
        errorArray.push(obj);
        fieldsError.shipDate = true;
      }

      if (
        shipmentObject.shippingreference &&
        shipmentObject.shippingreference.length > 70
      ) {
        let obj = {
          name: "shippingreference",
          errorName:
            "Shipper Reference length cannot be more than 70 characters.",
        };
        errorArray.push(obj);
        fieldsError.shippingreference = true;
      }

      if (!shipmentObject.ordernumber) {
        let obj = {
          name: "ordernumber",
          errorName: "Order number is required.",
        };
        errorArray.push(obj);
        fieldsError.ordernumber = true;
      }
      if (!shipmentObject.fName) {
        let obj = {
          name: "recieverName",
          errorName: "Sender name is required.",
        };
        errorArray.push(obj);
        fieldsError.fName = true;
      }

      if (shipmentObject.isDifferentAddress || shipmentObject.isReturnAddress) {
        if (!shipmentObject.fTel) {
          let obj = {
            name: "fTel",
            errorName: "Sender phone number is required.",
          };
          errorArray.push(obj);
          fieldsError.fTel = true;
        }

        if (!shipmentObject.returnname) {
          let obj = {
            name: "returnname",
            errorName: "Return address name is required.",
          };
          errorArray.push(obj);
          fieldsError.returnname = true;
        }

        if (!shipmentObject.returnemail) {
          let obj = {
            name: "returnemail",
            errorName: "Return address email is required.",
          };
          errorArray.push(obj);
          fieldsError.returnemail = true;
        }

        if (shipmentObject.returnphone) {
          if (
            shipmentObject.returnphone.endsWith("_") ||
            shipmentObject.returnphone.length < 10
          ) {
            let obj = {
              name: "returnphone",
              errorName: "Enter valid return address phone number.",
            };
            errorArray.push(obj);
            fieldsError.returnphone = true;
          }
        }

        if (!shipmentObject.returnzip) {
          let obj = {
            name: "returnzip",
            errorName: "Return Address zip code is required.",
          };
          errorArray.push(obj);
          fieldsError.returnzip = true;
        }
        if (
          parseInt(shipmentObject.returncountrycode) === 218 &&
          shipmentObject.returnzip &&
          !validUsZip.test(shipmentObject.returnzip)
        ) {
          let obj = {
            name: "recieverfpostcode",
            errorName: "Return zip must be XXXXX or XXXXX-XXXX",
          };
          errorArray.push(obj);
          fieldsError.returnzip = true;
        }

        if (!shipmentObject.returncity) {
          let obj = {
            name: "returncity",
            errorName: "Return Address city is required.",
          };
          errorArray.push(obj);
          fieldsError.returncity = true;
        }
        if (!shipmentObject.returnstate) {
          let obj = {
            name: "returnstate",
            errorName: "Return Address State is required.",
          };
          errorArray.push(obj);
          fieldsError.returnstate = true;
        }

        if (!shipmentObject.returnaddressline1) {
          let obj = {
            name: "returnaddressline1",
            errorName: "Return Address is required.",
          };
          errorArray.push(obj);
          fieldsError.returnaddressline1 = true;
        }
      }

      if (!shipmentObject.fEmail) {
        let obj = {
          name: "recieverEmail",
          errorName: "Sender email is required.",
        };
        errorArray.push(obj);
        fieldsError.fEmail = true;
      }

      if (availableAmount <= thresholdvalue) {
        let obj = {
          errorName:
            "You have reached your threshold limit. Please recharge your wallet to enjoy the continued service.",
        };
        threshholdErrors.push(obj);
      }

      if (
        availableAmount > thresholdvalue &&
        availableAmount - Number(zipZoneRates) < thresholdvalue
      ) {
        let obj = {
          errorName:
            "Your wallet balance will drop below the threshold limit. The wallet balance is not enough to proceed with this transaction. Please recharge your wallet to enjoy the continued service.",
        };
        threshholdErrors.push(obj);
      }
      if (shipmentObject.fEmail && !shipmentObject.fEmail.match(isValidEmail)) {
        let obj = {
          name: "recieverEmail",
          errorName: "Valid sender email is required.",
        };
        errorArray.push(obj);
        fieldsError.fEmail = true;
      }
      if (shipmentObject.fTel) {
        if (
          shipmentObject.fTel.endsWith("_") ||
          shipmentObject.fTel.length < 10
        ) {
          let obj = {
            name: "recieverftel",
            errorName: "Enter valid sender phone number.",
          };
          errorArray.push(obj);
          fieldsError.fTel = true;
        }
      }

      if (!shipmentObject.receiverCountryID) {
        let obj = {
          name: "receiverCountryID",
          errorName: "Sender country is required.",
        };
        errorArray.push(obj);
      }
      if (
        parseInt(shipmentObject.senderCountryID) === 218 &&
        !shipmentObject.fPostcode
      ) {
        let obj = {
          name: "recieverfpostcode",
          errorName: "Sender zip code is required.",
        };
        errorArray.push(obj);
        fieldsError.fPostcode = true;
      }

      if (
        parseInt(shipmentObject.senderCountryID) === 218 &&
        shipmentObject.fPostcode &&
        !validUsZip.test(shipmentObject.fPostcode)
      ) {
        let obj = {
          name: "recieverfpostcode",
          errorName: "Sender zip must be XXXXX or XXXXX-XXXX",
        };
        errorArray.push(obj);
        fieldsError.fPostcode = true;
      }
      if (!shipmentObject.fCity) {
        let obj = {
          name: "recieverCity",
          errorName: "Sender city is required.",
        };
        errorArray.push(obj);
        fieldsError.fCity = true;
      }

      if (!shipmentObject.fState) {
        let obj = {
          name: "recieverState",
          errorName: "Sender State is required.",
        };
        errorArray.push(obj);
        fieldsError.fState = true;
      }

      if (!shipmentObject.fAdd1) {
        let obj = {
          name: "recieverAddress",
          errorName: "Sender Address is required.",
        };
        errorArray.push(obj);
        fieldsError.fAdd1 = true;
      }

      if (!shipmentObject.sName) {
        let obj = {
          name: "recieverName",
          errorName: "Receiver name is required.",
        };
        errorArray.push(obj);
        fieldsError.sName = true;
      }
      if (!shipmentObject.sEmail) {
        let obj = {
          name: "recieverEmail",
          errorName: "Receiver email is required.",
        };
        errorArray.push(obj);
        fieldsError.sEmail = true;
      }
      if (shipmentObject.sEmail && !shipmentObject.sEmail.match(isValidEmail)) {
        let obj = {
          name: "senderEmail",
          errorName: "Valid Receiver email is required.",
        };
        errorArray.push(obj);
        fieldsError.sEmail = true;
      }
      if (shipmentObject.sTel) {
        if (
          shipmentObject.sTel.endsWith("_") ||
          shipmentObject.sTel.length < 10
        ) {
          let obj = {
            name: "recieverftel",
            errorName: "Enter valid receiver phone number.",
          };
          errorArray.push(obj);
          fieldsError.sTel = true;
        }
      }
      if (!shipmentObject.senderCountryID) {
        let obj = {
          name: "senderCountryID",
          errorName: "Sender country is required.",
        };
        errorArray.push(obj);
      }
      if (
        parseInt(shipmentObject.receiverCountryID) === 218 &&
        !shipmentObject.sPostcode
      ) {
        let obj = {
          name: "spostcode",
          errorName: "Receiver zip code is required.",
        };
        errorArray.push(obj);
        fieldsError.sPostcode = true;
      }
      if (
        parseInt(shipmentObject.receiverCountryID) === 218 &&
        shipmentObject.sPostcode &&
        !validUsZip.test(shipmentObject.sPostcode)
      ) {
        let obj = {
          name: "spostcode",
          errorName: "Receiver zip must be XXXXX or XXXXX-XXXX",
        };
        errorArray.push(obj);
        fieldsError.sPostcode = true;
      }
      if (!shipmentObject.sCity) {
        let obj = {
          name: "scity",
          errorName: "Receiver city is required.",
        };
        errorArray.push(obj);
        fieldsError.sCity = true;
      }
      if (!shipmentObject.sState) {
        let obj = {
          name: "sstate",
          errorName: "Receiver state is required.",
        };
        errorArray.push(obj);
        fieldsError.sState = true;
      }
      if (!shipmentObject.sAdd1) {
        let obj = {
          name: "sadd1",
          errorName: "Receiver address is required.",
        };
        errorArray.push(obj);
        fieldsError.sAdd1 = true;
      }

      if (shipmentObject.parcels.length) {
        if (shipmentObject.packageID > 0 && shipmentObject.serviceID > 0) {
          let validateParcels = shipmentObject.parcels.filter((parcel) => {
            if (!parcel.length || !parcel.width || !parcel.height) {
              return parcel;
            }
          });
          let obj = {
            name: "parcels",
            errorName: "Package dimensions are missing.",
          };
          if (validateParcels.length) {
            errorArray.push(obj);
          }

          let validateParcelsWeight = shipmentObject.parcels.filter(
            (parcel) => {
              if (!parcel.weight && !parcel.ounces) {
                return parcel;
              }
            }
          );
          let weightObj = {
            name: "packageWeight",
            errorName: "Package weight is missing.",
          };
          if (validateParcelsWeight.length) {
            errorArray.push(weightObj);
          }
        }
      }
      if (!!Number(shipmentObject.sendNotification)) {
        if (!shipmentObject.emailsValid || !shipmentObject.emails) {
          let obj = {
            name: "emailsValid",
            errorName: "Enter valid notification emails",
          };
          errorArray.push(obj);
        }
      }

      if (
        parseInt(shipmentObject.receiverCountryID) !== 218 ||
        shipmentObject.sState === "AA" ||
        shipmentObject.sState === "AE" ||
        shipmentObject.sState === "AP"
      ) {
        let validateUnitValue = shipmentObject.commodity.commodityList.filter(
          (parcel) => {
            if (!parcel.unitValue) {
              return parcel;
            }
          }
        );
        let unitValueObj = {
          name: "unitValue",
          errorName: "Commodity's Unit Value is missing.",
        };
        if (validateUnitValue.length) {
          errorArray.push(unitValueObj);
        }

        let validateQuantityValue =
          shipmentObject.commodity.commodityList.filter((parcel) => {
            if (!parcel.quantity) {
              return parcel;
            }
          });
        let qunatityValueObj = {
          name: "unitValue",
          errorName: "Commodity's Quantity Value is missing.",
        };
        if (validateQuantityValue.length) {
          errorArray.push(qunatityValueObj);
        }

        //Validate description
        let validateDescriptionValue =
          shipmentObject.commodity.commodityList.filter((parcel) => {
            if (!parcel.description) {
              return parcel;
            }
          });
        if (validateDescriptionValue.length) {
          errorArray.push({
            name: "description",
            errorName: "Commodity's Description is missing.",
          });
        }
        let validateWeightValue = shipmentObject.commodity.commodityList.filter(
          (parcel) => {
            if (!parcel.weight) {
              return parcel;
            }
          }
        );
        let validateOunceValue = shipmentObject.commodity.commodityList.filter(
          (parcel) => {
            if (!parcel.ounces) {
              return parcel;
            }
          }
        );

        if (validateWeightValue.length && validateOunceValue.length) {
          errorArray.push({
            name: "weight",
            errorName: "Commodity's Weight is missing.",
          });
        }
        if (!shipmentObject.contentType) {
          let obj = {
            name: "contentType",
            errorName: "Content Type is required.",
          };
          errorArray.push(obj);
          fieldsError.contentType = true;
        }

        if (
          shipmentObject.contentType === "Other" &&
          !shipmentObject.contentsExplanation
        ) {
          let obj = {
            name: "contentsExplanation",
            errorName: "Content explanation is required.",
          };
          errorArray.push(obj);
          fieldsError.contentsExplanation = true;
        }
      }
      if (
        shipmentObject?.sendFedExNotification &&
        !shipmentObject?.onDelivery &&
        !shipmentObject?.onException &&
        !shipmentObject?.onShipment &&
        !shipmentObject?.onTender &&
        !shipmentObject?.onEstimatedDelivery
      ) {
        let obj = {
          name: "sendFedexNotification",
          errorName: "Please select alteast one FedEx Notification option",
        };
        errorArray.push(obj);
        fieldsError.sendFedexNotification = true;
      }

      if (shipmentObject?.sendFedExNotification) {
        if (!shipmentObject.emails) {
          let obj = {
            name: "emailValid",
            errorName: "Enter email address",
          };
          errorArray.push(obj);
          fieldsError.emailValid = true;
        } else if (!shipmentObject.emails.match(isValidEmail)) {
          let obj = {
            name: "emailValid",
            errorName: "Enter valid email address",
          };
          errorArray.push(obj);
          fieldsError.emailValid = true;
        }
      }
    }
    setValidationsList(errorArray);
    setthresholdValidation(threshholdErrors);
    setErrorFields(fieldsError);
  };

  const handleLoadAddress = (e, type) => {
    e.preventDefault();
    setShowLoadAddressDialogObj({
      showModal: true,
      addressType: type,
    });
  };

  const addParcel = () => {
    let parcelObj = {
      createdBy: createdBy?.Value?.employeeId,
      createdDate: new Date().toISOString(),
      modifiedBy: 0,
      modifiedDate: new Date().toISOString(),
      isActive: true,
      id: 0,
      shipmentID: 0,
      box: 0,
      weight: null,
      ounces: null,
      length: null,
      width: null,
      height: null,
      sku: "string",
      cnName: "string",
      enName: "string",
      price: 0,
      num: 0,
      material: "string",
      use: "string",
      cWeight: 0,
      hsCode: "string",
      battery: "string",
    };
    let copyShipmentObject = { ...shipmentObject };
    copyShipmentObject.parcels.push(parcelObj);
    setShipmentObject(copyShipmentObject);
  };

  const removeParcel = (index) => {
    let copyShipmentObject = { ...shipmentObject };
    copyShipmentObject.parcels.splice(index, 1);
    setShipmentObject(copyShipmentObject);
  };

  const handleParcelChange = (e, name, index) => {
    const { value } = e.target;
    let copyShipmentObject = JSON.parse(JSON.stringify(shipmentObject));
    if (!isNaN(value) && validDecimalNum.test(value)) {
      // let val = value.indexOf(".") === -1 ? value : value.indexOf(".") && value.split('.')[1].length <= 2 ? value : value.substring(0, 5);
      copyShipmentObject.parcels[index][name] = value;
      // let total = copyShipmentObject.parcels.reduce((a, v) => a = a + Number(v.weight), 0);
      let total = copyShipmentObject.parcels.reduce(function (acc, obj) {
        return acc + Number(obj.weight);
      }, 0);
      let totalOunceGm = copyShipmentObject.parcels.reduce(function (acc, obj) {
        return acc + Number(obj.ounces);
      }, 0);
      copyShipmentObject.totalWeight = total;
      copyShipmentObject.totalOunceGm = totalOunceGm;
      setShipmentObject(copyShipmentObject);
    }
  };

  const validateEmails = (e) => {
    let copyShipmentObject = { ...shipmentObject };
    let isValid = true;
    if (copyShipmentObject.emails) {
      let emailList = copyShipmentObject.emails;
      let emails = emailList.replace(/\s/g, "").split(",");
      if (emails.length) {
        for (let i = 0; i < emails.length; i++) {
          if (emails[i] === "" || !validateMultipleEmails.test(emails[i])) {
            isValid = false;
          }
        }
      }
    }
    copyShipmentObject.emailsValid = isValid;
    setShipmentObject(copyShipmentObject);
  };

  const handleArrivalDate = (serviceId) => {
    if (serviceId === 3) {
      setPackageArrivalDate(
        moment(new Date(shipmentObject.shipmentDate))
          .add(2, "d")
          .format("MMMM D, YYYY")
      );
    } else if (serviceId === 4) {
      setPackageArrivalDate(
        moment(new Date(shipmentObject.shipmentDate))
          .add(1, "d")
          .format("MMMM D, YYYY")
      );
    } else {
      setPackageArrivalDate("No date provided");
    }
  };

  const getPendingOrderNumbers = async (integration = false) => {
    setOrderListLoaded(false);
    let data = `createdBy=${createdBy?.Value?.employeeId}&isAdmin=${createdBy?.Value?.isAdmin
      }&sellerId=${createdBy?.Value?.sellerId}&companySellerId=${createdBy?.Value?.companySellerId
      }&companyId=${createdBy?.Value?.companyId}&marketplaceIntegrationId=${integration ? "" : integrationValue
      }`;
    let response = await dispatch(getPendingOrders(data));
    if (response?.data && response.data.Status === 200) {
      if (response.data?.EntityList?.length) {
        setOrderListLoaded(true);
        setOrderNumberData(Object.values(response.data.EntityList));
        setOrderNumberDataCopy(Object.values(response.data.EntityList));
      } else {
        setOrderListLoaded(true);
        setOrderNumberData([]);
        setOrderNumberDataCopy([]);
      }
    }
  };

  const handleSelectedOrder = (Id) => {
    setSelectedOrder(Id);
    axios
      .get(`${apiUrl}/api/GetPendingOrderDetailsById?id=${Id}`)
      .then((resp) => {
        let res = resp.data.Entity;
        res.shipmentDate = res.shipmentDate ? res.shipmentDate : new Date();
        if (!res.parcels.length) {
          res.parcels = [
            {
              createdBy: createdBy?.Value?.employeeId,
              createdDate: new Date().toISOString(),
              modifiedBy: 0,
              modifiedDate: new Date().toISOString(),
              isActive: true,
              id: 0,
              shipmentID: 0,
              box: 0,
              weight: null,
              ounces: null,
              length: null,
              width: null,
              height: null,
              sku: "",
              cnName: "",
              enName: "",
              price: 0,
              num: 0,
              material: "",
              use: "",
              cWeight: 0,
              hsCode: "",
              battery: "",
            },
          ];
        }

        res.receiverCountryID = res.receiverCountryID
          ? res.receiverCountryID
          : 218;
        res.senderCountryID = res.senderCountryID ? res.senderCountryID : 218;
        res.sCountry = res.sCountry ? res.sCountry : "US";
        res.fCountry = res.fCountry ? res.fCountry : "US";
        let getTotalWeight = res.parcels
          ? res.parcels.reduce((n, { weight }) => n + parseFloat(weight), 0)
          : 0;
        let getTotalOunceGm = res.parcels.reduce(function (acc, obj) {
          return acc + Number(obj.ounces);
        }, 0);
        res.totalOunceGm = getTotalOunceGm;
        res.dimensionUnit = res.dimensionUnit ? res.dimensionUnit : "INCH";
        res.note = "note";
        if (!res.commodity) {
          res.commodity = JSON.parse(JSON.stringify(commodityObj));
        }
        // res.serviceID = res.serviceId;
        res.packageID = res.packageId;
        if (res.serviceID > 0) {
          setSelectedService(res.serviceID);
          selectServicePackage(res.serviceID, "serviceID");
          if (res.packageId > 0) {
            setSelectedPackage(res.packageId);
          }
        } else {
          setSelectedService(0);
          setSelectedPackage(0);
        }

        if (res.serviceID && res.packageID) {
          setServiceTab(3);
        } else if (res.serviceID && !res.packageID) {
          setServiceTab(2);
        } else {
          setServiceTab(1);
        }
        if (res.emails) {
          if (validateEmailsOnLoad(res.emails)) {
            res.emailsValid = true;
          } else {
            res.emailsValid = false;
          }
        } else {
          res.emailsValid = true;
        }
        setShipmentObject(res);
      });
  };

  const handleRefreshOrders = () => {
    setOrderNumberData([]);
    getPendingOrderNumbers(true);
    setSeachTxtOrder("");
  };

  const handleFilterOrder = (e) => {
    const { value } = e.target;
    let filterData = orderNumberDataCopy.filter((item) => {
      if (item.OrderNumber.toLowerCase().includes(value.toLowerCase())) {
        return item;
      }
    });
    setSeachTxtOrder(value);
    setOrderNumberData(filterData);
  };

  const handleYesDeleteAlert = async () => {
    let checkedData = [...orderNumberData].filter((item) => item.isChecked);
    let currentOrderSelected = _.isEmpty(currentSelectedRecord)
      ? _.map(checkedData, "Id")
      : [currentSelectedRecord.Id];
    let data = {
      id: currentOrderSelected,
    };
    handleDeleteMethod(data);
  };

  const handleDeleteMethod = async (data) => {
    let deleteOrderResponse = await deleteOrder(data);
    if (deleteOrderResponse.data.Status === 200) {
      toast.success("Order deleted Successfully");
      createNewShipment();
      getPendingOrderNumbers();
    } else {
      toast.error("Something went wrong", 1000);
    }
    handleCancelDeleteAlert();
    createNewShipment();
  };

  const handleCancelDeleteAlert = () => {
    setCurrentSelectedRecord({});
    setShowDeleteShipDialog(false);
  };

  const toggleShipmentSave = () => {
    let copyShipmentObject = { ...shipmentObject };
    copyShipmentObject.shipmentName = "";
    setShipmentObject(copyShipmentObject);
    setShowSaveShipment(!showSaveShipment);
  };

  const toggleLoadShipment = () => {
    setShowLoadOrders(!showLoadOrders);
  };

  const loadShipmentData = (shipmentObj) => {
    if (shipmentObj.serviceID) {
      selectServicePackage(shipmentObj.serviceID, "serviceID");
    }
    setSelectedService(shipmentObj.serviceID);
    if (shipmentObj.packageID > 0) {
      setSelectedPackage(shipmentObj.packageID);
    }
    let getTotalWeight = shipmentObj.parcels
      ? shipmentObj.parcels.reduce((n, { weight }) => n + parseFloat(weight), 0)
      : 0;
    let getTotalOunceGm = shipmentObj.parcels.reduce(function (acc, obj) {
      return acc + Number(obj.ounces);
    }, 0);
    shipmentObj.totalWeight = getTotalWeight;
    shipmentObj.totalOunceGm = getTotalOunceGm;
    shipmentObj.fCountry = shipmentObj.fCountry ? shipmentObj.fCountry : "US";
    if (shipmentObj.emails) {
      if (validateEmailsOnLoad(shipmentObj.emails)) {
        shipmentObj.emailsValid = true;
      } else {
        shipmentObj.emailsValid = false;
      }
    } else {
      shipmentObj.emailsValid = true;
    }
    if (shipmentObj.serviceID && shipmentObj.packageID) {
      setServiceTab(3);
    } else if (shipmentObj.serviceID && !shipmentObj.packageID) {
      setServiceTab(2);
    } else {
      setServiceTab(1);
    }
    setShipmentObject(shipmentObj);
    toggleLoadShipment();
  };

  const validateEmailsOnLoad = (emailsString) => {
    let isValid = true;
    let emailList = emailsString;
    let emails = emailList.replace(/\s/g, "").split(",");
    if (emails.length) {
      for (let i = 0; i < emails.length; i++) {
        if (emails[i] === "" || !validateMultipleEmails.test(emails[i])) {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  const createNewShipment = () => {
    const newShipmentObject = JSON.parse(JSON.stringify(newShipmentObj));
    setNewShipMent(true);
    setSelectedPackage();
    setSelectedService();
    setShipmentObject(newShipmentObject);
    setSelectedOrder(0);
    setShippngLabel({});
    setHasDifferentAddress(false);
    setGetZipZoneStatus(false);
    setServiceTab(1);
    setIsPackageSelected({});
    setShowInvalidAddress(false);
  };

  const saveOrderChanges = async (e) => {
    e.preventDefault();
    setSaveOrderLoader(true);
    let shipObj = { ...shipmentObject };
    shipObj.createdBy = createdBy?.Value?.employeeId;
    let res = await updateAddress(shipObj);

    if (res.data.Status === 200) {
      setSaveOrderLoader(false);
      toast.success("Order Updated Successfully");
    } else {
      setSaveOrderLoader(false);
      toast.error("Something went wrong");
    }
  };

  const handleSendNotification = (e, field) => {
    const { checked } = e.target;
    let copyShipmentObject = { ...shipmentObject };
    copyShipmentObject[field] = checked;
    if (!checked) {
      copyShipmentObject.emailsValid = true;
      copyShipmentObject.emails = "";
    } else {
      copyShipmentObject.emails = "";
    }
    setShipmentObject(copyShipmentObject);
  };

  const saveAddress = async (type) => {
    let dataAddressObj = { ...commonAddressObj };
    if (type === "receiver") {
      dataAddressObj.name = shipmentObject.sName;
      dataAddressObj.company = shipmentObject.sCompany;
      dataAddressObj.addressLine1 = shipmentObject.sAdd1;
      dataAddressObj.addressLine2 = shipmentObject.sAdd2;
      dataAddressObj.addressLine3 = shipmentObject.sAdd3;
      dataAddressObj.city = shipmentObject.sCity;
      dataAddressObj.state = shipmentObject.sState;
      dataAddressObj.zip = shipmentObject.sPostcode;
      dataAddressObj.countryId = Number(shipmentObject.receiverCountryID);
      dataAddressObj.phone = shipmentObject.sTel;
      dataAddressObj.isSender = false;
      dataAddressObj.isReceiver = true;
      dataAddressObj.companySellerId = createdBy?.Value?.companySellerId;
      dataAddressObj.userId = createdBy?.Value?.employeeId;
    } else if (type === "sender") {
      dataAddressObj.name = shipmentObject.fName;
      dataAddressObj.company = shipmentObject.fCompany;
      dataAddressObj.addressLine1 = shipmentObject.fAdd1;
      dataAddressObj.addressLine2 = shipmentObject.fAdd2;
      dataAddressObj.addressLine3 = shipmentObject.fAdd3;
      dataAddressObj.city = shipmentObject.fCity;
      dataAddressObj.state = shipmentObject.fState;
      dataAddressObj.zip = shipmentObject.fPostcode;
      dataAddressObj.countryId = Number(shipmentObject.senderCountryID);
      dataAddressObj.phone = shipmentObject.fTel;
      dataAddressObj.isSender = true;
      dataAddressObj.isReceiver = false;
      dataAddressObj.companySellerId = createdBy?.Value?.companySellerId;
      dataAddressObj.userId = createdBy?.Value?.employeeId;
    }
    let resSaveAddress = await createAddress(dataAddressObj);
    if (
      resSaveAddress &&
      resSaveAddress.data &&
      resSaveAddress.data.Status === 200
    ) {
      toast.success(resSaveAddress?.data?.Message?.AppStatusDescription);
    } else {
      toast.error(resSaveAddress?.data?.Message?.AppStatusDescription);
    }
  };

  const handleIntegrationChange = (event) => {
    const { value } = event.target;
    setIntegrationValue(value);
  };

  const handleFilterMarketPlace = async () => {
    getPendingOrderNumbers();
  };

  const handleFedExNotification = (e) => {
    const { checked, name } = e.target;
    let copyShipmentObject = { ...shipmentObject };
    copyShipmentObject[name] = checked;
    setShipmentObject(copyShipmentObject);
  };

  const handleOrderCheck = (e, index) => {
    // e.preventDefault();
    e.stopPropagation();
    let dataList = [...orderNumberData];
    dataList[index].isChecked = e.target.checked;
    setOrderNumberData(dataList);
  };

  const handleSelectAllOrder = (e) => {
    let dataList = [...orderNumberData];
    let updateData = dataList.map((item) => {
      return {
        ...item,
        isChecked: e.target.checked
      }
    });
    setSelectAllOrders(e.target.checked);
    setOrderNumberData(updateData);
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading">Shipment</div>
        <div className="cus-page-content-sec">
          <div className="content-container">
            <div className="shipment-container Custom-ship-contain">
              <div className="row">
                <div className="col-sm-12 col-xl-3 col-lg-3 pd-rt">
                  <div className="details">
                    {checkPermission(orderManagement, shipmentModule, permssionCreate, featureSubmodulePermission, employeePermissions) && (
                      <div
                        className="create-new-button btn-create"
                        onClick={createNewShipment}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        <span>Create New Shipment</span>
                      </div>
                    )}
                    <div className="ordershipment">
                      {checkPermission(settings, SUBF_MARKETPLACEINTEGRATION, '', featureSubmodule, employeePermissions) && (
                        <>
                          {marketIntegrationList.length > 0 && (
                            <>
                              <div className="orderrefresh">
                                Marketplace Integration
                              </div>
                              <div className="mb-20 marketPlaceInt">
                                <select
                                  className="form-control"
                                  value={integrationValue}
                                  name={"integration"}
                                  onChange={(e) => handleIntegrationChange(e)}
                                >
                                  <option value={''}>Select Integration</option>
                                  {marketIntegrationList?.map((item) => (
                                    <option
                                      value={item.integration_id}
                                      key={item.integration_id}
                                    >
                                      {item.integration_name}
                                    </option>
                                  ))}
                                </select>

                                <button
                                  className="cus-primary-bg-btn custom-btn-shipment searchBtn"
                                  onClick={handleFilterMarketPlace}
                                  id="filterInte"
                                  disabled={Number(integrationValue) === 0}
                                >
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  />
                                </button>
                                <WmsTooltip
                                  anchorId={"filterInte"}
                                  content={"Filter Integration"}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}

                      <div className="flex-container">
                        <div className="flexShipment">
                          <div className="">
                            {orderNumberDataCopy.length} order(s)
                          </div>
                          <div className="">
                            {checkPermission(orderManagement, shipmentModule, permssionDelete, featureSubmodulePermission, employeePermissions) && (
                              <button
                                className="cus-primary-bg-btn custom-btn-shipment searchBtn refreshorder btn"
                                disabled={
                                  orderNumberData.filter(
                                    (item) => item.isChecked
                                  ).length === 0
                                }
                                onClick={() => setShowDeleteShipDialog(true)}
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="flexitem2">
                          <div className="flexitem2icon">
                            <button
                              className="cus-primary-bg-btn custom-btn-shipment searchBtn refreshorder"
                              onClick={() => {
                                setIntegrationValue("");
                                handleRefreshOrders();
                              }}
                              id="refresh"
                            >
                              <i
                                className="fa fa-refresh"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <WmsTooltip
                              anchorId={"refresh"}
                              content={"Refresh List"}
                            />
                            <button
                              className="cus-primary-bg-btn custom-btn-shipment searchBtn"
                              onClick={() => setCsvModalShow(true)}
                              id="upload"
                            >
                              <i
                                className="fa fa-upload"
                                aria-hidden="true"
                              ></i>
                            </button>

                            <WmsTooltip
                              anchorId={"upload"}
                              content={"Upload CSV"}
                            />
                          </div>
                          {/* for serachiconbar */}
                          <div className="shipordersearchbar">
                            <input
                              className="inputshiporder form-control"
                              onChange={handleFilterOrder}
                              // autoComplete="off"
                              placeholder="Search Order"
                              type="text"
                              value={seachTxtOrder}
                            />
                          </div>
                        </div>
                      </div>
                      <hr className="headingorder" />
                      <div className="scrolorder custom-scrol">
                        <div className="typeAddress selectAllWrapper">
                          <div className="selectAll">
                            <input
                              type="checkbox"
                              checked={selectAllOrders}
                              onChange={handleSelectAllOrder}
                              disabled={!orderNumberData.length}
                            /> Select All
                          </div>
                          <div className="selectedDetails">
                            {`Selected ${orderNumberData.filter((item) => item.isChecked).length} of ${orderNumberData.length}`}
                          </div>
                        </div>
                        <div className="flexcover">
                          <div className="shiporderrecord">
                            <Table className="table-borderless border-0 orderListTable custom-date-td">
                              <tbody>
                                {orderListLoaded ? (
                                  orderNumberData.map((i, index) => {
                                    return (
                                      <tr
                                        key={i.Id}
                                        className={
                                          selectedOrder === i.Id
                                            ? "selected"
                                            : "orders_list"
                                        }
                                        onClick={() => {
                                          selectedOrder !== i.Id &&
                                            handleSelectedOrder(i.Id);
                                          setHasDifferentAddress(false);
                                          setNewShipMent(false);
                                          setShippngLabel("");
                                          setShowInvalidAddress(false);
                                        }}
                                      >
                                        <td width={"5%"} onClick={(e) => e.stopPropagation()}>
                                          <input
                                            type="checkbox"
                                            checked={i.isChecked}
                                            onChange={(e) =>
                                              handleOrderCheck(e, index)
                                            }
                                          />
                                        </td>
                                        <td>{i.OrderNumber} </td>
                                        <td className="custom-date" align="right">
                                          <i
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setShowDeleteShipDialog(true);
                                              setCurrentSelectedRecord(i);
                                              handleEditSender();
                                            }}
                                            className="fa fa-fw fa fa-close closeIcon custom-close-icn"
                                          ></i>
                                          {moment(i.UploadDate).format(
                                            "MM-DD-YYYY"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <>Loading...</>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 col-lg-6 custm-pd-ryt-lft">
                  <div className="formHeader">
                    <div className="leftSide">
                      <div className="referenceField">
                        <div className="heading-ref">
                          Shipper Reference (
                          {shipmentObject?.shippingreference?.length || 0}/70)
                        </div>
                        <input
                          value={shipmentObject.shippingreference}
                          onChange={(e) =>
                            updateUserInput(e, "shippingreference")
                          }
                          className={`rr1 form-control ${errorFields?.shippingreference ? "errorField" : ""
                            }`}
                          maxLength={70}
                        />
                      </div>
                    </div>
                    <div className="ship-date">
                      <div>Ship Date</div>
                      <div className="shipDateWithCalendar">
                        {shipmentObject.shipmentDate && (
                          <>
                            <DatePicker
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              showIcon
                              dateFormat="MM-dd-yyyy"
                              className={`rr1 form-control shipDate ${errorFields.shipDate && "errorField"
                                } date-input`}
                              selected={new Date(shipmentObject.shipmentDate)}
                              onChange={(date) => handleShipDate(date)}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="ship-date">
                      <div className="rr">
                        <div className="receiver">Order Number</div>
                        <input
                          className={`rr1 form-control ${errorFields.ordernumber && "errorField"
                            }`}
                          id="ordernumberInput"
                          type="text"
                          value={
                            shipmentObject.ordernumber
                              ? shipmentObject.ordernumber
                              : ""
                          }
                          disabled={shipmentObject.orderID > 0}
                          placeholder="e.g. order number"
                          onChange={(e) => updateUserInput(e, "ordernumber")}
                          onBlur={(e) => handleTrimValue(e, "ordernumber")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="details">
                    <div className="headers">
                      <div className="sender-header">
                        <div className="row">
                          <div className="col-sm-12 col-lg-8 custom-font-w col-shipment-checks">
                            Sender
                            {checkPermission(addressBook, addressModule, '', featureModule, employeePermissions) && (
                              <>
                                <div className="ml-8 alignRight">
                                  <WmsTooltip
                                    anchorId={"loadAddress"}
                                    content={"Load Saved Address"}
                                    place="top"
                                  />
                                  <button
                                    id="loadAddress"
                                    className="saveIconShipment"
                                    onClick={(e) =>
                                      handleLoadAddress(e, "sender")
                                    }
                                  >
                                    <i className="fa fa-address-card-o" />
                                  </button>

                                  <WmsTooltip
                                    anchorId={"saveFormSender"}
                                    place="top"
                                    content={"Save Address"}
                                  />
                                  <button
                                    id="saveFormSender"
                                    className="saveIconShipment marginLeft5"
                                    onClick={() => saveAddress("sender")}
                                  >
                                    <i className="fa fa-save" />
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                          {checkPermission(addressBook, addressModule, '', featureModule, employeePermissions) && (
                            <div className="col-4 alignRight senderResidential">
                              <input
                                type="checkbox"
                                name="senderResidential"
                                checked={shipmentObject?.senderResidential}
                                onChange={(e) => selectIsResidential(e)}
                              />{" "}
                              Residential
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        onClick={() => handleSwipe()}
                        className="arrow-swip"
                      >
                        <i className="fa fa-fw fa-arrows-h"></i>
                      </div>

                      <div className="receiver-header">
                        <div className="row">
                          <div className="col-sm-12 col-lg-8 custom-font-w col-shipment-checks">
                            Receiver
                            {checkPermission(addressBook, addressModule, '', featureModule, employeePermissions) && (
                              <>
                                <div className="alignRight ml-8">
                                  <WmsTooltip
                                    anchorId={"loadAddressReceiver"}
                                    place="left"
                                    content={"Load Saved Address"}
                                  />
                                  <button
                                    id="loadAddressReceiver"
                                    className="saveIconShipment"
                                    onClick={(e) =>
                                      handleLoadAddress(e, "receiver")
                                    }
                                  >
                                    <i className="fa fa-address-card-o" />
                                  </button>
                                  <WmsTooltip
                                    anchorId={"saveFormReciever"}
                                    place="left"
                                    content={"Save Address"}
                                  />
                                  <button
                                    id="saveFormReciever"
                                    className="saveIconShipment marginLeft5"
                                    onClick={() => saveAddress("receiver")}
                                  >
                                    <i className="fa fa-save" />
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                          {checkPermission(addressBook, addressModule, '', featureModule, employeePermissions) && (
                            <div className="col-4 alignRight senderResidential">
                              <input
                                type="checkbox"
                                name="receiverResidential"
                                checked={shipmentObject?.receiverResidential}
                                onChange={(e) => selectIsResidential(e)}
                              />{" "}
                              Residential
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mr-tp">
                      {/* {Sender form} */}
                      <div className="col-6">
                        {isSenderEdit && (
                          <div>
                            <div className="sender-details ship-form custom-inpt-labl">
                              <div className="input-group">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="label">Country <span className="require-field">*</span></div>
                                  </div>
                                  <div className="col-12 select">
                                    <select
                                      className={`form-select1`}
                                      onChange={(e) => {
                                        updateUserInput(e, "senderCountryID");
                                      }}
                                      value={shipmentObject.senderCountryID}
                                    >
                                      {countries?.map((country) => {
                                        return (
                                          <option
                                            selected={
                                              country.id ===
                                              shipmentObject.senderCountryID
                                            }
                                            value={country.id}
                                          >
                                            {country.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="input-group">
                                <div className="row">
                                  <div className="col-sm-12 col-lg-6">
                                    <div className="label">Name <span className="require-field">*</span></div>
                                    <input
                                      type="text"
                                      className={`form-control ${errorFields.fName && "errorField"
                                        }`}
                                      name={"fName"}
                                      onChange={(e) =>
                                        updateUserInput(e, "fName")
                                      }
                                      value={shipmentObject.fName || ""}
                                      onBlur={(e) =>
                                        handleTrimValue(e, "fName")
                                      }
                                    />
                                  </div>

                                  <div className="col-sm-12 col-lg-6">
                                    <div className="label">Company</div>
                                    <input
                                      type="text"
                                      className={`form-control`}
                                      name={"fCompany"}
                                      onChange={(e) =>
                                        updateUserInput(e, "fCompany")
                                      }
                                      value={shipmentObject.fCompany || ""}
                                      onBlur={(e) =>
                                        handleTrimValue(e, "fCompany")
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="input-group">
                                <div className="row">
                                  <div className="col-sm-12 col-lg-6">
                                    <div className="label">Email <span className="require-field">*</span></div>
                                    <input
                                      type="text"
                                      className={`form-control ${errorFields.fEmail && "errorField"
                                        }`}
                                      onChange={(e) =>
                                        updateUserInput(e, "fEmail")
                                      }
                                      name="fEmail"
                                      value={shipmentObject.fEmail || ""}
                                      onBlur={(e) =>
                                        handleTrimValue(e, "fEmail")
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-12 col-lg-6">
                                    <div className="label">Phone</div>
                                    <Input
                                      value={shipmentObject.fTel}
                                      name="fTel"
                                      onChange={(e) =>
                                        updateUserInput(
                                          { target: { value: e } },
                                          "fTel"
                                        )
                                      }
                                      className={`form-control ${errorFields.fTel && "errorField"
                                        }`}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="input-group">
                                <div className="row">
                                  <div className="col-sm-12 col-lg-6">
                                    <div className="label">Zip <span className="require-field">*</span></div>
                                    <input
                                      type="text"
                                      className={`form-control ${errorFields.fPostcode && "errorField"
                                        }`}
                                      onChange={(e) =>
                                        updateUserInput(e, "fPostcode")
                                      }
                                      value={shipmentObject.fPostcode || ""}
                                      onBlur={(e) => {
                                        handleTrimValue(e, "fPostcode");
                                        fetchAddress(
                                          e.target.value,
                                          "sender"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-sm-12 col-lg-6">
                                    <div className="label">State <span className="require-field">*</span></div>
                                    {parseInt(
                                      shipmentObject.senderCountryID
                                    ) === 218 ? (
                                      <select
                                        onChange={(e) => {
                                          updateUserInput(e, "fState");
                                        }}
                                        className={`form-control ${errorFields.fState && "errorField"
                                          }`}
                                        value={shipmentObject.fState}
                                      >
                                        <option value="">Select</option>
                                        {USSates &&
                                          USSates.length > 0 &&
                                          USSates.map((state, index) => (
                                            <option
                                              value={state.StateCode || ""}
                                              disabled={!state.StateName}
                                              selected={
                                                shipmentObject.fState ===
                                                state.StateCode
                                              }
                                            >
                                              {state.StateCode}{" "}
                                              {state.StateName}
                                            </option>
                                          ))}
                                      </select>
                                    ) : (
                                      <input
                                        type="text"
                                        className={`form-control ${errorFields.fState && "errorField"
                                          }`}
                                        onChange={(e) =>
                                          updateUserInput(e, "fState")
                                        }
                                        value={shipmentObject.fState || ""}
                                        name="fState"
                                        onBlur={(e) =>
                                          handleTrimValue(e, "fState")
                                        }
                                      />
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-lg-6 mr-tp-10">
                                    <div className="label">City <span className="require-field">*</span></div>
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        updateUserInput(e, "fCity")
                                      }
                                      value={shipmentObject.fCity || ""}
                                      onBlur={(e) =>
                                        handleTrimValue(e, "fCity")
                                      }
                                      className={`form-control ${errorFields.fCity && "errorField"
                                        }`}
                                    />
                                  </div>
                                  <div className="col-sm-12 col-lg-6 mr-tp-10">
                                    <div className="label">Address 1 <span className="require-field">*</span></div>
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        updateUserInput(e, "fAdd1")
                                      }
                                      value={shipmentObject.fAdd1 || ""}
                                      onBlur={(e) =>
                                        handleTrimValue(e, "fAdd1")
                                      }
                                      className={`form-control ${errorFields.fAdd1 && "errorField"
                                        }`}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="input-group">
                                <div className="row">
                                  <div className="col-sm-12 col-lg-6 mr-tp-10">
                                    <div className="label">Address 2</div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        updateUserInput(e, "fAdd2")
                                      }
                                      value={shipmentObject.fAdd2 || ""}
                                      onBlur={(e) =>
                                        handleTrimValue(e, "fAdd2")
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-12 col-lg-6 mr-tp-10">
                                    <div className="label">Address 3</div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        updateUserInput(e, "fAdd3")
                                      }
                                      value={shipmentObject.fAdd3 || ""}
                                      onBlur={(e) =>
                                        handleTrimValue(e, "fAdd3")
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {!newShipment && !isSenderEdit && !saveReciever && (
                          <div className="sender-details address">
                            <div>{employeeAddress.name}</div>
                            <div>{employeeAddress.address1}</div>
                            {employeeAddress.address2 && (
                              <div>{employeeAddress.address2}</div>
                            )}
                            {employeeAddress.city && (
                              <div>{employeeAddress.city}</div>
                            )}
                            {employeeAddress.state && (
                              <div>{employeeAddress.state}</div>
                            )}
                            {employeeAddress.zip && (
                              <div>{employeeAddress.zip}</div>
                            )}
                          </div>
                        )}

                        {newShipment && !isSenderEdit && (
                          <div className="sender-details address">
                            <div>{employeeAddress.name}</div>
                            <div>{employeeAddress.address1}</div>
                            {employeeAddress.address2 && (
                              <div>{employeeAddress.address2}</div>
                            )}
                            {employeeAddress.city && (
                              <div>{employeeAddress.city}</div>
                            )}
                            {employeeAddress.state && (
                              <div>{employeeAddress.state}</div>
                            )}
                            {employeeAddress.zip && (
                              <div>{employeeAddress.zip}</div>
                            )}
                          </div>
                        )}

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            id="differentAddress"
                            type="checkbox"
                            onChange={(e) =>
                              handleReturnAddressChecked(
                                e,
                                "isDifferentAddress",
                                "isReturnAddress"
                              )
                            }
                            checked={
                              shipmentObject.isDifferentAddress ||
                              shipmentObject.isReturnAddress
                            }
                          />
                          <label
                            className="form-check-label"
                            for="differentAddress"
                          >
                            Have a different return address
                          </label>
                        </div>

                        {/* Return Address */}
                        {(shipmentObject.isDifferentAddress ||
                          shipmentObject.isReturnAddress) && (
                            <div>
                              <div className="sender-details ship-form">
                                <div className="input-group">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="label">Country*</div>
                                    </div>
                                    <div className="col-12 select">
                                      <select
                                        className={`form-select1`}
                                        onChange={(e) => {
                                          updateUserInput(e, "returnCountryId");
                                        }}
                                        value={shipmentObject.returnCountryId}
                                      >
                                        {countries.map((country) => {
                                          return (
                                            <option
                                              key={country.id}
                                              value={country.id}
                                              selected={country.iSO === shipmentObject.returncountrycode}
                                            >
                                              {country.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="input-group">
                                  <div className="row">
                                    <div className="col-sm-12 col-lg-6">
                                      <div>Name*</div>
                                      <input
                                        type="text"
                                        className={`form-control ${errorFields.returnname && "errorField"
                                          }`}
                                        name={"returnname"}
                                        onChange={(e) =>
                                          updateUserInput(e, "returnname")
                                        }
                                        value={shipmentObject.returnname}
                                        onBlur={(e) =>
                                          handleTrimValue(e, "returnname")
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                      <div>Company</div>
                                      <div>
                                        <input
                                          type="text"
                                          className={`form-control`}
                                          name={"sCompany"}
                                          onChange={(e) =>
                                            updateUserInput(e, "returncompany")
                                          }
                                          value={shipmentObject.returncompany}
                                          onBlur={(e) =>
                                            handleTrimValue(e, "returncompany")
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="input-group">
                                  <div className="row">
                                    <div className="col-sm-12 col-lg-6">
                                      <div>Email*</div>
                                      <input
                                        type="text"
                                        className={`form-control ${errorFields.returnemail &&
                                          "errorField"
                                          }`}
                                        onChange={(e) =>
                                          updateUserInput(e, "returnemail")
                                        }
                                        name="returnemail"
                                        value={shipmentObject.returnemail}
                                        onBlur={(e) =>
                                          handleTrimValue(e, "returnemail")
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                      <div>Phone</div>
                                      <Input
                                        value={shipmentObject.returnphone}
                                        name="returnphone"
                                        onChange={(e) =>
                                          updateUserInput(
                                            { target: { value: e } },
                                            "returnphone"
                                          )
                                        }
                                        className={`form-control ${errorFields.returnphone &&
                                          "errorField"
                                          }`}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="input-group">
                                  <div className="row">
                                    <div className="col-sm-12 col-lg-6">
                                      <div>Zip*</div>
                                      <input
                                        type="text"
                                        className={`form-control ${errorFields.returnzip && "errorField"
                                          }`}
                                        onChange={(e) =>
                                          updateUserInput(e, "returnzip")
                                        }
                                        value={shipmentObject.returnzip}
                                        onBlur={(e) => {
                                          handleTrimValue(e, "returnzip");
                                          fetchAddress(
                                            e.target.value,
                                            "returnAddress"
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                      <div>State*</div>
                                      {parseInt(
                                        shipmentObject.returnCountryId
                                      ) === 218 ? (
                                        <select
                                          className={`form-control ${errorFields.returnstate &&
                                            "errorField"
                                            }`}
                                          onChange={(e) => {
                                            updateUserInput(e, "returnstate");
                                          }}
                                          value={shipmentObject.returnstate}
                                        >
                                          <option value="">Select</option>
                                          {USSates &&
                                            USSates.length > 0 &&
                                            USSates.map((state, index) => (
                                              <option
                                                value={state.StateCode}
                                                selected={
                                                  shipmentObject.returnstate ===
                                                  state.StateCode
                                                }
                                                disabled={!state.StateName}
                                              >
                                                {state.StateCode}{" "}
                                                {state.StateName}
                                              </option>
                                            ))}
                                        </select>
                                      ) : (
                                        <input
                                          type="text"
                                          className={`form-control ${errorFields.returnstate &&
                                            "errorField"
                                            }`}
                                          onChange={(e) =>
                                            updateUserInput(e, "returnstate")
                                          }
                                          value={shipmentObject.returnstate}
                                          name="returnstate"
                                          onBlur={(e) =>
                                            handleTrimValue(e, "returnstate")
                                          }
                                        />
                                      )}
                                    </div>
                                    <div className="col-sm-12 col-lg-6 mr-tp-10">
                                      <div>City*</div>
                                      <input
                                        type="text"
                                        className={`form-control ${errorFields.returncity && "errorField"
                                          }`}
                                        onChange={(e) =>
                                          updateUserInput(e, "returncity")
                                        }
                                        value={shipmentObject.returncity}
                                        onBlur={(e) =>
                                          handleTrimValue(e, "returncity")
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-12 col-lg-6 mr-tp-10">
                                      <div>Address 1*</div>
                                      <input
                                        className={`form-control ${errorFields.returnaddressline1 &&
                                          "errorField"
                                          }`}
                                        onChange={(e) =>
                                          updateUserInput(
                                            e,
                                            "returnaddressline1"
                                          )
                                        }
                                        value={
                                          shipmentObject.returnaddressline1
                                        }
                                        onBlur={(e) =>
                                          handleTrimValue(
                                            e,
                                            "returnaddressline1"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="input-group">
                                  <div className="row">
                                    <div className="col-sm-12 col-lg-6 mr-tp-10">
                                      <div>Address 2</div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                          updateUserInput(
                                            e,
                                            "returnaddressline2"
                                          )
                                        }
                                        value={
                                          shipmentObject.returnaddressline2
                                        }
                                        onBlur={(e) =>
                                          handleTrimValue(
                                            e,
                                            "returnaddressline2"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-12 col-lg-6 mr-tp-10">
                                      <div>Address 3</div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                          updateUserInput(
                                            e,
                                            "returnaddressline3"
                                          )
                                        }
                                        value={
                                          shipmentObject.returnaddressline3
                                        }
                                        onBlur={(e) =>
                                          handleTrimValue(
                                            e,
                                            "returnaddressline3"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>

                      {/* {Reciver form} */}
                      <div className="col-6">
                        <div>
                          <div className="sender-details ship-form custom-inpt-labl">
                            <div className="input-group">
                              <div className="row">
                                <div className="col-12">
                                  <div className="label">Country <span className="require-field">*</span></div>
                                </div>
                                <div className="col-12 select">
                                  <select
                                    className={`form-select1`}
                                    onChange={(e) => {
                                      updateUserInput(e, "receiverCountryID");
                                    }}
                                    value={shipmentObject.receiverCountryID}
                                  >
                                    {countries?.map((country) => {
                                      return (
                                        <option
                                          selected={
                                            country.id ===
                                            shipmentObject.receiverCountryID
                                          }
                                          value={country.id}
                                        >
                                          {country.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="input-group">
                              <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                  <div className="label">Name <span className="require-field">*</span></div>
                                  <input
                                    type="text"
                                    className={`form-control ${errorFields.sName && "errorField"
                                      }`}
                                    name={"sName"}
                                    onChange={(e) =>
                                      updateUserInput(e, "sName")
                                    }
                                    value={shipmentObject.sName || ""}
                                    onBlur={(e) =>
                                      handleTrimValue(e, "sName")
                                    }
                                  />
                                </div>

                                <div className="col-sm-12 col-lg-6">
                                  <div className="label">Company</div>
                                  <input
                                    type="text"
                                    className={`form-control sCompany`}
                                    name={"sCompany"}
                                    onChange={(e) =>
                                      updateUserInput(e, "sCompany")
                                    }
                                    value={shipmentObject.sCompany || ""}
                                    onBlur={(e) =>
                                      handleTrimValue(e, "sCompany")
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="input-group">
                              <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                  <div className="label">Email <span className="require-field">*</span></div>
                                  <input
                                    type="text"
                                    className={`form-control ${errorFields.sEmail && "errorField"
                                      }`}
                                    onChange={(e) =>
                                      updateUserInput(e, "sEmail")
                                    }
                                    name="sEmail"
                                    value={shipmentObject.sEmail || ""}
                                    onBlur={(e) =>
                                      handleTrimValue(e, "sEmail")
                                    }
                                  />
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                  <div className="label">Phone</div>
                                  <Input
                                    value={shipmentObject.sTel}
                                    name="sTel"
                                    onChange={(e) =>
                                      updateUserInput(
                                        { target: { value: e } },
                                        "sTel"
                                      )
                                    }
                                    className={`form-control ${errorFields.sTel && "errorField"
                                      }`}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="input-group">
                              <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                  <div className="label">Zip <span className="require-field">*</span></div>
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      updateUserInput(e, "sPostcode")
                                    }
                                    value={shipmentObject.sPostcode || ""}
                                    onBlur={(e) => {
                                      handleTrimValue(e, "sPostcode");
                                      fetchAddress(
                                        e.target.value,
                                        "receiver"
                                      );
                                    }}
                                    className={`form-control ${errorFields.sPostcode && "errorField"
                                      }`}
                                  />
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                  <div className="label">State <span className="require-field">*</span></div>
                                  {parseInt(
                                    shipmentObject.receiverCountryID
                                  ) === 218 ? (
                                    <select
                                      onChange={(e) => {
                                        updateUserInput(e, "sState");
                                      }}
                                      className={`form-control ${errorFields.sState && "errorField"
                                        }`}
                                      value={shipmentObject.sState}
                                    >
                                      <option value="">Select</option>
                                      {USSates &&
                                        USSates.length > 0 &&
                                        USSates.map((state, index) => (
                                          <option
                                            key={index}
                                            disabled={!state.StateName}
                                            value={state.StateCode || ""}
                                            selected={
                                              shipmentObject.sState ===
                                              state.StateCode
                                            }
                                          >
                                            {state.StateCode}{" "}
                                            {state.StateName}
                                          </option>
                                        ))}
                                    </select>
                                  ) : (
                                    <input
                                      type="text"
                                      className={`form-control ${errorFields.sState && "errorField"
                                        }`}
                                      onChange={(e) =>
                                        updateUserInput(e, "sState")
                                      }
                                      value={shipmentObject.sState || ""}
                                      name="sState"
                                      onBlur={(e) =>
                                        handleTrimValue(e, "sState")
                                      }
                                    />
                                  )}
                                </div>
                                <div className="col-sm-12 col-lg-6 mr-tp-10">
                                  <div className="label">City <span className="require-field">*</span></div>
                                  <input
                                    type="text"
                                    className={`form-control ${errorFields.sCity && "errorField"
                                      }`}
                                    onChange={(e) =>
                                      updateUserInput(e, "sCity")
                                    }
                                    value={shipmentObject.sCity || ""}
                                    onBlur={(e) =>
                                      handleTrimValue(e, "sCity")
                                    }
                                  />
                                </div>
                                <div className="col-sm-12 col-lg-6 mr-tp-10">
                                  <div className="label">Address 1 <span className="require-field">*</span></div>
                                  <input
                                    type="text"
                                    className={`form-control ${errorFields.sAdd1 && "errorField"
                                      }`}
                                    onChange={(e) =>
                                      updateUserInput(e, "sAdd1")
                                    }
                                    value={shipmentObject.sAdd1 || ""}
                                    onBlur={(e) =>
                                      handleTrimValue(e, "sAdd1")
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="input-group">
                              <div className="row">
                                <div className="col-sm-12 col-lg-6 mr-tp-10">
                                  <div className="label">Address 2</div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      updateUserInput(e, "sAdd2")
                                    }
                                    value={shipmentObject.sAdd2 || ""}
                                    onBlur={(e) =>
                                      handleTrimValue(e, "sAdd2")
                                    }
                                  />
                                </div>
                                <div className="col-sm-12 col-lg-6 mr-tp-10">
                                  <div className="label">Address 3</div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      updateUserInput(e, "sAdd3")
                                    }
                                    value={shipmentObject.sAdd3 || ""}
                                    onBlur={(e) =>
                                      handleTrimValue(e, "sAdd3")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row serviceSelectWrapper">
                      <div className="tabWrapper col-12">
                        <div className="row">
                          <div className="col-42">
                            <Button
                              onClick={() => {
                                setServiceTab(1);
                              }}
                              className={
                                serviceTab === 1
                                  ? "cus-seconday-bg-btn marginLeft5 tabs active"
                                  : "cus-seconday-bg-btn marginLeft5 tabs"
                              }
                              id="savesr"
                            >
                              <div className="selectHeader">
                                <div className="serviceHead">
                                  {serviceTab === 1 ||
                                    !shipmentObject.serviceName
                                    ? "Service"
                                    : shipmentObject.serviceName}
                                </div>
                                {(serviceTab === 1 ||
                                  !shipmentObject.serviceName) && (
                                    <div className="helpingText">
                                      Choose any one
                                    </div>
                                  )}
                              </div>
                              <i
                                className="fa fa-chevron-down"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </div>
                          <div className="col-40">
                            <button
                              disabled={!shipmentObject.serviceID}
                              onClick={() => setServiceTab(2)}
                              className={
                                serviceTab === 2
                                  ? "btn cus-seconday-bg-btn marginLeft5 tabs active"
                                  : "btn cus-seconday-bg-btn marginLeft5 tabs"
                              }
                              id="savesr"
                            >
                              <div className="selectHeader">
                                <div className="serviceHead">
                                  {serviceTab === 2 ||
                                    !shipmentObject.packageName
                                    ? "Package"
                                    : shipmentObject.packageName}
                                </div>
                                {(serviceTab === 2 ||
                                  !shipmentObject.packageName) && (
                                    <div className="helpingText">
                                      Choose any one
                                    </div>
                                  )}
                              </div>
                              <i
                                className="fa fa-chevron-down"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                          <div className="col-41">
                            <button
                              disabled={!shipmentObject.packageID}
                              onClick={() => setServiceTab(3)}
                              className={
                                serviceTab === 3
                                  ? "btn cus-seconday-bg-btn marginLeft5 tabs last active"
                                  : "btn cus-seconday-bg-btn marginLeft5 last tabs"
                              }
                              id="savesr"
                            // onClick={handleSave}
                            >
                              <div className="selectHeader">
                                <div className="serviceHead">Details</div>
                                <div className="helpingText">
                                  Enter details
                                </div>
                              </div>
                              <i
                                className="fa fa-chevron-down"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="carrierWrapper col-12">
                        {serviceTab === 1 ? (
                          <div className="service-details services row">
                            {services &&
                              services.map((carrier) => (
                                <div className="col-4" key={carrier.id}>
                                  <div className="serviceLogo">
                                    {carrier.carrierCompanyName === "3PL" ? (
                                      <img
                                        alt=""
                                        className="cus-main-logo-img"
                                        src={MainLogo}
                                      />
                                    ) : carrier.carrierCompanyName ===
                                      "USPS" ? (
                                      <img
                                        src={uspsLogo}
                                        className="usps-logo"
                                        alt=""
                                      />
                                    ) : carrier.carrierCompanyName ===
                                      "FedEx" ? (
                                      <img
                                        src={FedExLogo}
                                        className="fedexLogo"
                                        style={{
                                          maxHeight: "50px",
                                          maxWidth: "96px",
                                        }}
                                        alt=""
                                      />
                                    ) : carrier.carrierCompanyName === "3PLPREMIUM" ? (
                                      <img
                                        alt="Premium logo"
                                        className="premiumLogo cus-main-logo-img"
                                        src={PremiumLogo}
                                      />
                                    ) : (
                                      <img
                                        alt="Premium logo"
                                        className="premiumLogo cus-main-logo-img"
                                        src={findIsHostShipAi() ? uspsLogo : MainLogoAi}
                                      />
                                    )}
                                  </div>
                                  {carrier.services &&
                                    carrier.services.map((service) => (
                                      <div
                                        className="service-name"
                                        key={service.id}
                                      >
                                        {(shipmentObject.sState === "AA" ||
                                          shipmentObject.sState === "AE" ||
                                          shipmentObject.sState === "AP") &&
                                          carrier.carrierCompanyName ===
                                          "3PLPREMIUM" ? (
                                          <>
                                            {(service.serviceName ===
                                              "3PLPremium Priority" ||
                                              service.serviceName ===
                                              "3PLPremium Parcel Select") && (
                                                <div
                                                  className={
                                                    service.id ==
                                                      selectedService
                                                      ? "selected-txt-name"
                                                      : "txt-name"
                                                  }
                                                  onClick={() => {
                                                    selectServicePackage(
                                                      service.id,
                                                      "serviceID"
                                                    );
                                                    selectServiceAndPackageId(
                                                      "serviceID",
                                                      service.id,
                                                      service.serviceName,
                                                      carrier.carrierCompanyId,
                                                      carrier.carrierCompanyName
                                                    );
                                                    handleArrivalDate(
                                                      service.id
                                                    );
                                                    setServiceTab(2);
                                                  }}
                                                >
                                                  {service.serviceName}
                                                </div>
                                              )}
                                          </>
                                        ) : (
                                          <div
                                            className={
                                              service.id == selectedService
                                                ? "selected-txt-name"
                                                : "txt-name"
                                            }
                                            onClick={() => {
                                              selectServicePackage(
                                                service.id,
                                                "serviceID"
                                              );
                                              selectServiceAndPackageId(
                                                "serviceID",
                                                service.id,
                                                service.serviceName,
                                                carrier.carrierCompanyId,
                                                carrier.carrierCompanyName
                                              );
                                              handleArrivalDate(service.id);
                                              setServiceTab(2);
                                            }}
                                            key={service.id}
                                          >
                                            {service.serviceName}
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                </div>
                              ))}
                          </div>
                        ) : serviceTab === 2 ? (
                          <div className="service-details package row">
                            {packages.map((p) => {
                              return (
                                <div
                                  className={
                                    p.id === selectedPackage
                                      ? "col-4 selected-txt-name"
                                      : "col-4 txt-name packages"
                                  }
                                  onClick={() => {
                                    setIsPackageSelected(p);
                                    selectServicePackage(p.id, "packageID");
                                    selectServiceAndPackageId(
                                      "packageID",
                                      p.id,
                                      p.packageName
                                    );
                                    setServiceTab(3);
                                  }}
                                  key={p.id}
                                >
                                  {p.packageName}
                                </div>
                              );
                            })}
                          </div>
                        ) : serviceTab === 3 ? (
                          <>
                            {shipmentObject.packageID > 0 &&
                              shipmentObject.serviceID > 0 && (
                                <div className="mr-tp">
                                  <span className="txt-unit">Units</span>
                                  <div className="unitSelection shipment-cms">
                                    <select
                                      className="unitDropdown form-control"
                                      onChange={(e) =>
                                        updateUserInput(e, "dimensionUnit")
                                      }
                                      value={shipmentObject.dimensionUnit}
                                    >
                                      {unitList.length > 0 &&
                                        unitList.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item.value}
                                          >
                                            {item.name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  {shipmentObject.parcels &&
                                    shipmentObject.parcels.length > 0 &&
                                    shipmentObject.parcels.map(
                                      (item, index) => (
                                        <div
                                          className="row mr-tp units-details"
                                          key={index}
                                        >
                                          <div className="col-sm-12 col-lg-2">
                                            <div>Length</div>
                                            <input
                                              type="text"
                                              maxLength={10}
                                              className={`form-control parcel ${!item.length && "errorField"
                                                }`}
                                              onChange={(e) =>
                                                handleParcelChange(
                                                  e,
                                                  "length",
                                                  index
                                                )
                                              }
                                              disabled={
                                                isPackageSelected?.shouldAutofill
                                              }
                                              value={item.length}
                                            />
                                            <span className="txt-label">
                                              {shipmentObject.dimensionUnit ===
                                                "INCH"
                                                ? "in"
                                                : "cm"}
                                            </span>
                                          </div>
                                          <div className="col-sm-12 col-lg-2">
                                            <div>Width</div>
                                            <input
                                              type="text"
                                              maxLength={10}
                                              className={`form-control ${!item.width && "errorField"
                                                }`}
                                              disabled={
                                                isPackageSelected?.shouldAutofill
                                              }
                                              onChange={(e) =>
                                                handleParcelChange(
                                                  e,
                                                  "width",
                                                  index
                                                )
                                              }
                                              value={item.width}
                                            />
                                            <span className="txt-label">
                                              {shipmentObject.dimensionUnit ===
                                                "INCH"
                                                ? "in"
                                                : "cm"}
                                            </span>
                                          </div>
                                          <div className="col-sm-12 col-lg-2">
                                            <div>Height</div>
                                            <input
                                              type="text"
                                              className={`form-control ${!item.height && "errorField"
                                                }`}
                                              disabled={
                                                isPackageSelected?.shouldAutofill
                                              }
                                              maxLength={10}
                                              onChange={(e) =>
                                                handleParcelChange(
                                                  e,
                                                  "height",
                                                  index
                                                )
                                              }
                                              value={item.height}
                                            />
                                            <span className="txt-label">
                                              {shipmentObject.dimensionUnit ===
                                                "INCH"
                                                ? "in"
                                                : "cm"}
                                            </span>
                                          </div>
                                          <div className="col-sm-12 col-lg-2">
                                            <div>Weight</div>
                                            <input
                                              type="text"
                                              className={`form-control ${item.weight || item.ounces
                                                ? ""
                                                : "errorField"
                                                }`}
                                              maxLength={10}
                                              onChange={(e) =>
                                                handleParcelChange(
                                                  e,
                                                  "weight",
                                                  index
                                                )
                                              }
                                              value={item.weight}
                                            />
                                            <span className="txt-label">
                                              {shipmentObject.dimensionUnit ===
                                                "INCH"
                                                ? "lb"
                                                : "kg"}
                                            </span>
                                          </div>
                                          <div className="col-sm-12 col-lg-2">
                                            <div>
                                              {shipmentObject.dimensionUnit ===
                                                "INCH"
                                                ? "Ounces"
                                                : "gm"}
                                            </div>
                                            <input
                                              type="text"
                                              maxLength={10}
                                              className={`form-control ${item.weight || item.ounces
                                                ? ""
                                                : "errorField"
                                                }`}
                                              onChange={(e) =>
                                                handleParcelChange(
                                                  e,
                                                  "ounces",
                                                  index
                                                )
                                              }
                                              value={item.ounces}
                                            />
                                            <span className="txt-label">
                                              {shipmentObject.dimensionUnit ===
                                                "INCH"
                                                ? "oz"
                                                : "g"}
                                            </span>
                                          </div>

                                          <div className="col-sm-12 col-lg-2">
                                            {index + 1 ===
                                              shipmentObject.parcels.length &&
                                              shipmentObject.parcels.length <=
                                              4 && (
                                                <button
                                                  className="addplusicon shipment"
                                                  onClick={addParcel}
                                                >
                                                  <img alt="" src={pic9} />
                                                </button>
                                              )}

                                            {index > 0 && (
                                              <button
                                                className="addplusicon shipment removeParcel"
                                                onClick={(e) =>
                                                  removeParcel(index)
                                                }
                                              >
                                                <img alt="" src={pic10} />
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  <div className="row units-details mr-tp">
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="short-txt">
                                        Content Description
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                          updateUserInput(
                                            e,
                                            "contentDescription",
                                            0
                                          )
                                        }
                                        value={
                                          shipmentObject.contentDescription
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="short-txt">
                                        Dangerous Goods
                                      </div>
                                      <select
                                        value={shipmentObject.dangerousGoods}
                                        className="form-selectship sign-select form-control"
                                        onChange={(e) =>
                                          updateUserInput(e, "dangerousGoods")
                                        }
                                      >
                                        {dangerousGoods.length > 0 &&
                                          dangerousGoods.map(
                                            (item, index) => (
                                              <option
                                                selected={
                                                  item.value ===
                                                  parseInt(
                                                    shipmentObject.dangerousGoods
                                                  )
                                                }
                                                key={index}
                                                value={item.value}
                                              >
                                                {item.name}
                                              </option>
                                            )
                                          )}
                                      </select>
                                    </div>
                                  </div>
                                  {checkPermission(orderManagement, shipmentModule, permissionPrintLabel, featureSubmodulePermission, employeePermissions) && (
                                    <div className="row mt-3 mb-3">
                                      <div className="col-sm-12 col-12 printLabelCost">
                                        <input
                                          type="checkbox"
                                          checked={
                                            shipmentObject?.printLabelCost
                                          }
                                          className="mr-1"
                                          name="printLabelCost"
                                          onChange={(e) =>
                                            handleSendNotification(e, "printLabelCost")
                                          }
                                          id="printLabelCost"
                                        />
                                        <label for="printLabelCost" className="">Print Cost</label>
                                      </div>
                                    </div>
                                  )}
                                  <div className="row">
                                    {(parseInt(
                                      shipmentObject.receiverCountryID
                                    ) !== 218 ||
                                      shipmentObject.sState === "AA" ||
                                      shipmentObject.sState === "AE" ||
                                      shipmentObject.sState === "AP") && (
                                        <>
                                          <div className="col-12">
                                            <div className="short-txt">
                                              Content Type
                                            </div>
                                            <select
                                              onChange={(e) =>
                                                updateUserInput(
                                                  e,
                                                  "contentType"
                                                )
                                              }
                                              value={shipmentObject.contentType}
                                              className={
                                                errorFields.contentType
                                                  ? "form-selectship sign-select form-control errorField"
                                                  : "form-selectship sign-select form-control"
                                              }
                                            >
                                              <option value={""}>
                                                Choose one...
                                              </option>
                                              {contentTypeList?.length > 0 &&
                                                contentTypeList.map(
                                                  (item, index) => (
                                                    <option
                                                      key={item.id}
                                                      value={item.contentName}
                                                    >
                                                      {item.contentName}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                          </div>
                                          {shipmentObject.contentType ===
                                            "Other" && (
                                              <div className="col-4">
                                                <div className="short-txt">
                                                  Content Explanation
                                                </div>
                                                <input
                                                  type="text"
                                                  onChange={(e) =>
                                                    updateUserInput(
                                                      e,
                                                      "contentsExplanation"
                                                    )
                                                  }
                                                  className={
                                                    errorFields.contentsExplanation
                                                      ? "form-control errorField"
                                                      : "form-control"
                                                  }
                                                  value={
                                                    shipmentObject?.contentsExplanation
                                                  }
                                                />
                                              </div>
                                            )}
                                        </>
                                      )}
                                  </div>
                                  <div className="row units-details">
                                    {shipmentObject.carrierCompanyName ===
                                      "FedEx" && (
                                        <div className="col-12">
                                          <div className="fedexForm commercialInvoice">
                                            <div className="sendNotfication head">
                                              <input
                                                type="checkbox"
                                                name="sendFedExNotification"
                                                onChange={
                                                  handleFedExNotification
                                                }
                                                checked={
                                                  shipmentObject.sendFedExNotification
                                                }
                                              />{" "}
                                              Send FedEx Notification
                                            </div>
                                            {shipmentObject.sendFedExNotification && (
                                              <div className="fedexOption">
                                                <div className="option">
                                                  <input
                                                    name="onDelivery"
                                                    type="checkbox"
                                                    onChange={
                                                      handleFedExNotification
                                                    }
                                                    checked={
                                                      shipmentObject.onDelivery
                                                    }
                                                  />{" "}
                                                  On Delivery
                                                </div>
                                                <div className="option">
                                                  <input
                                                    name="onException"
                                                    type="checkbox"
                                                    onChange={
                                                      handleFedExNotification
                                                    }
                                                    checked={
                                                      shipmentObject.onException
                                                    }
                                                  />{" "}
                                                  On Exception
                                                </div>
                                                <div className="option">
                                                  <input
                                                    name="onShipment"
                                                    type="checkbox"
                                                    onChange={
                                                      handleFedExNotification
                                                    }
                                                    checked={
                                                      shipmentObject.onShipment
                                                    }
                                                  />{" "}
                                                  On Shipment
                                                </div>
                                                <div className="option">
                                                  <input
                                                    name="onTender"
                                                    type="checkbox"
                                                    onChange={
                                                      handleFedExNotification
                                                    }
                                                    checked={
                                                      shipmentObject.onTender
                                                    }
                                                  />{" "}
                                                  On Tender
                                                </div>
                                                <div className="option">
                                                  <input
                                                    name="onEstimatedDelivery"
                                                    type="checkbox"
                                                    onChange={
                                                      handleFedExNotification
                                                    }
                                                    checked={
                                                      shipmentObject.onEstimatedDelivery
                                                    }
                                                  />{" "}
                                                  On Estimated Delivery
                                                </div>
                                                {errorFields.sendFedexNotification && (
                                                  <p
                                                    className={
                                                      errorFields.sendFedexNotification
                                                        ? "errorField"
                                                        : ""
                                                    }
                                                  >
                                                    {
                                                      "Select atleast one option"
                                                    }
                                                  </p>
                                                )}
                                                <div className="emailBlock">
                                                  <div className="short-txt">
                                                    Emails
                                                  </div>
                                                  <div>
                                                    <input
                                                      value={
                                                        shipmentObject.emails
                                                      }
                                                      onChange={(e) =>
                                                        updateUserInput(
                                                          e,
                                                          "emails"
                                                        )
                                                      }
                                                      className={`form-control ${errorFields.emailValid &&
                                                        "errorField"
                                                        }`}
                                                      type={"text"}
                                                      placeholder="Enter email id"
                                                    />
                                                  </div>
                                                </div>

                                                <div className="emailBody">
                                                  <div className="short-txt">Message</div>
                                                  <textarea
                                                    className="form-control"
                                                    onChange={(e) =>
                                                      updateUserInput(
                                                        e,
                                                        "emailDescription"
                                                      )
                                                    }
                                                    value={
                                                      shipmentObject.emailDescription
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    {shipmentObject.carrierCompanyName !==
                                      "FedEx" && (
                                        <div className="col-12 send-notification1 mb-1">
                                          {shipmentObject.packageID > 0 &&
                                            shipmentObject.serviceID > 0 && (
                                              <div className="commercialInvoice">
                                                <div className="head">
                                                  <input
                                                    type="checkbox"
                                                    id="sendNotification"
                                                    className="form-check-notification"
                                                    onChange={(e) =>
                                                      handleSendNotification(
                                                        e,
                                                        "sendNotification"
                                                      )
                                                    }
                                                    checked={
                                                      shipmentObject.sendNotification
                                                    }
                                                  />{" "}
                                                  Send Notification Email
                                                </div>
                                              </div>
                                            )}
                                          <div className="col-12">
                                            <div className="row">
                                              <div className="col-12">
                                                {!!Number(
                                                  shipmentObject.sendNotification
                                                ) && (
                                                    <div>
                                                      <div>
                                                        {editShipmentDetails.email && (
                                                          <>
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-notification"
                                                              value=""
                                                              id="sendNotificationToSender"
                                                              onChange={(e) =>
                                                                selectServiceAndPackageId(
                                                                  "sendNotificationToSender",
                                                                  e.target.checked
                                                                )
                                                              }
                                                              checked={
                                                                shipmentObject.sendNotificationToSender
                                                              }
                                                            />
                                                            Send notification
                                                            email to
                                                            {
                                                              editShipmentDetails.email
                                                            }
                                                          </>
                                                        )}
                                                      </div>

                                                      <div className="emailBlock">
                                                        <div className="short-txt">
                                                          Emails
                                                        </div>
                                                        <div>
                                                          <input
                                                            value={
                                                              shipmentObject.emails
                                                            }
                                                            onBlur={(e) =>
                                                              validateEmails(e)
                                                            }
                                                            onChange={(e) =>
                                                              updateUserInput(
                                                                e,
                                                                "emails"
                                                              )
                                                            }
                                                            className="form-control"
                                                            type={"text"}
                                                            placeholder="Comma separated emails"
                                                          />

                                                          {shipmentObject.emailsValid
                                                            ? ""
                                                            : "Emails not valid"}
                                                        </div>
                                                      </div>
                                                      <div className="emailBody">
                                                        <div className="short-txt">Message</div>
                                                        <textarea
                                                          className="form-control"
                                                          onChange={(e) =>
                                                            updateUserInput(
                                                              e,
                                                              "emailDescription"
                                                            )
                                                          }
                                                          value={
                                                            shipmentObject.emailDescription
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                  {(parseInt(
                                    shipmentObject.receiverCountryID
                                  ) !== 218 ||
                                    shipmentObject.sState === "AA" ||
                                    shipmentObject.sState === "AE" ||
                                    shipmentObject.sState === "AP") && (
                                      <CommercialInvoice
                                        shipmentObject={shipmentObject}
                                        countries={countries}
                                        setShipmentObject={setShipmentObject}
                                      />
                                    )}
                                </div>
                              )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="save-load">
                    <div className="btn-container">
                      <div className="orderAction">
                        {shipmentObject.orderID > 0 ? (
                          <button
                            className="btn-save"
                            onClick={(e) => saveOrderChanges(e)}
                            disabled={saveOrderLoader}
                          >
                            Save Changes
                            {saveOrderLoader && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        ) : (
                          <div className="createShipActions">
                            {checkPermission(orderManagement, shipmentModule, permssionEdit, featureSubmodulePermission, employeePermissions) && (
                              <button
                                className="cus-primary-bg-btn custom-btn-shipment"
                                onClick={() => toggleShipmentSave()}
                              >
                                Save Form
                              </button>
                            )}
                            {checkPermission(orderManagement, shipmentModule, permssionView, featureSubmodulePermission, employeePermissions) && (
                              <button
                                className="cus-primary-bg-btn custom-btn-shipment marginLeft5"
                                onClick={toggleLoadShipment}
                              >
                                Load Form
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-xl-3 col-lg-3 pd-lt errorShipSection">
                  <div
                    className={`details btn-ship ship-button ${validationsList.length === 0 &&
                      thresholdValidation.length === 0 &&
                      Number(zipZoneRates) &&
                      !loader &&
                      !shippngLabel?.shippingLabel &&
                      !shipmentObject.isQueued
                      ? "validated"
                      : "notValidated"
                      }`}
                    disabled={
                      loader ||
                        (fundDetails && fundDetails[0]?.availableAmount <= 0)
                        ? true
                        : false
                    }
                    onClick={
                      validationsList.length === 0 &&
                        thresholdValidation.length === 0
                        ? saveShipment
                        : ""
                    }
                  >
                    SHIP
                  </div>
                  {thresholdValidation.length > 0 && (
                    <div className="validationBox ">
                      <ul>
                        {thresholdValidation.length > 0 &&
                          thresholdValidation.map((error, index) => (
                            <li className="thresholderrors" key={index}>
                              {error.errorName}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                  {getZipZoneStatus &&
                    validationsList.length === 0 &&
                    shipmentObject.packageID > 0 &&
                    shipmentObject.serviceID > 0 &&
                    getError && (
                      <div className="details btn-ship margin-tb">
                        <div className="validationBox noRate">
                          <h3>{getError}</h3>
                        </div>
                      </div>
                    )}
                  {shipmentObject.isQueued &&
                    <div className="details btn-ship margin-tb">
                      <div className="validationBox noRate">
                        <h3>{"This order is already in the queue on the VIP portal"}</h3>
                      </div>
                    </div>
                  }

                  <ViewParcel
                    shipmentObject={shipmentObject}
                    validationsList={validationsList}
                  />

                  <div className="details btn-ship margin-tb">
                    {loader && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    <div>
                      {fundDetails && fundDetails[0]?.availableAmount <= 0
                        ? "Insufficient Wallet Balance. Please recharge."
                        : ""}
                    </div>

                    {shippngLabel?.shippingLabel && (
                      <div className="shipmentBooked validationBox">
                        <div className="heading">
                          <h2>
                            Shipment Booked{" "}
                            <i className="fa fa-fw fa fa-check"></i>
                          </h2>
                        </div>
                        <div className="optionsList">
                          <ul>
                            <li>
                              <a
                                href={shippngLabel.shippingLabel}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-fw fa fa-print"></i> Print
                                Label
                              </a>
                            </li>
                          </ul>
                          <div className="keyFieldWrapper shipmentKeyFieldWrapper">
                            <input
                              value={shippngLabel.trackingNumber}
                              className="form-control"
                            />
                            <button
                              onClick={(e) =>
                                handleCopyClipboard(
                                  e,
                                  shippngLabel.trackingNumber
                                )
                              }
                              className="btn copyBtn"
                              title="Copy key"
                            >
                              <i
                                className="fa fa-clone"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {getZipZoneStatus &&
                      validationsList.length === 0 &&
                      shipmentObject.packageID > 0 &&
                      shipmentObject.serviceID > 0 && (
                        <>
                          {zipZoneRates >= 0 && !isNaN(zipZoneRates) ? (
                            <div className="validationBox servicePackage">
                              <div className="row">
                                <div className="col-6">
                                  {services.length > 0 &&
                                    services.map((service) => (
                                      <>
                                        {shipmentObject.serviceID ===
                                          service.id && service.serviceName}
                                      </>
                                    ))}
                                  {packages.map((pack) => (
                                    <p className="packageTxt">
                                      {shipmentObject.packageID === pack.id &&
                                        pack.packageName}
                                    </p>
                                  ))}
                                </div>
                                <div
                                  className="col-6"
                                  style={{ textAlign: "right" }}
                                >
                                  {shipmentObject?.carrierCompanyName ===
                                    "3PL" ? (
                                    <img
                                      alt=""
                                      className="cus-main-logo-img"
                                      src={MainLogo}
                                    />
                                  ) : shipmentObject.carrierCompanyName ===
                                    "USPS" ? (
                                    <img
                                      src={uspsLogo}
                                      className="usps-logo"
                                      alt=""
                                    />
                                  ) : shipmentObject.carrierCompanyName ===
                                    "FedEx" ? (
                                    <img
                                      src={FedExLogo}
                                      className="usps-logo"
                                      alt=""
                                      style={{ maxHeight: "30px" }}
                                    />
                                  ) : shipmentObject.carrierCompanyName === "3PLPREMIUM" ? (
                                    <img
                                      alt="Premium logo"
                                      className="premiumLogo cus-main-logo-img"
                                      src={PremiumLogo}
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      className="premiumLogo cus-main-logo-img"
                                      src={findIsHostShipAi() ? uspsLogo : MainLogoAi}
                                    />
                                  )}
                                </div>
                                <div className="billgenerationship">
                                  <div className="billgeneratedetail">
                                    <div>Base Rate</div>
                                    <div>Fuel Surcharge</div>
                                    <div>Residential Delivery Surcharge</div>
                                  </div>
                                  <div className="billgeneratecharge">
                                    <div>
                                      ${formatNumberWithCommas(zipZoneRates)}
                                    </div>
                                    <div>$0.00</div>
                                    <div>$0.00</div>
                                  </div>
                                </div>
                                <div className="tshiphead">
                                  <hr></hr>
                                </div>
                                <div className="billgenerationship">
                                  <div className="billgeneratedetail">
                                    <div>Total</div>
                                  </div>
                                  <div className="billgeneratecharge">
                                    <div>
                                      ${formatNumberWithCommas(zipZoneRates)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <p className="shipInfo error">
                                    <i
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Please note that the prices you see
                                    currently are not the final price. There
                                    might be a case in which carriers raise
                                    corrections/adjustments in case of
                                    discrepancy after receiving the parcel.
                                    The corrections/adjustments raised will be
                                    settled from your account in future.
                                  </p>
                                </div>
                                <div className="tshiphead">
                                  <hr></hr>
                                </div>

                                {shipmentObject.isShipmentValidDate && (
                                  <div className="col-12">
                                    <div className="flex-container-1">
                                      <div>
                                        <b>Estimated Arrival Date:</b>
                                      </div>
                                      <div>{packageArrivalDate}</div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : zipZoneRates ? (
                            <div className="validationBox">
                              <h2>Error</h2>
                              <p>{zipZoneRates}</p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}

                    {validationsList.length > 0 && (
                      <div className="validationBox">
                        <h2 className="number-records">
                          {validationsList.length} Empty/Invalid Required
                          Fields
                        </h2>
                        <ul>
                          {validationsList.length > 0 &&
                            validationsList.map((error, index) => (
                              <li key={index}>{error.errorName}</li>
                            ))}
                        </ul>
                      </div>
                    )}
                    {showInvalidAddress && (
                      <div className="validationBox">
                        <h2>USPS couldn't validate this address</h2>
                        <p>You can still book this shipment</p>
                      </div>
                    )}
                    <AlertDialogModal
                      modalShow={showDeleteShipDialog}
                      modalClass={"modalDeleteWrapper"}
                      handleCancelDeleteAlert={handleCancelDeleteAlert}
                      handleYesDeleteAlert={handleYesDeleteAlert}
                    />
                    <UploadCSVModal
                      modalClass={"modalDeleteWrapper"}
                      gridData={activeCSVList}
                      modalShow={openCsvModal}
                      setOpenCsvModal={setOpenCsvModal}
                    />
                    {csvModalShow && (
                      <UploadDialog
                        onHide={() => setCsvModalShow(false)}
                        show={csvModalShow}
                        type={"shipment"}
                        getPendingOrderNumbers={getPendingOrderNumbers}
                      />
                    )}
                    {showSaveShipment && (
                      <SaveShipmentDialog
                        modalClass={"modalDeleteWrapper"}
                        modalShow={showSaveShipment}
                        toggleShipmentSave={toggleShipmentSave}
                        updateUserInput={updateUserInput}
                        saveShipment={saveShipment}
                        shipmentName={shipmentName}
                        setShipmentName={setShipmentName}
                      />
                    )}
                    {showLoadOrders && (
                      <LoadOrdersDialog
                        modalClass={"modalDeleteWrapper"}
                        modalShow={showLoadOrders}
                        toggleShipmentSave={toggleLoadShipment}
                        shipmentObject={shipmentObject}
                        updateUserInput={updateUserInput}
                        saveShipment={saveShipment}
                        userDetail={createdBy}
                        loadShipmentData={loadShipmentData}
                        deleteOrder={deleteOrder}
                        createNewShipment={createNewShipment}
                        commodityObj={JSON.parse(
                          JSON.stringify(commodityObj)
                        )}
                      />
                    )}

                    {showLoadAddressDialogObj.showModal && (
                      <LoadSavedAddress
                        showModal={showLoadAddressDialogObj.showModal}
                        showLoadAddressDialogObj={showLoadAddressDialogObj}
                        setShowLoadAddressDialogObj={
                          setShowLoadAddressDialogObj
                        }
                        shipmentObject={shipmentObject}
                        setShipmentObject={setShipmentObject}
                        dispatch={dispatch}
                        userDetails={createdBy}
                        USSates={USSates}
                        countries={countries}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
