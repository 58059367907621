import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCountries,
  getDimensionUnitList,
  getWeightUnitList,
} from "../../store/actions";
import {
  createOutbound,
  getAllConsignmentCarrierType,
  getAllConsignmentOutboundStatus,
  getAllConsignmentType,
  getInboundDefaultValues,
  getOutboundById,
  updateOutbound,
  updateOutboundImage,
  updateOutboundFiles,
  updateOutboundProductImage,
  updateOutboundProductDoc,
  getQuantityBySku,
  updateOutboundLabelAction,
} from "../../store/actions/inventory.action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoaderSpinner from "../common/Loader";
import { outboundObject, validUsZip } from "../../constant";
import { toast } from "react-toastify";
import {
  appendFilesToUploadData,
  handleDocsUpload,
  handleImageUpload,
  importProduct,
  quantityTextRender,
  renderConsignmentType,
  renderTooltip,
  validateEmail,
} from "../../config/utils";
import OutboundProductListingDialog from "./OutboundProductListing";
import { Tooltip as WmsTooltip } from "react-tooltip";
import _ from "lodash";
import OutboundImages from "./OutboundImages";
import ItemsOutbound from "./OutboundItems";
import ReceiverAddress from "./ReceiverAddress";

const OutboundAddComponent = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const backUrl = location?.state?.backUrl;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [copyOutboundStatusId, setCopyOutboundStatusId] = useState(0);
  const [formDataCopy, setFormDataCopy] = useState({});
  const [imageActions, setImageActions] = useState([])
  const [fileActions, setFileActions] = useState([]);
  const [originalResponseConsignment, setOriginalResponseConsignment] =
    useState({});
  const countries = useSelector((state) => state.shipment.countries);
  const dispatch = useDispatch();
  const { consignmentTypes } = useSelector(
    (state) => state.InventoryManagement
  );
  const { consignmentAllOutboundStatus } = useSelector(
    (state) => state.InventoryOutboundManagement
  );
  const [error, setErrors] = useState({});
  const [consignmentTypeList, setConsignmentTypeList] = useState([]);
  const [isEditable, setIsEditable] = useState(location?.state?.isEdit);
  const [userActionEdit, setUserActionEdit] = useState(location?.state?.userActionEdit);
  const { dimensionUnitList, weightUnitList } = useSelector(
    (state) => state.shipment
  );
  const [consignmentStatusList, setConsignmentStatusList] = useState([]);
  const [image, setImage] = useState([]);
  const [file, setFile] = useState([]);
  const [uploadedLabel, setUploadedLabel] = useState();
  const [weightUnitListing, setWeightUnitListing] = useState([]);
  const [dimensionUnitListing, setDimensionUnitListing] = useState([]);
  const [showProductListingBox, setShowProductListingBox] = useState(false);
  const [showInlargeModal, setshowInlargeModal] = useState(false);
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    if (Number(formData.consignmentTypeId) === 3) {
      setFormData({ ...formData, subQuantity: 0 });
    }
  }, [formData.consignmentTypeId])

  useEffect(() => {
    if (weightUnitList.length) {
      setWeightUnitListing(weightUnitList);
    }
    if (dimensionUnitList.length) {
      setDimensionUnitListing(dimensionUnitList);
    }
  }, [weightUnitList, dimensionUnitList]);

  useEffect(() => {
    if (!dimensionUnitList.length) {
      dispatch(getDimensionUnitList());
    }
  }, [dimensionUnitList]);

  useEffect(() => {
    if (!weightUnitList.length) {
      dispatch(getWeightUnitList());
    }
  }, [weightUnitList]);

  const handleDeleteImage = (item, index, mediaType) => {
    if (mediaType === "image") {
      let images = [...image];
      images.splice(index, 1);
      setImage(images);
    } else if (mediaType === "file") {
      let files = [...file];
      files.splice(index, 1);
      setFile(files);
      let cloneFormData = JSON.parse(JSON.stringify(formDataCopy));
      let deletedFiles = [];
      cloneFormData?.files?.map((box) => {
        if (box.id === item.id) {
          deletedFiles.push({
            ...box,
            isDeleted: true,
          });
        }
      });
      setFileActions([...fileActions, ...deletedFiles]);
    }
  };

  useEffect(() => {
    if (!consignmentAllOutboundStatus?.length) {
      dispatch(getAllConsignmentOutboundStatus());
    } else {
      setConsignmentStatusList(consignmentAllOutboundStatus);
    }
  }, [consignmentAllOutboundStatus]);

  useEffect(() => {
    if (Number(id)) {
      setCopyOutboundStatusId(formData.outboundStatusId)
      fetchOutboundById();
    } else {
      let conObj = _.cloneDeep(outboundObject);
      fetchDefaultValues(conObj);
    }
  }, [id]);

  useEffect(() => {
    if (!consignmentTypes.length) {
      dispatch(getAllConsignmentType());
    } else {
      setConsignmentTypeList(consignmentTypes);
    }
  }, [consignmentTypes]);

  useEffect(() => {
    if (!countries.length) {
      dispatch(getAllCountries());
    }
    fetchCarriers();
  }, []);

  const fetchCarriers = () => {
    getAllConsignmentCarrierType().then((res) => {
      if (res.data.Status === 200) {
        setCarriers(res.data.EntityList);
      }
    });
  };

  const fetchDefaultValues = async (formObject) => {
    getInboundDefaultValues().then((res) => {
      if (res.data.Status === 200) {
        let cloneFormObject = { ...formObject };
        if (!Number(id)) {
          cloneFormObject.outboundNumber = res.data.Entity.OutboundNumber;
        }
        cloneFormObject.sellerCode = res.data.Entity.SellerCode;
        setFormData(cloneFormObject);
      }
    });
  };

  const fetchOutboundById = async () => {
    setLoader(true);
    let dataRes = await Promise.resolve(dispatch(getOutboundById(id)));
    if (dataRes.data.Status === 200) {
      setOriginalResponseConsignment(
        JSON.parse(JSON.stringify(dataRes.data.Entity))
      );
      setFormDataCopy(JSON.parse(JSON.stringify(dataRes.data.Entity)));
      fetchDefaultValues(dataRes.data.Entity);
      setFile(dataRes.data.Entity.files);
    }
    setLoader(false);
  };

  const handleFormValue = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...error, [name]: "" });
  };

  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    let findOrder = consignmentStatusList.filter(item => item.id === Number(value));
    let findOutboundStatusId = consignmentStatusList.filter(item => item.id === Number(originalResponseConsignment.outboundStatusId));
    if (findOrder[0]?.consignmentOrder < findOutboundStatusId[0]?.consignmentOrder && id > 0) {
      toast.error("Consignment status cannot be move to backward state");
    } else {
      setFormData({ ...formData, [name]: value });
      setErrors({ ...error, [name]: "" });
    }
  };

  const handleBack = () => {
    if (backUrl) {
      navigate(backUrl, {
        state: {
          isEditable: isEditable,
          type: location?.state?.historyType
        }
      });
    } else {
      navigate('/outbound');
    }
  };

  /* istanbul ignore next */
  const updateMedia = async (type, OutboundId, mediaType, Type) => {
    if (mediaType.length > 0) {
      let uploadData = new FormData();
      uploadData.append("OutboundId", OutboundId);
      if (Type === "image") {
        uploadData = appendFilesToUploadData(uploadData, mediaType, "Images");
      } else {
        uploadData = appendFilesToUploadData(uploadData, mediaType, "Files");
      }

      let api = Type === "image" ? updateOutboundImage : updateOutboundFiles;
      let uploadMediaRes = await Promise.resolve(dispatch(api(uploadData)));
      if (uploadMediaRes.data && uploadMediaRes.data.Status === 200) {
        toast.success(`Consignment ${type} Successfully`);
        setLoader(false);
        handleBack();
      }
    } else {
      toast.success(`Consignment ${type} Successfully`);
      setLoader(false);
      handleBack();
    }
  };
  const errorHandling = () => {
    toast.error(`Something went wrong`);
    setLoader(true);
  };
  /* istanbul ignore next */
  const updateImageDoc = async (type, OutboundId) => {
    let uploadData = new FormData();
    let imageToUpload = image.filter((item) => !item.isImported);
    let findImportedDocs = file.filter((item) => !item.isImported);
    uploadData.append("OutboundId", OutboundId);
    if (imageToUpload.length > 0 && findImportedDocs?.length > 0) {
      uploadData = appendFilesToUploadData(uploadData, imageToUpload, "Images");
      const uploadMediaRes = await Promise.resolve(
        dispatch(updateOutboundImage(uploadData))
      );

      if (uploadMediaRes.data && uploadMediaRes.data.Status === 200) {
        uploadData.delete("Images");
        uploadData = appendFilesToUploadData(uploadData, findImportedDocs, "Files");
        const uploadMediaRes = await Promise.resolve(
          dispatch(updateOutboundFiles(uploadData))
        );
        if (uploadMediaRes.data && uploadMediaRes.data.Status === 200) {
          toast.success(`Consignment ${type} Successfully`);
          setLoader(false);
          handleBack();
        } else {
          errorHandling();
        }
      } else {
        errorHandling();
      }
    } else {
      toast.success(`Consignment ${type} Successfully`);
      setLoader(false);
      handleBack();
    }
  };

  const handleItemsValidations = () => {
    if (formData?.items.length) {
      let formObjectData = _.cloneDeep(formData);
      let validateItems = formObjectData?.items?.map((item) => {
        let error = '';
        if (!item.orderedQuantity) {
          error = 'This is required';
        } else if (Number(item.orderedQuantity) > Number(item.availableQuantity)) {
          error = 'Ordered Qty should be equal or less than Available Qty.';
        }
        return {
          ...item,
          error: error
        };
      });
      formObjectData.items = validateItems;
      setFormData(formObjectData);
      let findErrors = formObjectData.items.filter(item => item?.error);
      if (findErrors.length) {
        return false
      }
      return true
    } else {
      toast.error('Add atleast one product.');
      return false
    }
  }

  const handleValidations = () => {
    let error = {};
    let isFormValid = true;
    const requiredFields = ["quantity", "outboundNumber", "consignmentTypeId", "receiverName", "phoneNumber", "zipCode", "state", "city"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        isFormValid = false;
        error[field] = `This is required.`;
      }
    });
    if (formData.zipCode && parseInt(formData.country_Id) === 218 && !validUsZip.test(formData.zipCode)) {
      error.zipCode = `Enter valid zip code in US format.`;
      isFormValid = false;
    }
    if (formData.email && !validateEmail(formData.email)) {
      error.email = `Enter valid email address.`;
      isFormValid = false;
    }

    if (formData?.phoneNumber?.length < 10) {
      error.phoneNumber = `Enter valid phone number.`;
      isFormValid = false;
    }
    if (!formData.isGenerateLabel) {
      if (!formData.trackingNumber) {
        error.trackingNumber = `This is required.`;
        isFormValid = false;
      }
      if (Number(id) === 0 && !Boolean(uploadedLabel)) {
        error.uploadedLabel = `This is required.`;
        isFormValid = false;
      }
    }

    !isFormValid && toast.error('Validation failed');
    setErrors(error);
    return isFormValid;
  };

  const updateLabel = async (outboundId) => {
    if (Boolean(uploadedLabel)) {
      let uploadData = new FormData();
      uploadData.append("OutboundId", outboundId);
      uploadData.append("TrackingNumber", formData.trackingNumber);
      uploadData.append("SubTrackingNumber", formData.subTrackingNumber);
      uploadData.append("Label", uploadedLabel);
      let responseLabel = await Promise.resolve(dispatch(updateOutboundLabelAction(uploadData)));
      if (responseLabel.data.Status !== 200) {
        toast.error('Something went wrong')
      }
    }
  }

  /* istanbul ignore next */
  const getQuantityBySkuValue = async () => {
    if (formData.productSKU || formData.productId) {
      let data = {
        sku: formData.productSKU,
        consignmentType: Number(formData.consignmentTypeId),
        productId: formData.productId || 0
      };

      try {
        const response = await getQuantityBySku(data);
        let res = response?.data?.Entity;

        if (res && !res.IsProductExist) {
          return true;
        } else if (res && res.IsProductExist && Number(formData.quantity) > res.Quantity) {
          toast.error('The requested outbound quantity exceeds the current inventory quantity');
          return false;
        }
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    } else {
      return true;
    }
  };

  const handleSaveConsole = async () => {
    if (!handleValidations() || !handleItemsValidations()) return;
    const isValid = await getQuantityBySkuValue();
    if (!isValid) return;

    if (Number(id) === 0) {
      await createNewOutbound();
    } else {
      handleUpdateOutbound();
    }
  };
  /* istanbul ignore next */
  const createNewOutbound = async () => {
    const payloadData = {
      ...formData,
      consignmentTypeId: Number(formData.consignmentTypeId),
      outboundStatusId: Number(formData.outboundStatusId),
    };
    setLoader(true);

    try {
      const createOutboundRes = await Promise.resolve(
        dispatch(createOutbound(payloadData))
      );
      if (createOutboundRes.data.Status === 200) {
        updateLabel(createOutboundRes.data.Entity.id);
        handleCreateOutboundSuccess(
          createOutboundRes.data.Entity.id,
          "created"
        );
      } else {
        handleCreateOutboundFailure(
          createOutboundRes.data.Entity.resultMessage
        );
      }
    } catch (error) {
      handleCreateOutboundFailure("Something went wrong");
    }
  };

  /* istanbul ignore next*/
  const handleSaveImportedDocs = async (outboundId, isBack) => {
    let findImportedImages = image.filter((item) => item.isImported);
    let findImportedDocs = file.filter((item) => item.isImported);
    if (findImportedImages.length) {
      let ImportedImagesId = _.map(findImportedImages, "id");
      let uploadData = new FormData();
      uploadData.append("OutboundId", outboundId);
      uploadData = appendFilesToUploadData(uploadData, ImportedImagesId, "ExistingProductImagesId");
      updateOutboundProductImage(uploadData).then((resImage) => {
        if (resImage.data.Status !== 200) {
          setLoader(false);
        }
      });

      if (findImportedDocs.length) {
        let ImportedDocId = _.map(findImportedDocs, "id");
        let uploadDataDoc = new FormData();
        uploadDataDoc.append("OutboundId", outboundId);
        uploadDataDoc = appendFilesToUploadData(uploadDataDoc, ImportedDocId, "ExistingProductFilesId");
        updateOutboundProductDoc(uploadDataDoc).then((res) => {
          if (res.data.Status === 200) {
            !isBack && handleBack();
          }
        });
      } else {
        !isBack && handleBack();
      }
    }
  }

  /* istanbul ignore next */
  const handleCreateOutboundSuccess = async (outboundId, type) => {
    if (!image.length && !file.length) {
      handleBack();
      toast.success(`Consignment ${type} Successfully`);
      return
    }
    let filtermportedImage = image.filter((item) => !item.isImported);
    let filtermportedDoc = file.filter((item) => !item.isImported);
    if (filtermportedImage?.length || filtermportedDoc?.length) {
      handleSaveImportedDocs(outboundId, true);
    } else {
      handleSaveImportedDocs(outboundId, false);
    }

    if (filtermportedImage?.length > 0 && filtermportedDoc?.length > 0) {
      updateImageDoc(type, outboundId);
    } else if (filtermportedImage?.length > 0) {
      updateMedia(type, outboundId, filtermportedImage, "image");
    } else if (filtermportedDoc?.length > 0) {
      updateMedia(type, outboundId, filtermportedDoc, "file");
    } else {
      toast.success(`Consignment ${type} Successfully`);
      setLoader(false);
    }
  };

  const handleCreateOutboundFailure = (errorMessage) => {
    toast.error(errorMessage);
    setLoader(false);
  };

  const handleUpdateOutbound = async () => {
    try {
      setLoader(true);
      let payloadData = { ...formData };
      payloadData.consignmentTypeId = Number(formData.consignmentTypeId);
      payloadData.outboundStatusId = Number(formData.outboundStatusId);
      payloadData.ImageActions = imageActions;
      payloadData.fileActions = fileActions;
      payloadData.itemActions = formData.items.filter(item => item.isDeleted);
      let updateConsoleRes = await Promise.resolve(
        dispatch(updateOutbound(payloadData))
      );
      updateLabel(updateConsoleRes.data.Entity.id);
      if (
        updateConsoleRes.data.Status === 200 &&
        updateConsoleRes.data.Entity.id !== 0
      ) {
        handleCreateOutboundSuccess(updateConsoleRes.data.Entity.id, "updated");
      } else {
        toast.error(updateConsoleRes.data.Entity.resultMessage);
        setLoader(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const handleBlurInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.trim() });
  };

  const handleCancel = () => {
    setShowProductListingBox(false);
  };

  const handleEditbutton = () => {
    return loader || isEditable || userActionEdit;
  };

  const handleSaveButton = () => {
    return ((loader || !isEditable) && !userActionEdit);
  };

  const handleNavigateToInbound = (item, type = "") => {
    if (type) {
      navigate(`/inbound/${item}`, {
        state: {
          data: item,
          isEdit: type === "edit" ? true : false,
          backUrl: `/outbound/${Number(id)}`
        },
      });
      return;
    }
    navigate(`/inbound/${item}`, {
      state: {
        data: item,
        isEdit: true,
        screen: "outbound",
        outboundId: Number(id),
      },
    });
  };

  const handleMultiProductSelect = (data) => {
    let createdData = importProduct(data);
    let formDataClone = _.cloneDeep(formData);
    formDataClone.items.push(...createdData);
    setFormData(formDataClone);
  };

  const handleEditable = () => {
    if (formDataCopy.outboundStatusId === 1) {
      setIsEditable(true);
    } else if (!userActionEdit) {
      setUserActionEdit(true);
    }
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          <span>Outbound</span>
          <div className="top_right_button_container">
            <div className="top_right_button_container">
              <button
                className="btn cus-seconday-bg-btn top-btn"
                onClick={handleSaveConsole}
                disabled={handleSaveButton()}
              >
                Save
              </button>
              <button
                className="btn cus-seconday-bg-btn top-btn eidtbtn"
                onClick={handleEditable}
                disabled={handleEditbutton()}
              >
                Edit
              </button>
              <button
                className="btn cus-primary-transparent-btn bckbtn"
                onClick={handleBack}
                disabled={loader}
              >
                Back
              </button>
            </div>
          </div>
        </div>
        {loader && <LoaderSpinner />}

        <div className="cus-page-content-sec">
          <div className="row equalHeight">
            <div className={Number(id) > 0 ? "col-sm-12 col-lg-9" : "col-12"}>
              <div className="incoming_wrapper main_content_table addressDetailWrapper">
                <div className="row mb-3">
                  <div className="col-lg-3 col-md-6 col-sm-12 mobMb-10">
                    <label>
                      Outbound Id <span className="require-field">*</span>
                    </label>
                    <input
                      className={`form-control outboundNumber`}
                      type={"text"}
                      name="outboundNumber"
                      value={formData.outboundNumber}
                      onChange={handleFormValue}
                      disabled={!isEditable || Number(id) > 0}
                      onBlur={handleBlurInput}
                    />
                    <div className="error">{error.outboundNumber}</div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mobMb-10">
                    <label>Linked Inbound Id</label>
                    {formData.consignmentId ? (
                      <div className="tackingUrl b">
                        <span
                          className="link"
                          onClick={() =>
                            handleNavigateToInbound(formData.consignmentId, "view")
                          }
                        >
                          {formData.consignmentNumber}
                        </span>
                        <a rel="noreferrer" target="_blank" href={`/inbound/${formData.consignmentId}`}>
                          <i class="fa fa-external-link-square" aria-hidden="true" />
                        </a>
                      </div>
                    ) : (
                      <input
                        className={`form-control`}
                        type={"text"}
                        name="consignmentNumber"
                        value={formData.consignmentNumber}
                        onChange={handleFormValue}
                        onBlur={handleBlurInput}
                        disabled={!isEditable}
                      />
                    )}

                  </div>
                  <div className="col col-lg-3 col-md-6 col-sm-12 mobMb-10">
                    <label className="zIndex1">Status</label>
                    <select
                      className={`form-control carrierSelect`}
                      onChange={handleStatusChange}
                      value={formData.outboundStatusId}
                      disabled={(!isEditable && !userActionEdit) || (formData.outboundStatusId === 3)}
                      name={"outboundStatusId"}
                    >
                      <option value={"0"}>Select Status Type</option>
                      {consignmentStatusList?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <label>
                      Consignment Type <span className="require-field">*</span>
                    </label>
                    <select
                      value={formData.consignmentTypeId}
                      className={`form-control consignmentTypeId`}
                      onChange={handleFormValue}
                      name="consignmentTypeId"
                      disabled={!isEditable || formData.productId || formData.consignmentId || formData.isQuantityDeducted || copyOutboundStatusId > 1}
                    >
                      <option value={"0"}>Select Consignment Type</option>
                      {consignmentTypeList?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <div className="error">{error.consignmentTypeId}</div>
                  </div>
                </div>

                <div className="row mb-3 addressForm">
                  <div className="col col-lg-3 col-md-6 col-sm-12 zIndex2">
                    <label>
                      {renderConsignmentType(formData.consignmentTypeId, true)} <span className="require-field">*</span>
                    </label>
                    {Number(formData.consignmentTypeId) === 3 &&
                      <><i className="ml-1 fa fa-info-circle" aria-hidden="true" id={`mainQuantity`}></i>
                        <WmsTooltip
                          anchorId={`mainQuantity`}
                          content={quantityTextRender(formData.quantity)}
                          place="right"
                          className="tooltipTxt"
                        />
                      </>
                    }
                    <input
                      className={`form-control`}
                      type={"number"}
                      name="quantity"
                      min={0}
                      value={formData.quantity}
                      onChange={handleFormValue}
                      disabled={!isEditable || formData.consignmentId || formData.isQuantityDeducted || copyOutboundStatusId > 1}
                      onBlur={handleBlurInput}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                    />
                    <div className="error">{error.quantity}</div>
                  </div>
                  {Number(formData.consignmentTypeId) !== 3 && (
                    <>
                      <div className="col-lg-5 col-md-6 col-sm-12 mobMb-10 zIndex2">
                        <label>Sub Quantity{renderConsignmentType(formData.consignmentTypeId, false)} {' '}
                          <>
                            <i className="fa fa-info-circle" aria-hidden="true" id={`quantity`}></i>
                            <WmsTooltip
                              anchorId={`quantity`}
                              content={renderTooltip(Number(formData.consignmentTypeId), Number(formData.subQuantity))}
                              place="right"
                              className="tooltipTxt"
                            />
                          </>
                        </label>
                        <input
                          className={`form-control`}
                          type={"number"}
                          min={0}
                          name="subQuantity"
                          value={formData.subQuantity}
                          onChange={handleFormValue}
                          disabled={!isEditable || formData.consignmentId || formData.isQuantityDeducted || copyOutboundStatusId > 1 || (formData.productId && (Number(formData.consignmentTypeId) === 1 || Number(formData.consignmentTypeId) === 2))}
                          onBlur={handleBlurInput}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 zIndex1">
                        <label>Sub Quantity - Items (Total Quantity of items in Boxes)</label>
                        <input
                          className={`form-control tertiarySubQuantity`}
                          type={"number"}
                          min={0}
                          onBlur={handleBlurInput}
                          onKeyDown={(evt) => ["e", '.', "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                          name="tertiarySubQuantity"
                          value={formData?.tertiarySubQuantity}
                          onChange={handleFormValue}
                          disabled={!isEditable || formData.consignmentId || formData.isQuantityDeducted || copyOutboundStatusId > 1 || (formData.productId && (Number(formData.consignmentTypeId) === 1 || Number(formData.consignmentTypeId) === 2))}
                        />
                      </div>
                    </>
                  )}

                </div>

                <div className="row mb-3">
                  <div className="col col-lg-3 col-md-6 col-sm-12 mobMb-10">
                    <label>Images</label>
                    <div className="uploadContainer">
                      <div className="inlineBlock fileUpload inventory">
                        <button
                          disabled={!isEditable}
                          type="button"
                          className="btn cus-seconday-bg-btn imgUploadbtn"
                        >
                          Upload images
                        </button>
                        <input
                          className="hiddenFileField"
                          onChange={(e) =>
                            handleImageUpload(
                              e,
                              toast,
                              error,
                              setErrors,
                              setImage,
                              image,
                              true
                            )
                          }
                          disabled={!isEditable}
                          type="file"
                          id="customFile"
                          multiple
                        />
                      </div>
                    </div>
                    <div>
                      {image.length > 0 &&
                        image.map((item, index) => (
                          <div className="selectedFile" key={item?.id}>
                            <div className="fileName">{item.name}</div>
                            <button
                              className="deleteImg"
                              onClick={() =>
                                handleDeleteImage(item, index, "image")
                              }
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col col-lg-3 col-md-6 col-sm-12 mobMb-10">
                    <label>Attachments</label>
                    <div className="uploadContainer">
                      <div className="inlineBlock fileUpload inventory">
                        <button
                          type="button"
                          className="btn cus-seconday-bg-btn"
                          disabled={!isEditable}
                        >
                          Upload documents
                        </button>
                        <input
                          className="hiddenFileField"
                          onChange={(e) => {
                            handleDocsUpload(e, toast, error, setErrors, setFile, file);
                          }}
                          disabled={!isEditable}
                          type="file"
                          id="customFile"
                          multiple
                        />
                      </div>
                    </div>
                    <div>
                      {file.length > 0 &&
                        file.map((item, index) => (
                          <div className="selectedFile" key={item?.id}>
                            <div className="fileName">
                              {item.fileUrl ? (
                                <a
                                  href={item.fileUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.name}
                                </a>
                              ) : (
                                <>{item.name}</>
                              )}
                            </div>
                            <button
                              className="deleteFile"
                              onClick={() =>
                                handleDeleteImage(item, index, "file")
                              }
                              disabled={!isEditable}
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <ItemsOutbound
                  formData={formData}
                  setFormData={setFormData}
                  handleFormValue={handleFormValue}
                  isEditable={isEditable}
                  setShowProductListingBox={setShowProductListingBox}
                  loader={loader}
                  weightUnitListing={weightUnitListing}
                  dimensionUnitListing={dimensionUnitListing}
                  error={error}
                />

                <ReceiverAddress
                  formData={formData}
                  handleFormValue={handleFormValue}
                  dimensionUnitListing={dimensionUnitListing}
                  weightUnitListing={weightUnitListing}
                  handleBlurInput={handleBlurInput}
                  isEditable={isEditable}
                  carriers={carriers}
                  error={error}
                  setFormData={setFormData}
                  setUploadedLabel={setUploadedLabel}
                  uploadedLabel={uploadedLabel}
                  loader={loader}
                  setLoader={setLoader}
                  setErrors={setErrors}
                  outboundId={id}
                />

                <div className="row">
                  <div className="col-lg-12">
                    <label>Comment</label>
                    <textarea
                      className={`form-control textArea`}
                      type={"text"}
                      name="comments"
                      value={formData.comments}
                      onChange={handleFormValue}
                      disabled={!isEditable && !userActionEdit}
                      onBlur={handleBlurInput}
                    />
                  </div>
                </div>
              </div>
              {showProductListingBox && (
                <OutboundProductListingDialog
                  showProductListingBox={showProductListingBox}
                  setShowProductListingBox={setShowProductListingBox}
                  handleCancel={handleCancel}
                  show={showProductListingBox}
                  handleMultiProductSelect={handleMultiProductSelect}
                  formData={formData}
                />
              )}
            </div>
            <>
              <OutboundImages
                id={id}
                formData={formData}
                isEditable={isEditable}
                showInlargeModal={showInlargeModal}
                setshowInlargeModal={setshowInlargeModal}
                setFormData={setFormData}
                file={file}
                formDataCopy={formDataCopy}
                setImageActions={setImageActions}
                imageActions={imageActions}
              />
            </>
          </div>
        </div>
      </div>
    </main>
  );
};
export default OutboundAddComponent;
