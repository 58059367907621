import React, { useEffect, useState } from 'react';
import { Table } from "bootstrap-4-react/lib/components";
import edit_icon from "../../assets/edit_icon.svg";
import eye_icon from "../../assets/eye_icon.svg";
import { Tooltip as WmsTooltip } from "react-tooltip";
import { fetchAllCarrierServicesList, sellerCarrierServiceBackDetails } from '../../store/actions';
import { useSelector } from 'react-redux';
import BatchPagination from '../batch/BatchPagination';
import { featureSubmodulePermission, numOfPage, permissionSellerCarrierServicesEdit, permissionSellerCarrierServicesView, settings, SUBF_SERVICECONFIGIURATION } from '../../constant';
import LoaderSpinner from '../common/Loader';

import { getAllCompanyDetails, getFilterSellers } from "../../store/actions/rateCard";
import {
  getAllSellersList
} from "../../store/actions";
import { checkPermission, renderTableRow } from '../../config/utils';

import { getSelectList } from "../../config/utils";
import Select from "react-select";
import _ from 'lodash';

const SellerCarrierServices = (props) => {
  const { navigate, userDetails, dispatch } = props;
  const { allCarrerServicesStatus, allCarrerServicesList, sellerCarrierServiceprevState } = useSelector((state) => state.MarginReducer);
  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    totalPages: 1,
    sortColumn: '',
    sortOrder: 'DESCENDING',
    filterSellerIds: [],
    filterCompanyIds: [],
    totalRecords: 0,
  }
  const [filterData, setfilterData] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const { getAllCompany, filterAllSellers } = useSelector((state) => state.RateCardReducer);
  const [allSellers, setAllSellers] = useState([]);
  const UserValue = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (!_.isEmpty(sellerCarrierServiceprevState)) {
      setfilterData({ ...filterData, ...sellerCarrierServiceprevState });
    } else {
      setfilterData(dataLoad);
    }
  }, []);

  useEffect(() => {
    let companyList = getAllCompany?.Entity?.listCompanyMasterOrdersEntity || [];
    let sellerList = filterAllSellers?.EntityList || [];
    if (companyList.length) {
      if (userDetails?.Value?.isSuperAdmin === true) {
        setCompanyList(getSelectList(getAllCompany?.Entity?.listCompanyMasterOrdersEntity, 'Id', 'name'));
      } else {
        let filterCompany = companyList.filter((company) => company.name.toLowerCase() === userDetails?.Value?.companyName.toLowerCase());
        setCompanyList(getSelectList(filterCompany, 'Id', 'name'));

      }
    }
    if (sellerList.length) {
      setAllSellers(getSelectList(sellerList, 'id', 'sellerName'));
      setSellerList(getSelectList(sellerList, 'id', 'sellerName'));
    }
  }, [getAllCompany, filterAllSellers]);


  useEffect(() => {
    let sellerData = {
      "pageIndex": 1,
      "pageSize": 10,
      "sortColumn": "name",
      "sortOrder": "ASCENDING",
      "companyIdList": [0],
      ScreenType: 2
    };
    dispatch(getAllCompanyDetails(null, 500, 1));
    dispatch(getFilterSellers(sellerData));
  }, [userDetails]);

  const fetchSellers = async (companyList) => {
    if (companyList.length === 0) {
      setSellerList(allSellers);
      return;
    }
    let data = {
      companyIds: companyList,
    };
    let res = await getAllSellersList(data);
    if (res?.data?.Status === 200) {
      let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
        (item) => {
          return {
            ...item,
            label: item.sellerAndCompany,
            value: item.id,
          };
        }
      );
      setSellerList(dataList);
    }
  };

  const handleMultiSelect = (value, fieldName) => {
    setfilterData({
      ...filterData,
      [fieldName]: value
    });
    if (fieldName === "filterCompanyIds") {
      let companyData = _.map(value, "Id");
      fetchSellers(companyData);
      if (filterData?.filterSellerIds?.length) {
        const filteredSellers = filterData.filterSellerIds.filter(seller =>
          companyData.includes(parseInt(seller.companyIds))
        );
        setfilterData({ ...filterData, filterSellerIds: filteredSellers, filterCompanyIds: value })
      }
    }
  }

  const handleResetFilter = async () => {
    setfilterData({ ...dataLoad, pageSize: filterData.pageSize })
    setSellerList([]);
    dispatch(fetchAllCarrierServicesList(dataLoad));
    saveFilterSellerCarrierServiceDetails()
    setSellerList(allSellers)
  };

  useEffect(() => {
    if (!_.isEmpty(filterData)) {
      fetchAllCarrierServices();
    }
  }, [filterData.pageSize, filterData.pageIndex, filterData.sortColumn, filterData.sortOrder]);

  useEffect(() => {
    if (allCarrerServicesList?.Entity?.listCompanySellerEntities) {
      setTotalPages(Math.ceil(allCarrerServicesList?.Entity?.TotalRecords / (filterData.pageSize)));
    }
  }, [allCarrerServicesList]);

  const handleViewEdit = (item, type) => {
    saveFilterSellerCarrierServiceDetails()
    if (type === 'view') {
      navigate(`/seller-carrier-services/${item.companySellerId}`, {
        state: {
          data: item,
          editable: false,
        },
      });
    } else {
      navigate(`/seller-carrier-services/${item.companySellerId}`, {
        state: {
          data: item,
          editable: true,
        },
      });
    }
  }

  const handleSortingColumn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setfilterData({
      ...filterData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return filterData.sortColumn === columnName && filterData.sortOrder === "ASCENDING"
        ? "fa fa-arrow-up"
        : "fa fa-arrow-down";
    }
    return filterData?.sortColumn === columnName ? "active" : "sortColumn";
  };

  const fetchAllCarrierServices = () => {
    let data = {
      ...filterData,
      filterCompanyIds: _.map(filterData.filterCompanyIds, 'Id'),
      filterSellerIds: _.map(filterData.filterSellerIds, 'id'),
    }
    dispatch(fetchAllCarrierServicesList(data))
  }

  const handleFilter = () => {
    let data = {
      ...filterData,
      pageIndex: 1,
      filterCompanyIds: _.map(filterData.filterCompanyIds, 'Id'),
      filterSellerIds: _.map(filterData.filterSellerIds, 'id'),
    }
    dispatch(fetchAllCarrierServicesList(data))
    setfilterData({
      ...filterData, pageIndex: 1,
      pageSize: 30
    })
  }

  const saveFilterSellerCarrierServiceDetails = () => {
    dispatch(sellerCarrierServiceBackDetails(filterData))
  }

  const setCurrentPagePagination = (value) => {
    setfilterData({ ...filterData, pageIndex: value });
  }
  const setTotalNumberOfPage = (value) => {
    setfilterData({ ...filterData, pageSize: value });
  }

  return (
    <div className="incoming_wrapper main_content_table incomingTbl">
      {allCarrerServicesStatus === 'pending' && <LoaderSpinner />}
      {UserValue?.Value?.roleName !== 'Seller' && (
        <div className='pt-3 zIndex2'>
          <div className="row mb10">
            <div className="col-lg-4 col-sm-12">
              <div className="row">
                <div className="col-12">
                  <label className="labelHead">Company Name</label>
                </div>
                <div className="col-12 fieldAndLabel">
                  <div className={"select_react carrierListSelect"}>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={companyList}
                      value={filterData.filterCompanyIds}
                      onChange={(e) => handleMultiSelect(e, "filterCompanyIds")}
                      className="activeSellerListInput"
                      classNamePrefix="list"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="row">
                <div className="col-12">
                  <label className="labelHead">Seller Name</label>
                </div>
                <div className="col-12 fieldAndLabel">
                  <div className={"select_react carrierListSelect"}>
                    <Select
                      isMulti
                      options={sellerList}
                      value={filterData.filterSellerIds}
                      onChange={(e) => handleMultiSelect(e, "filterSellerIds")}
                      className="activeSellerListInput"
                      classNamePrefix="list"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12">
              <div className="col-12">
                <label
                  className="labelHead"
                  style={{ marginBottom: "26px" }}
                ></label>
              </div>
              <button
                className="btn cus-primary-transparent-btn top-btn"
                onClick={handleResetFilter}
                disabled={filterData?.filterCompanyIds?.length === 0 ? true : false}
              >
                {" "}
                Clear Filter
              </button>
              <button
                className="btn cus-seconday-bg-btn top-btn filterButton"
                onClick={handleFilter}
              >
                {" "}
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="tableFixHead zIndex1">
        <Table className="table table-borderless keyGenerate">
          <thead>
            <tr className="trow">
              <th className="trows-s" width={"20%"}>
                <div
                  className={handleSortingClass("companyName")}
                  onClick={(e) =>
                    handleSortingColumn(e, "companyName")
                  }
                >
                  Associate Company
                  <i
                    className={handleSortingClass("companyName", true)}
                    aria-hidden="true"
                  ></i>
                </div>
              </th>

              <th className="trows-s" width={"30%"}>
                <div
                  className={handleSortingClass("sellerName")}
                  onClick={(e) =>
                    handleSortingColumn(e, "sellerName")
                  }
                >
                  Seller
                  <i
                    className={handleSortingClass("sellerName", true)}
                    aria-hidden="true"
                  ></i>
                </div>
              </th>
              <th className="trows-s" width={"10%"}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {allCarrerServicesStatus === 'success' &&
              allCarrerServicesList?.Entity?.listCompanySellerEntities.length > 0 &&
              allCarrerServicesList?.Entity?.listCompanySellerEntities.map((item) => (
                <tr key={item.Id}>
                  <td className="trows-s">{item.companyName}</td>
                  <td className="trows-s">{item.sellerName}</td>
                  <td>
                    <>
                      {checkPermission(settings, SUBF_SERVICECONFIGIURATION, permissionSellerCarrierServicesView, featureSubmodulePermission, props.employeePermissions) && (
                        <img
                          className="View-Icon"
                          src={eye_icon}
                          alt="View"
                          onClick={() => handleViewEdit(item, "view")}
                          id={`${item.companySellerId}-view`}
                        />
                      )}
                      {checkPermission(settings, SUBF_SERVICECONFIGIURATION, permissionSellerCarrierServicesEdit, featureSubmodulePermission, props.employeePermissions) && (
                        <img
                          className="listActionIcon"
                          src={edit_icon}
                          alt="Edit"
                          onClick={() => handleViewEdit(item, "edit")}
                          id={`${item.companySellerId}-edit`}
                        />
                      )}
                      <WmsTooltip
                        anchorId={`${item.companySellerId}-view`}
                        content="View"
                        place="right"
                        className="tooltipWidth"
                      />
                      <WmsTooltip
                        anchorId={`${item.companySellerId}-edit`}
                        content="Edit"
                        place="right"
                        className="tooltipWidth"
                      />
                    </>

                  </td>
                </tr>
              ))}
            {allCarrerServicesStatus === 'pending' && (
              renderTableRow(3, 'Loading...')
            )}

            {allCarrerServicesStatus === 'success' && allCarrerServicesList?.Entity?.listCompanySellerEntities.length === 0 && (
              renderTableRow(3, 'No Record Found...')
            )}
          </tbody>
        </Table>
      </div>
      {
        allCarrerServicesList?.Entity?.listCompanySellerEntities.length > 0 && (
          <BatchPagination
            gridData={allCarrerServicesList?.Entity?.listCompanySellerEntities}
            totalRecords={allCarrerServicesList?.Entity?.TotalRecords}
            numberOfPage={filterData.pageSize}
            numOfPage={numOfPage}
            loader={allCarrerServicesStatus === 'pending'}
            currentPage={filterData.pageIndex}
            totalPages={totalPages || 0}
            setCurrentPage={setCurrentPagePagination}
            setNumberOfPage={setTotalNumberOfPage}
          />
        )
      }
    </div >
  );
}

export default SellerCarrierServices;