import axios from "axios";
import {
  EXISTING_RATE_CARDS,
  EXISTING_RATE_CARDS_FAILED,
  GET_ACTIVE_SELLERS,
  GET_ACTIVE_SELLERS_FAILED,
  GET_BASE_RATECARDS,
  GET_BASE_RATECARDS_ZONE_DATA,
  GET_SELLER_RATECARDS,
  GET_ACTIVE_COMPANY,
  GET_ALL_COMPANY,
  GET_SELLER_DETAILS,
  GET_ADRESS_COMPANY_DETAIL,
  GET_FILTER_LIST,
  GET_ALL_COMPANY_STATUS,
  COMPANY_SAVE_PAGINATION_DETAILS,
  BASE_RATE_CARD_SAVE_PAGINATION_DETAILS,
  SELLER_RATE_CARD_SAVE_PAGINATION_DETAILS,
  GET_SELLER_FILTER_LIST,
  GET_ALL_SELLER_FILTER_LIST,
} from "../types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiUrl } from "../../constant";
import { getAllFilterCompanyService, getAllSellerFilterService, getAllSellerService } from "../../services/company.service";
import { rateConstant } from "../constants/rateCard.constant";
import { ratecardService } from "../../services/ratecard.service";

export const getRateCardDetails =
  (CreatedBy, setTotalPages, numberOfPage, pageIndex, companySellerId, role, filterValue) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `` },
      };
      try {
        const response = await axios.get(
          `${apiUrl}/api/GetBaseRateCardDetails?createdBy=${CreatedBy?.Value?.employeeId}&SortColumn=${filterValue?.sortColumn || ''}&SortOrder=${filterValue?.sortOrder || ''}&role=${role}&companySellerId=${companySellerId}&sellerId=${CreatedBy?.Value?.sellerId}&companyId=${CreatedBy?.Value?.companyId}&pageIndex=${pageIndex}&pageSize=${numberOfPage}`,
          config
        );
        dispatch({
          type: GET_BASE_RATECARDS,
          payload: response.data,
        });
        setTotalPages(
          Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
        );
      } catch (e) {
        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const getTotalSellerDetails =
  (sellerRateCardMasterId) => async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };
    try {
      const response = await axios.get(
        `${apiUrl}/api/GetSellerRateCardCompany?sellerRateCardMasterId=${sellerRateCardMasterId}`,
        config
      );
      dispatch({
        type: GET_SELLER_DETAILS,
        payload: response.data,
      });
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };
export const getSellerCardDetails =
  (isAdmin, id, setTotalPages, numberOfPage, pageIndex, filterValue) => async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };
    try {
      const response = await axios.post(
        `${apiUrl}/api/GetSellerRateCards`,
        {
          isAdmin: id?.Value?.isSuperAdmin,
          sellerId: id?.Value?.sellerId,
          createdBy: id?.Value?.employeeId,
          companyId: id?.Value?.companyId,
          companySellerId: id?.Value?.companySellerId,
          pageIndex,
          pageSize: numberOfPage,
          isDefault: filterValue.sortColumn ? false : true,
          ...filterValue
        },
        config
      );
      dispatch({
        type: GET_SELLER_RATECARDS,
        payload: response.data,
      });
      setTotalPages(
        Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
      );
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };
export const GetBaseZoneRateMasterData = (carrierId) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };
  try {
    const response = await axios.get(
      `${apiUrl}/api/GetBaseZoneRateMasterData?carrierId=${carrierId}`,
      config
    );
    dispatch({
      type: GET_BASE_RATECARDS_ZONE_DATA,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};

export const GetSellerBaseZoneRateMasterData =
  (carrierId, sellerId) => async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };
    try {
      const response = await axios.get(
        `${apiUrl}/api/GetSellerZoneRateMasterDataById?carrierId=${carrierId}&sellerMasterId=${sellerId}`,
        config
      );
      dispatch({
        type: GET_BASE_RATECARDS_ZONE_DATA,
        payload: response.data,
      });
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const UpdateBaseRateCard =
  (data, navigate, setErrors) => async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };
    try {
      const response = await axios.post(
        `${apiUrl}/api/UpdateBaseRateCard`,
        {
          baseMasterId: `${data?.baseMasterId}`,
          name: `${data?.name}`,
          carrierId: `${data?.carrierId}`,
          isActive: `${data?.isActive}`,
          createdBy: `${data?.createdBy}`,
          modifiedBy: `${data?.modifiedBy}`,
          sellerId: `${data?.sellerId}`,
          companyId: `${data?.companyId}`,
        },
        config
      );
      if (response?.data?.Entity?.isValid == true) {
        toast.success(response?.data?.Entity?.message);
        navigate("/rateCard", { state: { zoneTab: data.activeTab } });
        setErrors({});
      } else {
        toast.error(response?.data?.Entity?.message);
      }
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };
export const UpdateSellerRateCard =
  (data, navigate, setErrors) => async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };
    try {
      const response = await axios.put(
        `${apiUrl}/api/UpdateSellerRateMaster`,
        data,
        config
      );
      if (response?.data?.Entity?.isValid == true) {
        toast.success(response?.data?.Entity?.message);
        navigate("/rateCard", { state: { zoneTab: data.activeTab } });
        setErrors({});
      } else {
        toast.error(response?.data?.Entity?.message);
      }
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };
export const GetActiveSellers = () => async (dispatch) => {
  try {
    const response = await axios.get(`${apiUrl}/api/seller`);
    dispatch({
      type: GET_ACTIVE_SELLERS,
      payload: response.data.EntityList || [],
    });
  } catch (e) {
    dispatch({
      type: GET_ACTIVE_SELLERS_FAILED,
      payload: e,
    });
  }
};
export const GetActiveCompany = () => async (dispatch) => {
  try {
    const response = await axios.get(`${apiUrl}/api/company`);
    dispatch({
      type: GET_ACTIVE_COMPANY,
      payload: response.data || [],
    });
  } catch (e) {
    dispatch({
      type: GET_ACTIVE_COMPANY,
      payload: e,
    });
  }
};
export const GetCompanyAndAddress = (companyId) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/GetCompanyAndAddressByCompanyId?Id=${companyId}`
    );
    dispatch({
      type: GET_ADRESS_COMPANY_DETAIL,
      payload: response.data || [],
    });
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};
const fetchAllCompanyDetailsPending = () => ({
  type: rateConstant.FETCH_ALL_COMPANY_DETAILS_PENDING,
});
const fetchAllCompanyDetailsFailed = () => ({
  type: rateConstant.FETCH_ALL_COMPANY_DETAILS_FAILED,
});
const fetchAllCompanyDetailsSuccess = (response) => ({
  type: rateConstant.FETCH_ALL_COMPANY_DETAILS_SUCCESS,
  payload: response,
});

export const fetchAllCompanyDetails = (data) => {
  return (dispatch) => {
      dispatch(fetchAllCompanyDetailsPending());
      ratecardService.fetchAllCompanyDetailsService(data).then((result) => {
          dispatch(fetchAllCompanyDetailsSuccess(result?.data?.Entity?.listCompanyMasterOrdersEntity));
      }).catch((error) => {
          dispatch(fetchAllCompanyDetailsFailed());
      })
  };
};

export const getAllCompanyDetails =
  (setTotalPages, numberOfPage, pageIndex, filterData) => async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };
    dispatch({ type: GET_ALL_COMPANY_STATUS });
    try {
      if (typeof filterData?.state === 'object') {
        const response = await axios.post(
          `${apiUrl}/GetAllCompany`,
          {
            ...filterData,
            pageIndex: pageIndex,
            pageSize: numberOfPage,
            country: filterData?.country,
            state: filterData?.state.label,
            screenType: 1,
          },
          config
        );

        dispatch({
          type: GET_ALL_COMPANY,
          payload: response.data || [],
        });
        setTotalPages(
          Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
        );
      } else if (filterData?.state || filterData?.sortColumn) {
        const response = await axios.post(
          `${apiUrl}/GetAllCompany`,
          {
            ...filterData,
            pageIndex: pageIndex,
            pageSize: numberOfPage,
            country: filterData?.country,
            state: filterData?.state,
            screenType: 1,
          },
          config
        );
        dispatch({
          type: GET_ALL_COMPANY,
          payload: response.data || [],
        });
        setTotalPages(
          Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
        );
      } else {
        const response = await axios.post(
          `${apiUrl}/GetAllCompany`,
          {
            ...filterData,
            pageIndex: pageIndex,
            pageSize: numberOfPage,
            sortColumn: "",
            sortOrder: "DESCENDING",
            screenType: 1,
          },
          config
        );


        dispatch({
          type: GET_ALL_COMPANY,
          payload: response.data || [],
        });
        setTotalPages(
          Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
        );
      }

    } catch (e) {
      dispatch({
        type: GET_ACTIVE_COMPANY,
        payload: e,
      });
    }
  };

export const GetExistingBaseRateCards =
  (createdBy, status) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/GetBaseRateCardDetails?CreatedBy=${createdBy?.Value?.employeeId}&companySellerId=${createdBy?.Value?.companySellerId}&role=${createdBy?.Value?.role}&SellerId=${createdBy?.Value?.sellerId}&CompanyId=${createdBy?.Value?.companyId}&status=${status}`
      );

      dispatch({
        type: EXISTING_RATE_CARDS,
        payload: response.data.Entity || [],
      });
    } catch (e) {
      dispatch({
        type: EXISTING_RATE_CARDS_FAILED,
        payload: e,
      });
    }
  };

export const createSellerRateCard = (data) => {
  return new Promise((res, rej) => {
    return res(axios.post(`${apiUrl}/api/CreateSellerRateCard`, data));
  });
};

export const createNewSellerRateCard = (data) => {
  return new Promise((res, rej) => {
    return res(axios.post(`${apiUrl}/api/CreateSellerRateCardByUploadFile`, data));
  });
};

const getCompanyListSuccess = (response) => ({
  type: GET_FILTER_LIST,
  payload: response,
});

const getSellerListSuccess = (response) => ({
  type: GET_SELLER_FILTER_LIST,
  payload: response,
});

const getSellerFilterListSuccess = (response) => ({
  type: GET_ALL_SELLER_FILTER_LIST,
  payload: response,
});

export const fetchCompanyFilters = (data) => {
  return (dispatch) => {
    getAllFilterCompanyService(data)
      .then((result) => {
        dispatch(getCompanyListSuccess(result.data));
      })
      .catch((error) => {
        console.error(error)
      });
  };
};

export const fetchSellerFilters = (data) => {
  return (dispatch) => {
    getAllSellerService(data)
      .then((result) => {
        dispatch(getSellerListSuccess(result.data));
      })
      .catch((error) => {
        console.error(error)
      });
  };
};

export const getFilterSellers = (data) => {
  return (dispatch) => {
    getAllSellerFilterService(data)
      .then((result) => {
        dispatch(getSellerFilterListSuccess(result.data));
      })
      .catch((error) => {
        console.error(error)
      });
  };
};

export const companyListBackDetails = (data) => ({
  type: COMPANY_SAVE_PAGINATION_DETAILS,
  payload: data,
});
export const baseRatecardListBackDetails = (data) => ({
  type: BASE_RATE_CARD_SAVE_PAGINATION_DETAILS,
  payload: data,
});
export const sellerRatecardListBackDetails = (data) => ({
  type: SELLER_RATE_CARD_SAVE_PAGINATION_DETAILS,
  payload: data,
});