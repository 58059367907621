import React, { useEffect, useState } from "react";
import { Button } from "bootstrap-4-react/lib/components";
import { useNavigate } from "react-router-dom";
import LoaderSpinner from "../../common/Loader";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import BatchPagination from "../../batch/BatchPagination";
import { featureSubmodulePermission, inventoryManagement, numOfPage, permssionBulkUpload, permssionCreate, permssionEdit, permssionView, SUBF_LISTING } from "../../../constant";
import {
  getActiveIntegrations,
  getInventoryProductList,
} from "../../../store/actions/inventory.action";
import { checkPermission, renderTableRow } from "../../../config/utils";
import ViewImage from "../../common/ViewImage";
import SearchProduct from "./searchProduct";
import ActionItems from "../../common/showActions";
import { BulkUploadDialog } from "../../common/bulkUploadDialog";
import ProductListingFilter from "./ProductListingFilter";
import { InventoryProductBackDetails } from "../../../store/actions/employee";
import _ from "lodash";

const Inventory = (props) => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const {
    inventoryProductList,
    inventoryProductListStatus,
    activeIntegrations,
  } = useSelector((state) => state.InventoryManagement);
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);
  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    totalPages: 1,
    sortColumn: '',
    sortOrder: 'DESCENDING',
    searchKeyword: '',
    sellerIdList: [],
    companyIdList: [],
    showFilter: false,
    showSearch: false,
    totalRecords: 0,
  }
  const [filterData, setfilterData] = useState({});
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [showHideImageModal, setShowHideImageModal] = useState(false);
  const userDetails = useSelector((state) => state.auth.user);
  const { productPrevState } = useSelector((state) => state.employee);
  const [csvModalShow, setCsvModalShow] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [allSellers, setAllSellers] = useState([]);
  const UserValue = useSelector((state) => state.auth.user);

  const handleFilterToggle = (key, value) => {
    setfilterData({ ...filterData, [key]: value });
  }

  useEffect(() => {
    if (inventoryProductListStatus === "pending") {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [inventoryProductListStatus]);

  useEffect(() => {
    if (inventoryProductList?.TotalRecords) {
      setfilterData({ ...filterData, totalRecords: inventoryProductList?.TotalRecords, totalPages: Math.ceil(inventoryProductList?.TotalRecords / filterData.pageSize) });
    }
    if (inventoryProductList?.listInventoryEntity) {
      setProductList(inventoryProductList?.listInventoryEntity);
    }
  }, [inventoryProductList]);

  useEffect(() => {
    if (!_.isEmpty(productPrevState)) {
      setfilterData({ ...filterData, ...productPrevState });
    } else {
      setfilterData(dataLoad);
    }
  }, []);

  const handleNavigateToProduct = (item, type = "") => {
    saveCurrentDetails();
    if (type) {
      navigate(`/inventory/${item}`, {
        state: {
          data: item,
          isEdit: type === "edit" ? true : false,
        },
      });
      return;
    }
    navigate(`/inventory/${item}`, {
      state: {
        data: item,
        isEdit: true,
      },
    });
  };

  useEffect(() => {
    if (!_.isEmpty(filterData)) {
      fetchProductList();
    }
  }, [filterData.pageSize, filterData.pageIndex, filterData.sortColumn, filterData.sortOrder]);

  const fetchProductList = () => {
    let data = {
      ...filterData,
      companyIdList: _.map(filterData.companyIdList, 'Id'),
      sellerIdList: _.map(filterData.sellerIdList, 'id'),
    }
    dispatch(getInventoryProductList(data));
  };

  const handleSortingColumn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setfilterData({
      ...filterData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleSearchFilter = (e, type) => {
    if (type === "clear") {
      let data = JSON.parse(JSON.stringify(dataLoad));
      data.showFilter = true;
      data.pageSize = filterData.pageSize
      setfilterData(data);
      dispatch(getInventoryProductList(data));
      setSellerList(allSellers);
      return;
    }
    fetchProductList();
    setfilterData({ ...filterData, pageIndex: 1 });
    dispatch(InventoryProductBackDetails({}));
  };


  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return filterData.sortColumn === columnName && filterData.sortOrder === "ASCENDING"
        ? "fa fa-arrow-up"
        : "fa fa-arrow-down";
    }
    return filterData.sortColumn === columnName ? "active" : "sortColumn";
  };

  const handleReset = () => {
    setfilterData({ ...filterData, searchKeyword: '' });
    let data = {
      pageIndex: filterData.currentPage,
      pageSize: filterData.pageSize,
      sortColumn: filterData.sortColumn,
      sortOrder: filterData.sortOrder,
      searchKeyword: "",
    };
    dispatch(getInventoryProductList(data));
  };

  const getActiveIntegrationList = () => {
    dispatch(getActiveIntegrations());
  };

  useEffect(() => {
    getActiveIntegrationList();
  }, []);

  useEffect(() => {
    if (activeIntegrations) {
      setGridData(activeIntegrations?.EntityList);
    }
  }, [activeIntegrations]);

  const saveCurrentDetails = () => {
    dispatch(InventoryProductBackDetails(filterData));
  }

  const handleHistoryPage = (item) => {
    saveCurrentDetails();
    navigate(`/inventory/history-${item.id}`, {
      state: {
        data: item,
        editable: false,
        type: 'product'
      },
    })
  };

  const setCurrentPagePagination = (value) => {
    setfilterData({ ...filterData, pageIndex: Number(value) });
  }
  const setTotalNumberOfPage = (value) => {
    setfilterData({ ...filterData, pageSize: Number(value) });
  }

  const handleSearchText = (value) => {
    setfilterData({
      ...filterData,
      searchKeyword: value
    })
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          Listing
          <div className="top_right_button_container">
            <div className="top_right_button_container">
              {checkPermission(inventoryManagement, SUBF_LISTING, permssionBulkUpload, featureSubmodulePermission, props.employeePermissions) && (
                <button
                  className="btn cus-seconday-bg-btn top-btn"
                  onClick={() => {
                    setCsvModalShow(true);
                  }}
                >
                  Bulk Upload
                </button>
              )}
              {checkPermission(inventoryManagement, SUBF_LISTING, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
                <Button
                  className="cus-seconday-bg-btn top-btn addNewProduct"
                  onClick={() => handleNavigateToProduct(0)}
                >
                  Add New Product
                </Button>
              )}
              {UserValue?.Value?.roleName !== 'Seller' && (
                <Button
                  className="cus-seconday-bg-btn top-btn FilterButton"
                  onClick={() => handleFilterToggle('showFilter', !filterData.showFilter)}
                >Filter</Button>
              )}

              <Button
                className="cus-seconday-bg-btn openSearch"
                onClick={() => handleFilterToggle('showSearch', !filterData.showSearch)}
              >
                Search
              </Button>
            </div>
          </div>
        </div>

        <SearchProduct
          showSearch={filterData.showSearch}
          searchTxt={filterData.searchKeyword}
          setSearchTxt={handleSearchText}
          handleReset={handleReset}
          handleSearchFilter={handleSearchFilter}
          placeHolderText="Search by Company, Seller, Product SKU or Product Name"
        />

        {filterData.showFilter && (
          <ProductListingFilter
            showFilter={filterData.showFilter}
            dispatch={dispatch}
            useSelector={useSelector}
            filterData={filterData}
            setfilterData={setfilterData}
            fetchProductList={fetchProductList}
            handleReset={handleSearchFilter}
            sellerList={sellerList}
            setSellerList={setSellerList}
            allSellers={allSellers}
            setAllSellers={setAllSellers}
          />
        )}
        {loader && <LoaderSpinner />}

        <div className="main_content cus-page-content-sec">
          <div className="main_content_table_history">
            <div className="tableFixHead incomingTbl">
              <Table
                className={`table table-height cus-left-right-col-layout table ${loader ? "disableTble" : ""
                  }`}
              >
                <thead className="tbl-btch">
                  <tr className="table-rw-batch">
                    <th>Thumbnail</th>
                    {userDetails?.Value?.roleName !== 'Seller' && (
                      <th width={'20%'}>Company / Seller Name</th>
                    )}
                    <th width={'13%'}>
                      <div
                        className={handleSortingClass("productSku")}
                        onClick={(e) => handleSortingColumn(e, "productSku")}
                        id="productSku"
                      >
                        Product SKU
                        <i
                          className={handleSortingClass("productSku", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width={'15%'}>
                      <div
                        className={handleSortingClass("productName")}
                        onClick={(e) => handleSortingColumn(e, "productName")}
                        id="productName"
                      >
                        Product Name
                        <i
                          className={handleSortingClass("productName", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width={'20%'}>Product Description</th>
                    <th width={'8%'}>
                      <div
                        className={handleSortingClass("consignmentType")}
                        onClick={(e) => handleSortingColumn(e, "consignmentType")}
                        id="consignmentType"
                      >
                        Type
                        <i
                          className={handleSortingClass("consignmentType", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width={'8%'}>
                      <div
                        className={handleSortingClass("quantity")}
                        onClick={(e) => handleSortingColumn(e, "quantity")}
                        id="quantity"
                      >
                        Quantity
                        <i
                          className={handleSortingClass("quantity", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width={'20%'}>Action</th>
                  </tr>
                </thead>
                <tbody className="tbl-bt-body">
                  {inventoryProductListStatus === "success" && productList.map((item) => (
                    <tr key={item.id}>
                      <td>
                        {item.imageUrl && (
                          <div
                            className="thumbImg"
                            onClick={() => {
                              setSelectedRecord(item);
                              setShowHideImageModal(!showHideImageModal);
                            }}
                          >
                            <img
                              src={item.imageUrl}
                              alt=""
                              style={{ height: "24px" }}
                            />
                          </div>
                        )}
                      </td>
                      {userDetails?.Value?.roleName !== 'Seller' && (
                        <td>{item.companySellerName}</td>
                      )}
                      <td>
                        <span
                          className="link"
                          onClick={() =>
                            handleNavigateToProduct(item.id, "view")
                          }
                        >
                          {item.productSku}
                        </span>
                      </td>
                      <td>{item.productName}</td>
                      <td>{item.productDescription}</td>
                      <td>{item.consignmentType}</td>
                      <td>{item.quantity}</td>
                      <td>
                        {checkPermission(inventoryManagement, SUBF_LISTING, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                          <ActionItems
                            type={"View"}
                            item={item}
                            handleAction={() =>
                              handleNavigateToProduct(item.id, "view")
                            }
                          />
                        )}
                        {checkPermission(inventoryManagement, SUBF_LISTING, permssionEdit, featureSubmodulePermission, props.employeePermissions) && (
                          <ActionItems
                            type={"Edit"}
                            item={item}
                            handleAction={() =>
                              handleNavigateToProduct(item.id, "edit")
                            }
                          />
                        )}
                        <ActionItems
                          type={"History"}
                          item={item}
                          handleAction={() => handleHistoryPage(item)}
                        />
                      </td>
                    </tr>
                  ))}
                  {inventoryProductListStatus === "success" &&
                    productList.length === 0 &&
                    renderTableRow(10, "No Result Found...")}
                </tbody>
              </Table>
            </div>
            {csvModalShow && (
              <BulkUploadDialog
                onHide={() => setCsvModalShow(false)}
                show={csvModalShow}
                gridData={gridData}
                fetchProductList={fetchProductList}
              />
            )}

            <ViewImage
              title={"Product Image"}
              selectedRecord={selectedRecord}
              setShowHideImageModal={setShowHideImageModal}
              showHideImageModal={showHideImageModal}
            />
          </div>
        </div>
        <BatchPagination
          gridData={productList}
          totalRecords={filterData.totalRecords}
          numberOfPage={filterData.pageSize}
          numOfPage={numOfPage}
          loader={loader}
          currentPage={filterData.pageIndex}
          totalPages={filterData.totalPages}
          setCurrentPage={setCurrentPagePagination}
          setNumberOfPage={setTotalNumberOfPage}
        />
      </div>
    </main>
  );
};

export default Inventory;
