import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./createcsv.css";
import { getCsvAllIntegrationAction, mappingListBackDetails } from "../../store/actions";
import Button from "bootstrap-4-react/lib/components/Button";
import MappingList from "./MappingList";
import BatchPagination from "../batch/BatchPagination";
import LoaderSpinner from "../common/Loader";
import { checkPermission } from "../../config/utils";
import { featureSubmodulePermission, permssionCreate, settings, SUBF_CSVMAPPING } from "../../constant";
import _ from "lodash";

export function CreateCsv(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gridData, setGridData] = useState([]);
  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    totalPages: 1,
    sortColumn: '',
    sortOrder: 'DESCENDING',
    totalRecords: 0,
  }
  const [filterData, setfilterData] = useState({});
  const [paginationGridData, setPaginationGridData] = useState([]);
  const [loader, setLoader] = useState(false);
  const numOfPage = [30, 50, 100, 200, 500, 1000, 2000];
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const createdBy = useSelector((state) => state.auth.user);
  const mappingListPrevState = useSelector((state) => state.batchReducer.mappingListPrevState)

  useEffect(() => {
    if (!_.isEmpty(mappingListPrevState)) {
      setfilterData({ ...dataLoad, ...mappingListPrevState });
    } else {
      setfilterData({ ...dataLoad });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(filterData)) {
      getCsvAllIntegration();
    }
  }, [filterData?.pageSize, filterData?.pageIndex, filterData?.sortOrder, filterData?.sortColumn]);

  const getCsvAllIntegration = async () => {
    setLoader(true);
    let dataRes = await Promise.resolve(dispatch(
      getCsvAllIntegrationAction(
        `?createdBy=${createdBy?.Value?.employeeId}&sellerId=${createdBy?.Value?.sellerId
        }&SortColumn=${filterData?.sortColumn}&SortOrder=${filterData?.sortOrder
        }&companyId=${createdBy?.Value?.companyId
        }&PageIndex=${filterData?.pageIndex}&PageSize=${filterData?.pageSize}&ModifiedDate=05-15-2023&ModifiedBy=${createdBy?.Value?.employeeId
        }&CreatedDate=05-15-2023&IsActive=${true}&isAdmin=${createdBy?.Value?.isSuperAdmin
        }`
      )
    ));
    if (dataRes?.status === 200) {
      setGridData(dataRes.data?.Entity?.listCsvAllEntity);
      setPaginationGridData([...dataRes.data?.Entity?.listCsvAllEntity]);
      setTotalPages(
        Math.ceil(dataRes.data?.Entity?.TotalRecords / filterData?.pageSize)
      );
      setTotalRecords(dataRes.data?.Entity?.TotalRecords);
      setLoader(false);
    }
  };

  const handleSortingColumn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setfilterData({
      ...filterData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return filterData.sortColumn === columnName && filterData.sortOrder === "ASCENDING"
        ? "fa fa-arrow-up"
        : "fa fa-arrow-down";
    }
    return filterData.sortColumn === columnName ? "active" : "sortColumn";
  };

  const saveCurrentDetails = () => {
    dispatch(mappingListBackDetails(filterData));
  };
  const setCurrentPagePagination = (value) => {
    setfilterData({ ...filterData, pageIndex: value });
  }
  const setTotalNumberOfPage = (value) => {
    setfilterData({ ...filterData, pageSize: value });
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          CSV Mapping
          <div className="top_right_button_container">
            {checkPermission(settings, SUBF_CSVMAPPING, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
              <Button
                className="cus-seconday-bg-btn top-btn"
                onClick={() => {
                  navigate("/createintegration/0", {
                    state: {
                      url: '/csvList'
                    }
                  });
                }}
              >
                Create CSV Integration
              </Button>
            )}
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/setting");
                dispatch(mappingListBackDetails({}));
              }}
            >
              Back
            </Button>
          </div>
        </div>
        {loader && <LoaderSpinner />}
        <div className="main_content">
          <MappingList
            {...props}
            loader={loader}
            getCsvAllIntegration={getCsvAllIntegration}
            gridData={gridData}
            filterData={filterData}
            handleSortingColumn={handleSortingColumn}
            handleSortingClass={handleSortingClass}
            saveCurrentDetails={saveCurrentDetails}
          />
        </div>
        <BatchPagination
          gridData={paginationGridData}
          totalRecords={totalRecords}
          numberOfPage={filterData?.pageSize}
          numOfPage={numOfPage}
          loader={loader}
          currentPage={filterData?.pageIndex}
          totalPages={totalPages}
          setCurrentPage={setCurrentPagePagination}
          setNumberOfPage={setTotalNumberOfPage}
        />
      </div>
    </main>
  );
}
