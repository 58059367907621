import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../fundManagement/fundmanagement.css";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompanyDetails } from "../../store/actions/rateCard";
import { Button } from "bootstrap-4-react/lib/components";
import BatchPagination from "../batch/BatchPagination";
import AddCompanyModal from "../modal/AddCompanyModal";
import edit_icon from "../../assets/edit_icon.svg";
import eye_icon from "../../assets/eye_icon.svg";
import {
  getAllSellers,
  getSellersforMultipleCompany,
  sellerListBackDetails,
  userListBackDetails,
} from "../../store/actions";
import FilterCompany from "../company-Seller-Filter";
import { EditSeller } from "../EditSeller";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { accountsManagement, featureSubmodulePermission, numOfPage, permissionAssociatedUser, permssionCreate, permssionEdit, permssionView, SUBF_SELLER } from "../../constant";
import { checkPermission, renderTableRow } from "../../config/utils";

export function SellerManagement(props) {
  const {
    type,
    companyId,
    isTab,
    sellerState,
    setSellerState,
  } = props;
  const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserValue = useSelector((state) => state.auth.user);
  const getAssociatedSellersData = useSelector(
    (state) => state?.company?.getMultipleCompanySellers
  );
  const { getMultipleCompanySellersStatus, sellerBackDetails } = useSelector((state) => state?.company);
  const [loader, setLoader] = useState(false);
  const getSellersData = useSelector(
    (state) => state?.company?.getAllSellersData
  );
  const { getAllCompany } = useSelector((state) => state.RateCardReducer);
  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    sortColumn: "id",
    sortOrder: 'DESCENDING',
    showFilter: false,
    country: '',
    state: "",
  };
  const [filterValue, setFilterValue] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [sellerId, setsellerId] = useState();
  const [editMode, seteditMode] = useState();
  const [companyModalShow, setcompanyModalShow] = React.useState(false);
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(sellerBackDetails)) {
      setFilterValue({ ...filterValue, ...sellerBackDetails });
    } else {
      setFilterValue({ ...dataLoad });
    }
  }, [])

  useEffect(() => {
    if (getMultipleCompanySellersStatus === 'PENDING') {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [getMultipleCompanySellersStatus])

  useEffect(() => {
    if (!type) {
      dispatch(getAllCompanyDetails(null, 500, 1));
    }
  }, [UserValue]);

  const handleSellerEdit = (rowData, typeOf) => {
    dispatch(sellerListBackDetails(filterValue))
    if (typeOf === "company") {
      setSellerState(true);
      setsellerId(rowData?.id);
      seteditMode(false);
    } else {
      navigate(`/editSeller/sell-${rowData?.id}`, {
        state: {
          data: rowData,
          editable: false,
        },
      });
    }
  };

  const handleSortingCoulmn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterValue.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setFilterValue({
      ...filterValue,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleAssociatedUserPage = (sellerIdValue) => {
    dispatch(sellerListBackDetails(filterValue))
    navigate(`/editSeller/sell-${sellerIdValue}`, {
      state: {
        sellerId: sellerIdValue,
        associatedUser: true,
      },
    });
  };

  const handleSellerView = (rowData, typeVal) => {
    dispatch(sellerListBackDetails(filterValue))
    if (typeVal === "company") {
      setSellerState(true);
      setsellerId(rowData?.id);
      seteditMode(true);
    } else {
      navigate(`/editSeller/sell-${rowData?.id}`, {
        state: {
          data: rowData,
          editable: true,
        },
      });
    }
  };
  useEffect(() => {
    if (!_.isEmpty(filterValue)) {
      if (!type) {
        let isArray = Array.isArray(filterValue?.state);
        let filterData = {
          ...filterValue,
          state: isArray ? filterValue?.state[0]?.value : filterValue?.state
        }
        dispatch(
          getAllSellers(setTotalPages, filterValue.pageSize, filterValue.pageIndex, filterData)
        );
      }
    }
  }, [UserValue, filterValue.pageSize, filterValue.pageIndex, filterValue.sortColumn, filterValue.sortOrder, filterValue.state, filterValue.country]);

  useEffect(() => {
    if (companyId && filterValue.pageIndex) {
      dispatch(getSellersforMultipleCompany([companyId]));
    }
  }, [UserValue, filterValue.pageSize, filterValue.pageIndex]);

  useEffect(() => {
    if (
      getAllCompany?.Entity &&
      getAllCompany?.Entity?.listCompanyMasterOrdersEntity.length
    ) {
      let data = getAllCompany?.Entity?.listCompanyMasterOrdersEntity.map(
        (item) => {
          return {
            ...item,
            value: item.Id,
            label: item.name,
          };
        }
      );
      setCompanyList(data);
    }
  }, [getAllCompany]);

  const handleFilterToggle = (key, value) => {
    setFilterValue({ ...filterValue, [key]: value });
  }

  const renderSellerState = (type) => {
    return sellerState ? (
      <EditSeller
        {...props}
        sellerId={sellerId}
        editMode={editMode}
        seteditMode={seteditMode}
        type={type}
        setSellerState={setSellerState}
        companyId={companyId}
      />
    ) : (
      <>
        <div className="main__tile_heading cus-btn-include-sec">
          Associated Sellers
          <div className="top_right_button_container">
            <div className="btn m2 selectYear filterIncoming top-btn"></div>
            {checkPermission(accountsManagement, SUBF_SELLER, permssionCreate, featureSubmodulePermission, employeePermissions) && (
              <>
                <Button
                  className="cus-seconday-bg-btn"
                  onClick={() => setcompanyModalShow(true)}
                >
                  Add Seller
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="main_content_table  company-table">
          <div className="tableFixHead">
            <Table className="table  table-height cus-left-right-col-layout">
              <thead border="Green">
                <tr>
                  <th className="head-company">Seller Brand Name</th>
                  <th>Country</th>
                  <th>State</th>
                  <th>Phone Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getAssociatedSellersData?.Entity &&
                  getAssociatedSellersData?.Entity?.listSellerMasterOrdersEntity?.map(
                    (d, i) => (
                      <>
                        <tr key={d?.id}>
                          <td>{d?.newSellerName}</td>
                          <td>{d?.defaultCountry}</td>
                          <td>{d?.defaultState}</td>
                          <td>
                            <span className="phn-value">
                              {formatPhoneNumberIntl(d?.phoneNumber)}
                            </span>
                          </td>
                          <td>
                            <img
                              className="View-Icon"
                              onClick={() => handleSellerView(d, "company")}
                              src={eye_icon}
                              alt=""
                            ></img>
                            <img
                              className="history-Icon"
                              onClick={() => handleSellerEdit(d, "company")}
                              src={edit_icon}
                              alt=""
                            ></img>
                          </td>
                        </tr>
                      </>
                    )
                  )}
                {!getAssociatedSellersData?.Entity?.listSellerMasterOrdersEntity
                  .length && (
                    <tr>
                      <td colSpan={"4"} align="center">
                        No Record Found
                      </td>
                    </tr>
                  )}
              </tbody>
            </Table>
          </div>
        </div>
        {getAssociatedSellersData?.Entity?.listSellerMasterOrdersEntity &&
          renderList(
            getAssociatedSellersData?.Entity?.listSellerMasterOrdersEntity
          )}
      </>
    );
  };

  const renderList = (data) => {
    return (
      <BatchPagination
        gridData={data}
        totalVisualRecords={data.length}
        numberOfPage={filterValue.pageSize}
        numOfPage={numOfPage}
        currentPage={filterValue.pageIndex}
        totalPages={totalPages || 0}
        totalRecords={getAssociatedSellersData?.Entity?.TotalRecords}
        setCurrentPage={(value) => setFilterValue({ ...filterValue, pageIndex: value })}
        setNumberOfPage={(value) => setFilterValue({ ...filterValue, pageSize: value })}
      />
    );
  };

  const renderColumn = (title, field) => {
    return (
      <div
        className={filterValue.sortColumn === field ? "active" : "sortColumn"}
        onClick={(e) => handleSortingCoulmn(e, field)}
      >
        {title}
        <i
          className={
            filterValue.sortColumn === field && filterValue.sortOrder === "ASCENDING"
              ? "fa fa-arrow-up"
              : "fa fa-arrow-down"
          }
          aria-hidden="true"
        ></i>
      </div>
    );
  };

  return (
    <>
      <main className={isTab ? "inTabElement" : ""}>
        {type === "associatedSeller" ? (
          <div>
            {companyModalShow && (
              <AddCompanyModal
                associatedSeller={type}
                companyId={companyId}
                show={companyModalShow}
                companyList={companyList}
                type={"seller"}
                onHide={() => setcompanyModalShow(false)}
              />
            )}

            <div className="main__container">{renderSellerState(type)}</div>
          </div>
        ) : (
          <>
            {companyModalShow && (
              <AddCompanyModal
                show={companyModalShow}
                companyList={companyList}
                type={"seller"}
                onHide={() => setcompanyModalShow(false)}
                setTotalPages={setTotalPages}
                numberOfPage={filterValue.pageSize}
                currentPage={filterValue.pageIndex}
                filterData={filterValue}
              />
            )}

            <div className="main__container">
              <div className="main__tile_heading cus-btn-include-sec">
                Seller Management
                <div className="top_right_button_container">
                  <div className="btn m2 selectYear filterIncoming top-btn">
                    <Button
                      className="cus-seconday-bg-btn FilterButton"
                      onClick={() => handleFilterToggle('showFilter', !filterValue.showFilter)}
                    >Filter
                    </Button>
                  </div>

                  {checkPermission(accountsManagement, SUBF_SELLER, permssionCreate, featureSubmodulePermission, employeePermissions) && (
                    <Button
                      className="cus-seconday-bg-btn"
                      onClick={() => setcompanyModalShow(true)}
                    >
                      Add Seller
                    </Button>
                  )}
                </div>
              </div>
              {filterValue.showFilter && (
                <FilterCompany
                  setfilterData={setFilterValue}
                  filterData={filterValue}
                  dataLoad={dataLoad}
                />
              )}
              <div className="main_content_table  company-table incomingTbl tableFixHead">
                <Table className={`table-height cus-left-right-col-layout ${loader ? "disableTble" : ""}`}>
                  <thead border="Green">
                    <tr>
                      <th className="head-company">
                        {renderColumn("Seller Brand Name", "sellerName")}
                      </th>
                      <th>{renderColumn("Country", "country")}</th>
                      <th>{renderColumn("State", "state")}</th>
                      <th>
                        {renderColumn("Associated Users", "associatedUsers")}
                      </th>
                      <th>{renderColumn("Phone Number", "phonenumber")}</th>
                      <th>
                        <span className="action-text">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getSellersData?.Entity &&
                      getSellersData?.Entity?.listSellerMasterOrdersEntity?.map(
                        (d) => (
                          <tr key={d?.id}>
                            <td>{d?.newSellerName}</td>
                            <td>{d?.defaultCountry}</td>
                            <td>{d?.defaultState}</td>
                            <td>
                              {d?.userCount > 0 && checkPermission(accountsManagement, SUBF_SELLER, permissionAssociatedUser, featureSubmodulePermission, props.employeePermissions) ? (
                                <div
                                  className="seller-modal"
                                  onClick={() => {
                                    handleAssociatedUserPage(d?.id)
                                    dispatch(userListBackDetails({}))
                                  }}
                                >
                                  {d?.userCount}
                                </div>
                              ) : (
                                <div>{d?.userCount}</div>
                              )}
                            </td>
                            <td>
                              <span className="phn-value">
                                {formatPhoneNumberIntl(d?.phoneNumber) ||
                                  d?.phoneNumber}
                              </span>
                            </td>

                            <td>
                              {checkPermission(accountsManagement, SUBF_SELLER, permssionView, featureSubmodulePermission, employeePermissions) && (
                                <img
                                  className="View-Icon"
                                  onClick={() => handleSellerView(d)}
                                  src={eye_icon}
                                  alt=""
                                ></img>
                              )}
                              {checkPermission(accountsManagement, SUBF_SELLER, permssionEdit, featureSubmodulePermission, employeePermissions) && (
                                <img
                                  className="history-Icon"
                                  onClick={() => handleSellerEdit(d)}
                                  src={edit_icon}
                                  alt=""
                                ></img>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    {!getSellersData?.Entity?.listSellerMasterOrdersEntity
                      .length && (
                        <>{renderTableRow(6, 'No Record Found')}</>
                      )}
                  </tbody>
                </Table>
              </div>
              {getSellersData?.Entity?.listSellerMasterOrdersEntity && (
                <BatchPagination
                  gridData={
                    getSellersData?.Entity?.listSellerMasterOrdersEntity
                  }
                  totalVisualRecords={
                    getSellersData?.Entity?.listSellerMasterOrdersEntity?.length
                  }
                  numberOfPage={filterValue.pageSize}
                  numOfPage={numOfPage}
                  currentPage={filterValue.pageIndex}
                  totalPages={totalPages || 0}
                  totalRecords={getSellersData?.Entity?.TotalRecords}
                  setCurrentPage={(value) => setFilterValue({ ...filterValue, pageIndex: value })}
                  setNumberOfPage={(value) => setFilterValue({ ...filterValue, pageSize: value })}
                />
              )}
            </div>
          </>
        )}
      </main>
    </>
  );
}
