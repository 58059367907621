import React, { useRef, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import AlertDialogModal from "./AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import BatchBooking from "./BatchBooking";
import { RecentBatchPrint } from "./RecentBatchPrint";

const BatchGroup = (props) => {
  const {
    selectedData,
    setShowCreateBatchDialog,
    setSelectedBatch,
    setSelectedData,
    gridData,
    createdBy,
    getAllRecords,
    setRate,
    thresholdValidation,
  } = props;
  const dispatch = useDispatch();
  const ref = useRef();
  const { batchesList } = useSelector((state) => state.batchReducer);
  const [showDeleteBatchDialog, setShowDeleteBatchDialog] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let data;
    if (batchesList?.length) {
      /* istanbul ignore next */
      data = batchesList.map((item) => {
        return {
          ...item,
          id: item.orderBatchId,
          label: item.orderBatchName,
        };
      });
      setOptions(data);
    } else {
      setOptions([]);
    }
  }, [batchesList?.length]);

  /* istanbul ignore next */
  const handleSearch = (query) => {
    setSelectedData([]);
    setSelectedBatch(query);
    ref.current.blur();
  };
  /* istanbul ignore next */
  const handleOptions = (option) => {
    return (
      <div className="menuItemWrapper" key={option.id}>
        <div className="label">{option.label}</div>
      </div>
    );
  };
  /* istanbul ignore next */
  const handleBatchDialogCancel = () => {
    setShowDeleteBatchDialog(!showDeleteBatchDialog);
  };

  /* istanbul ignore next */
  const handleDeleteBatch = async () => {
    let responseDeleteBatch = dispatch();
    if (responseDeleteBatch.data && responseDeleteBatch.data.Status === 200) {
      handleBatchDialogCancel();
    }
  };

  return (
    <div className="bookingSection rightWidgetArea">
      <div className="create-batch batchBooking">
        <div className="batch-select">
          <div className="headingBatch">Select Batch Group </div>
          <Typeahead
            renderMenuItemChildren={handleOptions}
            id="batch"
            options={options}
            onChange={handleSearch}
            placeholder="Select Batch from list"
            clearButton
            className="batchListing"
            ref={ref}
          />
        </div>
        <div className="bookingWrapper">
          <Button
            onClick={() => setShowCreateBatchDialog(true)}
            variant="outline-primary"
            disabled={selectedData.length === 0}
            className={`${selectedData.length === 0
                ? "batchBookingBtn booking disabled"
                : "batchBookingBtn booking"
              }`}
          >
            {selectedData.length ? (
              <>Add {selectedData.length} Orders to Batch</>
            ) : (
              <>No Order Selected</>
            )}
          </Button>
          <BatchBooking
            {...props}
            selectedData={selectedData}
            setRate={setRate}
            thresholdValidation={thresholdValidation}
            gridData={gridData}
            createdBy={createdBy}
            getAllRecords={getAllRecords}
          />
          <RecentBatchPrint
            dispatch={dispatch}
            className="create-batch batchBooking"
            userDetails={createdBy}
          />
        </div>
      </div>
      <AlertDialogModal
        messageText={'Are you sure, you want to delete this batch?'}
        modalShow={showDeleteBatchDialog}
        modalClass={'modalDeleteWrapper'}
        handleCancelDeleteAlert={handleBatchDialogCancel}
        handleYesDeleteAlert={handleDeleteBatch}
      />
    </div>
  );
};

export default BatchGroup;
