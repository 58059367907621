import React, { useEffect, useState, useRef } from 'react';
import LoaderSpinner from '../common/Loader';
import { createAddress, getAddressById, updateAddress } from '../../store/actions/address.action';
import { useDispatch, useSelector } from 'react-redux';
import { commonAddressObj } from '../../constant';
import { fetchAddressFromZipCode, getAllCountries, getStates } from '../../store/actions';
import { toast } from "react-toastify";
import Input from 'react-phone-number-input/input';
import { useNavigate, useParams } from 'react-router-dom';

const AddressDetail = () => {
    const { id } = useParams();
    const userDetails = useSelector((state) => state.auth.user);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const [addressObj, setAddressObj] = useState({});
    const [error, setErrors] = useState({});
    const navigate = useNavigate();
    const countries = useSelector((state) => state.shipment.countries);
    const USSates = useSelector((state) => state.shipment.states);
    const zipCodeValue = useRef('');

    useEffect(() => {
        if (id === '0') {
            setAddressObj({ ...commonAddressObj });
        } else {
            fetchAddress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const fetchAddress = async () => {
        setLoader(true);
        let res = await getAddressById(id);
        if (res && res.data && res.data.Status === 200) {
            setLoader(false);
            setAddressObj(res.data.Entity);
        }
    }

    useEffect(() => {
        if (!countries.length) {
            dispatch(getAllCountries());
        }
        if (!USSates.length) {
            dispatch(getStates(218));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValueUpdate = (event, isChecked = false) => {
        const { value, name } = event.target;
        if (isChecked) {
            setAddressObj({
                ...addressObj,
                [name]: event.target.checked
            });
        } else {
            if (name === 'countryId') {
                let allCountries = [...countries];
                let countryIndex = allCountries.findIndex(
                    (item) => item.id.toString() == value
                );
                setAddressObj({
                    ...addressObj,
                    [name]: value,
                    phone: `+(${countries[countryIndex].phoneCountryCode}) `,
                    state: ''
                });
            } else {
                setAddressObj({
                    ...addressObj,
                    [name]: value
                });
            }
        }
        setErrors({
            ...error,
            [name]: ''
        });
    }

    const validateAddress = () => {
        let isValid = true;
        let errorData = {};
        const zipCodeRegex = /^\d{5}(-\d{4})?$/;
        if (!addressObj.name) {
            isValid = false;
            errorData.name = 'This is required';
        }
        if (!addressObj.phone) {
            isValid = false;
            errorData.phone = 'Phone number is required';
        }
        if (Number(addressObj.countryId) === 218 && addressObj.phone && addressObj.phone.length < 10) {
            isValid = false;
            errorData.phone = 'Phone number should have a minimum of 10 digits';
        }
        if (!addressObj.isSender && !addressObj.isReceiver) {
            isValid = false;
            errorData.isSender = 'This is required';
        }
        if (!addressObj.countryId) {
            isValid = false;
            errorData.countryId = 'Country is required';
        }
        if (!addressObj.state) {
            isValid = false;
            errorData.state = 'State is required';
        }
        if (!addressObj.city) {
            isValid = false;
            errorData.city = 'City is required';
        }
        if (!addressObj.zip) {
            isValid = false;
            errorData.zip = 'Zip is required';
        } else if (Number(addressObj.countryId) === 218 && !zipCodeRegex.test(addressObj.zip)) {
            isValid = false;
            errorData.zip = "Zip code should be in the format xxxxx or xxxxx-xxxx";
        }
        if (!addressObj.addressLine1) {
            isValid = false;
            errorData.addressLine1 = 'Address 1 is required';
        }
        setErrors(errorData);
        return isValid;
    };

    const handleSaveAddress = async () => {
        if (validateAddress()) {
            let data = {
                ...addressObj,
                userId: userDetails?.Value?.employeeId,
                companySellerId: userDetails?.Value?.companySellerId,
                countryId: Number(addressObj.countryId),
            }
            setLoader(true);
            if (id === '0') {
                let res = await createAddress(data);
                if (res && res.data && res.data.Status === 200) {
                    toast.success(res?.data?.Message?.AppStatusDescription);
                    navigate('/address-book');
                } else {
                    toast.error(res?.data?.Message?.AppStatusDescription);
                }
            } else {
                let res = await updateAddress(data);
                if (res && res.data && res.data.Status === 200) {
                    toast.success(res?.data?.Message?.AppStatusDescription);
                    navigate('/address-book');
                }
            }
            setLoader(false);
        }
    };

    const fetchAddressByZip = async (e) => {
        if (zipCodeValue.current !== addressObj.zip) {
            zipCodeValue.current = addressObj.zip;
            let { value } = e.target;
            let responseAddress = await Promise.resolve(dispatch(fetchAddressFromZipCode(value)));
            if (responseAddress?.data?.Status === 200) {
                let address = responseAddress?.data?.Entity;
                if (address && address?.Country) {
                    let cloneAddressObj = { ...addressObj };
                    cloneAddressObj.state = address.Country === 'United States' ? USSates.filter((state) => state.StateName === address?.State)[0].StateCode : address?.State;;
                    cloneAddressObj.city = address.City || cloneAddressObj.city;
                    cloneAddressObj.countryId = countries.filter((country) => country.name === address?.Country)[0].id || cloneAddressObj.countryId;
                    setAddressObj(cloneAddressObj);
                }
            }
        }
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    {id === "0" ? "Create New Address" : "View/Update Address"}
                    <div className="top_right_button_container">
                        <button className="btn cus-seconday-bg-btn top-btn saveBtnDialog" disabled={loader} onClick={handleSaveAddress}>
                            {id === "0" ? "Create Address" : "Save Address"}
                        </button>
                        <button className="btn cus-primary-transparent-btn" onClick={() => navigate('/address-book')}>
                            Back
                        </button>
                    </div>
                </div>
                {loader && <LoaderSpinner />}

                <div className="cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className='incoming_wrapper main_content_table addressDetailWrapper'>
                            <div className='row addressForm'>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>Name</label>
                                    <input
                                        className={`form-control`}
                                        type={'text'}
                                        name="name"
                                        value={addressObj.name}
                                        onChange={handleValueUpdate}
                                    />
                                    <div className='error'>{error.name}</div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>Company</label>
                                    <input className='form-control' type={'text'} name="company" value={addressObj.company} onChange={handleValueUpdate} />
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>Address Type</label>
                                    <div className='typeAddress'>
                                        <div>
                                            <input
                                                inline
                                                label="Sender"
                                                name="isSender"
                                                id="isSender"
                                                checked={addressObj.isSender}
                                                type={'checkbox'}
                                                onChange={(e) => handleValueUpdate(e, true)}
                                            />
                                            <label htmlFor='isSender'>Sender</label>
                                        </div>

                                        <div className=''>
                                            <input
                                                inline
                                                label="Receiver"
                                                name="isReceiver"
                                                id="isReceiver"
                                                checked={addressObj.isReceiver}
                                                type={'checkbox'}
                                                onChange={(e) => handleValueUpdate(e, true)}
                                            />
                                            <label htmlFor='isReceiver'>Receiver</label>
                                        </div>
                                    </div>
                                    <div className='error'>{error?.isSender}</div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>Phone</label>
                                    <Input
                                        value={addressObj.phone}
                                        name="phone"
                                        onChange={(e) =>
                                            handleValueUpdate({ target: { value: e, name: 'phone' } })
                                        }
                                        className={`form-control phoneField`}
                                    />
                                    <div className='error'>{error?.phone}</div>
                                </div>
                            </div>
                            <div className='row addressForm'>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>Country</label>
                                    <select
                                        className={`form-control countrySelect`}
                                        onChange={(e) => {
                                            handleValueUpdate(e);
                                        }}
                                        value={addressObj.countryId}
                                        name={'countryId'}
                                    >
                                        {countries?.map((country) => {
                                            return (
                                                <option
                                                    value={country.id}
                                                    key={country.id}
                                                >
                                                    {country.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div className='error'>{error?.countryId}</div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>State</label>
                                    {parseInt(addressObj.countryId) === 218 ? (
                                        <select
                                            onChange={(e) => {
                                                handleValueUpdate(e);
                                            }}
                                            className={`form-control usStatesList`}
                                            value={addressObj.state}
                                            name="state"
                                        >
                                            <option value="0">Select</option>
                                            {USSates &&
                                                USSates.length > 0 &&
                                                USSates.map((state, index) => (
                                                    <option
                                                        key={state.StateCode}
                                                        value={state.StateCode}
                                                    >
                                                        {state.StateCode} {state.StateName}
                                                    </option>
                                                ))}
                                        </select>
                                    ) : (
                                        <input className='form-control' type={'text'} name="state" value={addressObj.state} onChange={handleValueUpdate} />
                                    )}
                                    <div className='error'>{error?.state}</div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>City</label>
                                    <input className={`form-control`} type={'text'} name="city" value={addressObj.city} onChange={handleValueUpdate} />
                                    <div className='error'>{error?.city}</div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>Zip</label>
                                    <input className={`form-control zipField`} type={'text'} name="zip" value={addressObj.zip} onChange={handleValueUpdate} onBlur={fetchAddressByZip} />
                                    <div className='error'>{error?.zip}</div>
                                </div>
                            </div>
                            <div className='row addressForm'>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>Address Line 1</label>
                                    <input className={`form-control`} type={'text'} name="addressLine1" value={addressObj.addressLine1} onChange={handleValueUpdate} />
                                    <div className='error'>{error?.addressLine1}</div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>Address Line 2</label>
                                    <input className={`form-control`} type={'text'} name="addressLine2" value={addressObj.addressLine2} onChange={handleValueUpdate} />
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <label>Address Line 3</label>
                                    <input className='form-control' type={'text'} name="addressLine3" value={addressObj.addressLine3} onChange={handleValueUpdate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default AddressDetail;