import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilterProductsList, productBackDetails } from "../../../store/actions/incoming";
import _ from "lodash";
import DatePickerComponent from "../../common/DatePickerComponent";
import Select from "react-select";
import { getSelectList } from "../../../config/utils";
import { fetchCompanyFilters, fetchSellerFilters } from "../../../store/actions/rateCard";
import moment from "moment";
import { getAllSellersList } from "../../../store/actions";

/* istanbul ignore next */
function useOutsideAlerter(ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilterValue({ ...filterValue, showFilter: false })
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const FilterProduct = (props) => {
  const { filterValue, setFilterValue, handleReset, setLoader, sellerList, setSellerList, allSellers, setAllSellers } = props;
  const UserValue = useSelector((state) => state.auth.user);
  const userDetails = useSelector((state) => state?.auth?.user);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const [warehouseTypeList, setwarehouseTypeList] = useState([]);
  const [conditionList, setconditionList] = useState([]);
  const { filterCompanies, filterSellers } = useSelector((state) => state.RateCardReducer);
  const [companyList, setCompanyList] = useState([]);

  useOutsideAlerter(wrapperRef);
  const productConditionListData = useSelector(
    (state) => state?.IncomingProductReducer?.productConditionList
  );
  const productWarehouseListData = useSelector(
    (state) => state?.IncomingProductReducer?.productWarehouseList
  );
  const productwarehouseListData = useSelector(
    (state) => state?.IncomingProductReducer?.productwarehouseList
  );
  useEffect(() => {
    let companyList = filterCompanies?.Entity?.listCompanyMasterOrdersEntity || [];
    let sellerList = filterSellers?.Entity?.listSellerMasterOrdersEntity || [];

    if (companyList.length) {
      setCompanyList(getSelectList(filterCompanies?.Entity?.listCompanyMasterOrdersEntity, 'Id', 'name'));
    }
    if (sellerList.length) {
      setAllSellers(getSelectList(sellerList, 'id', 'newSellerName'));
      setSellerList(getSelectList(sellerList, 'id', 'newSellerName'));
    }
  }, [filterCompanies, filterSellers]);

  useEffect(() => {
    let data = {
      pageIndex: 1,
      pageSize: 10,
      sortColumn: 'name',
      sortOrder: 'ascending',
      processingDateTo: '',
      processingDateFrom: ''
    };
    let sellerData = {
      screenType: 2,
    };
    dispatch(fetchCompanyFilters(data));
    dispatch(fetchSellerFilters(sellerData));
  }, []);

  useEffect(() => {
    let productConditionListCopyObj = JSON.parse(JSON.stringify(productConditionListData));
    let productWarehouseListObj = JSON.parse(JSON.stringify(productWarehouseListData));
    let productwarehouseListObj = JSON.parse(JSON.stringify(productwarehouseListData));
    setconditionList(getSelectList(productConditionListCopyObj, 'id', 'condition'));
    if (userDetails?.Value?.isSuperAdmin) {
      setwarehouseTypeList(getSelectList(productWarehouseListObj, 'id', 'location'));
    } else {
      setwarehouseTypeList(getSelectList(productwarehouseListObj, 'id', 'location'));
    }
  }, [productConditionListData, productwarehouseListData]);

  const handleChangeFilter = (value, name) => {
    setFilterValue({ ...filterValue, [name]: value });
    if (name === "companyIdList") {
      let companyData = _.map(value, "Id");
      fetchSellers(companyData);
      if (filterValue?.sellerIdList?.length) {
        const filteredSellers = filterValue.sellerIdList.filter(seller =>
          companyData.includes(parseInt(seller.companyIds))
        );
        setFilterValue({ ...filterValue, sellerIdList: filteredSellers, companyIdList: value })
      }
    }
  }

  const fetchSellers = async (companyList) => {
    if (companyList.length === 0) {
      setSellerList(allSellers);
      return;
    }
    let data = {
      companyIds: companyList,
    };
    let res = await getAllSellersList(data);
    if (res?.data?.Status === 200) {
      let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
        (item) => {
          return {
            ...item,
            label: item.sellerAndCompany,
            value: item.id,
          };
        }
      );
      setSellerList(dataList);
    }
  };

  const handleApplyFilter = () => {
    setLoader(true)
    let data = { ...props.filterValue, userId: UserValue?.Value?.employeeId };
    data.pageIndex = 1;
    data.sortColumn = '';
    data.warehouseList = _.map(filterValue.warehouseList, "id");
    data.conditionList = _.map(filterValue.conditionList, "id");
    data.sellerIdList = _.map(filterValue.sellerIdList, "value");
    data.companyIdList = _.map(filterValue.companyIdList, "Id");
    data.processingDateFrom = filterValue.processingDateFrom;
    data.processingDateTo = filterValue.processingDateTo;
    dispatch(productBackDetails(filterValue));
    setFilterValue({ ...filterValue, searchTxt: "", searchKeywords: "", pageIndex: 1, sortColumn: '' })
    dispatch(getFilterProductsList(data));
    setLoader(false)
  };

  const handleDateChange = (date, fieldName) => {
    let dateSelected = moment(date).utc(true).format();
    setFilterValue({ ...filterValue, [fieldName]: dateSelected });
  }

  return (
    <div className={filterValue.showFilter ? 'filterContainer show historyFilter' : 'filterContainer historyFilter'}>
      <div className='row mb10'>
        <div className='col-sm-12 col-lg-3 mobMb-10'>
          <div className='row'>
            <div className='col-12'>
              <label className='labelHead'>Warehouse</label>
            </div>
            <div className='col-12'>
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={warehouseTypeList}
                classNamePrefix="list"
                id="warehouseList"
                name="warehouseList"
                value={filterValue?.warehouseList}
                onChange={(value) =>
                  handleChangeFilter(value, "warehouseList")
                }
              />
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-lg-3 mobMb-10'>
          <div className='row carriers'>
            <div className='col-12'>
              <label className='labelHead'>Condition</label>
            </div>
            <div className='col-12'>
              <Select
                isMulti
                options={conditionList}
                classNamePrefix="allCarriers"
                name="conditionList"
                id="conditionList"
                value={filterValue?.conditionList}
                onChange={(value) =>
                  handleChangeFilter(value, "conditionList")
                }
              />
            </div>
          </div>
        </div>

        <div className='col-sm-12 col-lg-3 mobMb-10'>
          <div className='row carriers'>
            <div className='col-12'>
              <label className='labelHead'>Processing Date From</label>
            </div>
            <div className='col-12'>
              <DatePickerComponent
                fieldName={'processingDateFrom'}
                handleDateChange={handleDateChange}
                dateField={filterValue.processingDateFrom}
              />
            </div>
          </div>
        </div>

        <div className='col-sm-12 col-lg-3 mobMb-10'>
          <div className='row'>
            <div className='col-12'>
              <label className='labelHead'>Processing Date To</label>
            </div>
            <div className='col-12'>
              <DatePickerComponent
                fieldName={'processingDateTo'}
                handleDateChange={handleDateChange}
                dateField={filterValue?.processingDateTo}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row mb10'>
        {UserValue?.Value?.roleName !== 'Seller' && (
          <>
            <div className='col-sm-12 col-lg-3 mobMb-10'>
              <div className='row'>
                <div className='col-12'>
                  <label className='labelHead'>Company</label>
                </div>
                <div className='col-12'>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    options={companyList}
                    classNamePrefix="list"
                    id='companyIdList'
                    name="companyIdList"
                    value={filterValue?.companyIdList}
                    onChange={(value) =>
                      handleChangeFilter(value, "companyIdList")
                    }
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-lg-3'>
              <div className='row'>
                <div className='col-12'>
                  <label className='labelHead'>Seller</label>
                </div>
                <div className='col-12'>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    options={sellerList}
                    classNamePrefix="list"
                    id='sellerIdList'
                    name="sellerIdList"
                    value={filterValue?.sellerIdList}
                    onChange={(value) =>
                      handleChangeFilter(value, "sellerIdList")
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className='col-sm-12 col-lg-3'>
          <div className='row'>
            <div className='col-12'>
              <label className='labelHead'></label>
            </div>
            <div className='col-12 flexEnd'>
              <button
                className="btn cus-primary-transparent-btn top-btn"
                onClick={handleReset}
              >
                Clear Filter
              </button>
              <button
                className="cus-seconday-bg-btn btn filterBtn"
                onClick={handleApplyFilter}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default FilterProduct;