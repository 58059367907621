import React, { useEffect, useState } from 'react';
import { Table } from 'bootstrap-4-react/lib/components';
import LoaderSpinner from '../common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getScanFormList } from '../../store/actions/batch.action';
import { Tooltip as WmsTooltip } from "react-tooltip";
import BatchPagination from '../batch/BatchPagination';
import { numOfPage } from '../../constant';

const ScanForm = () => {
    const dispatch = useDispatch();
    const { scanFormList, scanFormListStatus } = useSelector((state) => state.batchReducer);
    const [numberOfPage, setNumberOfPage] = useState(30);
    const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        if (scanFormListStatus !== 'success') {
            setLoader(true);
        } else {
            setLoader(false);
        }
    }, [scanFormListStatus]);

    useEffect(() => {
        if (scanFormList.EntityList && scanFormList.EntityList.length) {
            setTotalPages(
                Math.ceil(scanFormList.TotalRecords / numberOfPage)
            );
        }
    }, [scanFormList]);

    useEffect(() => {

        fetchScanFormList();

    }, [currentPage, numberOfPage]);

    const fetchScanFormList = () => {
        let dataScan = {
            "pageIndex": currentPage,
            "pageSize": numberOfPage,
            "sortColumn": "",
            "sortOrder": "ASCENDING",

        }
        dispatch(getScanFormList(dataScan));
    }

    const noRecord = () => {
        return (
            <tr>
                <td colSpan={4} align='center'>No Record Found</td>
            </tr>
        )
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">Scan Forms</div>
                {scanFormListStatus === 'pending' && <LoaderSpinner />}

                <div className="cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className='incoming_wrapper main_content_table'>
                            <div className='tableFixHead'>
                                <Table className="table table-borderless keyGenerate">
                                    <thead>
                                        <tr className="trow">
                                            <th className="trows-s">#</th>
                                            <th className="trows-s" width={"25%"}>Tracking Number</th>
                                            <th className="trows-s" width={"30%"}>Creation Date</th>
                                            <th className="trows-s" width={"30%"}>Order Number</th>
                                            <th className="trows-s">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {scanFormListStatus === 'success' && scanFormList && scanFormList.EntityList.length > 0 && scanFormList.EntityList.map((item, index) => (
                                            <tr key={item.id}>
                                                <td className="trows-s">{index + 1}</td>
                                                <td className="trows-s">{item.trackingData.map((trackingData) => (
                                                    <div key={trackingData.trackingNumber}><a rel="noreferrer" href={trackingData.trackingUrl} target="_blank">{trackingData.trackingNumber}</a></div>
                                                ))}</td>
                                                <td className="trows-s">{item.createdDate}</td>
                                                <td>{item.trackingData.map((order) => (
                                                    <div key={order.orderNumber}>{order.orderNumber}</div>
                                                ))}</td>
                                                <td>
                                                    <a id={item.id} rel="noreferrer" target="_blank" href={item.formUrl}>
                                                        <i className="fa fa-download" aria-hidden="true"></i>
                                                    </a>
                                                    <WmsTooltip
                                                        anchorId={item.id}
                                                        place="bottom"
                                                        className="tooltipWidth"
                                                        content='Download Form'
                                                    ></WmsTooltip>
                                                </td>
                                            </tr>
                                        ))}

                                        {scanFormListStatus === 'success' && scanFormList.EntityList.length === 0 ? (
                                            noRecord()
                                        ) : (
                                            <></>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                {scanFormList.TotalRecords > 0 && (
                    <>
                        {scanFormList?.EntityList?.length && (
                            <BatchPagination
                                gridData={scanFormList.EntityList}
                                totalVisualRecords={scanFormList?.EntityList?.length}
                                numberOfPage={numberOfPage}
                                numOfPage={numOfPage}
                                loader={loader}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                totalRecords={scanFormList.TotalRecords}
                                setNumberOfPage={setNumberOfPage}
                            />
                        )}
                    </>
                )}
            </div>
        </main>
    );
}

export default ScanForm;