import React, { useEffect, useState } from 'react';
import { Button } from "bootstrap-4-react/lib/components";
import { useSelector } from 'react-redux';
import { getPaymentToken } from '../../../store/actions/fundManagement';
import { useLocation, useNavigate } from 'react-router-dom';
import { findIsHostShipAi } from '../../../config/utils';

const Recharge = () => {
    const location = useLocation();
    const userDetails = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const [agreeTnC, setAgreeTnC] = useState(false);
    const [data, setLocationData] = useState(false);
    useEffect(() => {
        if (!location?.state?.amount) {
            navigate('/fundManagement');
        } else {
            /* istanbul ignore next */
            setLocationData(location.state);
            navigate(location.pathname, { replace: true });
        }
    }, []);

    const recharge = async () => {
        const response = await getPaymentToken(data);
        localStorage.setItem('userRecharge', JSON.stringify({
            token: response?.data?.Entity?.Token,
            processingFees: data?.processingFees,
            amount: data?.amount,
            description: data?.description,
            shouldSendEmail: data?.shouldSendEmail,
            email: data?.email,
        }));
        if (localStorage.getItem("userRecharge")) {
            const url = "/Payment/paymentf.html";
            window.location.href = url;
        }
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    Transaction Summary
                    <div className="top_right_button_container"></div>
                </div>
                <div className="main_content_table incomingTbl summaryTable">
                    <div className=''>
                        <div className='row'>
                            <div className='col col-6'>Recharge Amount ($)</div>
                            <div className='col col-6'>{data?.amount}</div>
                        </div>
                        <div className='row'>
                            <div className='col col-6'>Processing Fees ($)</div>
                            <div className='col col-6'>{data?.commissionAmount}</div>
                        </div>
                        <div className='row'>
                            <div className='col col-6'>Total Amount ($)</div>
                            <div className='col col-6'>{data?.totalAmount}</div>
                        </div>
                        <div className='row'>
                            <div className='col col-6'>Seller Name</div>
                            <div className='col col-6'>{userDetails?.Value?.sellerName}</div>
                        </div>
                        <div className='row'>
                            <div className='col col-6'>Paid To</div>
                            <div className='col col-6'>{findIsHostShipAi() ? 'ShipVisionAI' : '3PLVision'}\CrossCultureMall</div>
                        </div>
                        <div className='row acceptTcBox'>
                            <div className='col-1'>
                                <input className='agreeTnC' type='checkbox' checked={agreeTnC} onChange={(e) => setAgreeTnC(e.target.checked)} />
                            </div>
                            <div className='col-11'>
                                <div className='mt10'>
                                    Please note that all payments made for {findIsHostShipAi() ? 'ShipVisionAI' : '3PLVision'} are non-refundable. Once a payment is processed, it is considered final, and no refunds will be issued, regardless of the circumstances. By proceeding with your purchase, you acknowledge and agree to this non-refundable policy.
                                    By clicking the checkbox, you acknowledge that you have read and agree to the terms and conditions of this service. Please review the details carefully before proceeding.
                                </div>
                            </div>
                        </div>

                        <div className='rechargeAction'>
                            <Button
                                className="cus-primary-transparent-btn top-btn"
                                onClick={() => navigate(-1)}
                            >Back</Button>

                            <button
                                className="btn cus-seconday-bg-btn top-btn"
                                onClick={() => recharge()}
                                disabled={!agreeTnC}
                            >Recharge</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Recharge;