import React from 'react';
import AddAddressModal from '../modal/AddAddressModal';
import { Button } from "bootstrap-4-react/lib/components";
import Select from "react-select";
import Input from "react-phone-number-input/input";
import AlertDialogModal from '../common/AlertDialog';

const AddressModalComponent = (props) => {
    const {
        addressModalShow,
        addresp,
        formData,
        setaddressModalShow,
        handleEditFromCompany,
        saveData,
        TopUpForm,
        errors,
        companyList,
        handleSelectChange,
        addressDataValue,
        handleDefaultAddress,
        handleDeleteAddress,
        countryList,
        handleChangeAddress,
        handleInputValueTypes,
        stateList,
        statusModalShow,
        handleCancelAlert,
        handleYesDeleteAlert,
        isDefault
    } = props;
    return (
        <div>
            {addressModalShow && (
                /* istanbul ignore next */
                <AddAddressModal
                    show={addressModalShow}
                    res={addresp}
                    SellerData={{
                        newSellerName: formData?.newSellerName,
                        companyIds: formData?.companyIds,
                        email: formData?.email,
                        steCode: formData?.steCode,
                        sellerCode: formData?.sellerCode,
                        sellerId: formData?.id,
                    }}
                    type="seller"
                    onHide={() => setaddressModalShow(false)}
                />
            )}
            <div className="container-fluid">
                <div className="cus-page-content-sec">
                    <div className="cus-tab-heading-sec">
                        <div className="tabs">
                            <div className="main_content_table">
                                <div className="main__tile_heading cus-btn-include-sec">
                                    Seller Details
                                    <div className="top_right_button_container">
                                        <>
                                            {props?.editMode ? (
                                                <>
                                                    <Button
                                                        className="cus-seconday-bg-btn top-btn"
                                                        disabled={props?.editMode}
                                                    >
                                                        Add Address
                                                    </Button>
                                                    <Button
                                                        className="cus-seconday-bg-btn top-btn"
                                                        onClick={
                                /* istanbul ignore next */ () =>
                                                                handleEditFromCompany()
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        className="cus-seconday-bg-btn top-btn"
                                                        onClick={() => setaddressModalShow(true)}
                                                    >
                                                        Add Address
                                                    </Button>
                                                    <Button
                                                        className="cus-seconday-bg-btn top-btn"
                                                        onClick={
                                /* istanbul ignore next */ () => saveData()
                                                        }
                                                    >
                                                        Save
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                    <span className="input-label">Basic Details:</span>
                                </div>
                                <div className="row modal_input Edit-Form">
                                    <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                        <span className="input-label">Seller Brand Name:</span>
                                    </div>
                                    <div className="col-lg-8 col-sm-12 mobMb-10">
                                        <div className={"select_react"}>
                                            <input
                                                value={formData?.newSellerName}
                                                onChange={(e) =>
                                                    TopUpForm(e.target.value, "newSellerName")
                                                }
                                                disabled={props?.editMode}
                                                className="form-control availableBalanceInput"
                                                type="text"
                                            />
                                            <p className="error dialogError">
                                                {errors.newSellerName}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row modal_input Edit-Form">
                                    <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                        <span className="input-label">Seller Code:</span>
                                    </div>
                                    <div className="col-lg-8 col-sm-12 mobMb-10">
                                        <div className={"select_react"}>
                                            <input
                                                value={formData?.sellerCode}
                                                onChange={(e) =>
                                                    TopUpForm(e.target.value, "sellerCode")
                                                }
                                                disabled={props?.editMode}
                                                className="form-control availableBalanceInput"
                                                type="text"
                                            />
                                            <p className="error dialogError">
                                                {errors.sellerCode}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row modal_input Edit-Form">
                                    <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                        <span className="input-label">Company:</span>
                                    </div>
                                    <div className="col-lg-8 col-sm-12 mobMb-10">
                                        <div className={"select_react"}>
                                            <Select
                                                isMulti
                                                options={companyList}
                                                onChange={handleSelectChange}
                                                className="activeSellerListInput"
                                                isDisabled={props?.editMode}
                                                value={formData?.companyIds}
                                                classNamePrefix="list"
                                            />
                                            <p className="error dialogError">
                                                {errors.companyIds}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row modal_input Edit-Form">
                                    <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                        <span className="input-label">Ste Code:</span>
                                    </div>
                                    <div className="col-lg-8 col-sm-12 mobMb-10">
                                        <div className={"select_react"}>
                                            <input
                                                value={formData?.steCode}
                                                onChange={(e) =>
                                                    TopUpForm(e.target.value, "steCode")
                                                }
                                                disabled={props?.editMode}
                                                className="form-control availableBalanceInput"
                                                type="text"
                                            />
                                            <p className="error dialogError">{errors.steCode}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row modal_input Edit-Form">
                                    <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                        <span className="input-label">Email Address:</span>
                                    </div>
                                    <div className="col-lg-8 col-sm-12 mobMb-10">
                                        <div className={"select_react"}>
                                            <input
                                                value={formData?.email}
                                                onChange={(e) => TopUpForm(e.target.value, "email")}
                                                className="form-control availableBalanceInput"
                                                type="text"
                                                disabled={props?.editMode}
                                            />
                                            <p className="error dialogError">{errors.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                    <span className="input-label">Addresses:</span>
                                </div>
                                {addressDataValue?.length > 0 &&
                                    addressDataValue?.map((d, index) => (
                                        <>
                                            {d?.id !== 0 && (
                                                <div className="edit-company-address">
                                                    {props?.editMode ? (
                                                        <div className="row modal_input Edit-Form-company">
                                                            <div className="col-4 formLabel Edit"></div>
                                                            <div className="col-lg-8 col-sm-12 mobMb-10 delete-company">
                                                                <span className="input-label col-4">
                                                                    {" "}
                                                                    <input
                                                                        type="radio"
                                                                        class
                                                                        id="html"
                                                                        checked={d?.isDefault}
                                                                        onChange={
                                        /* istanbul ignore next */ () =>
                                                                                handleDefaultAddress(d?.id)
                                                                        }
                                                                        name="fav_language"
                                                                        disabled={true}
                                                                        value="HTML"
                                                                    />
                                                                    {" "}
                                                                    <label
                                                                        className="default-add-label"
                                                                        for="html"
                                                                    >
                                                                        Default Address
                                                                    </label>
                                                                </span>

                                                                <span className="input-label col-4">
                                                                    {" "}
                                                                    <i
                                                                        className="fa fa-trash-o"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="row modal_input Edit-Form-company">
                                                            <div className="col-4 formLabel Edit"></div>
                                                            <div className="col-lg-8 col-sm-12 mobMb-10 delete-company">
                                                                <span className="input-label col-4">
                                                                    {" "}
                                                                    <input
                                                                        type="radio"
                                                                        class
                                                                        id="html"
                                                                        checked={d?.isDefault}
                                                                        onChange={
                                        /* istanbul ignore next */ () =>
                                                                                handleDefaultAddress(d?.id)
                                                                        }
                                                                        name="fav_language"
                                                                        disabled={false}
                                                                        value="HTML"
                                                                    />
                                                                    {" "}
                                                                    <label
                                                                        className="default-add-label"
                                                                        for="html"
                                                                    >
                                                                        Default Address
                                                                    </label>
                                                                </span>

                                                                <span
                                                                    className="input-label col-4 delete-btn"
                                                                    onClick={
                                                                        () =>
                                                                            handleDeleteAddress(d)
                                                                    }
                                                                >
                                                                    <i
                                                                        className="fa fa-trash-o"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="row modal_input Edit-Form-company">
                                                        <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                                            <span className="input-label">Country:</span>
                                                        </div>
                                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                                            <div className={"select_react"}>
                                                                <Select
                                                                    options={countryList}
                                                                    onChange={(value) =>
                                                                        handleChangeAddress(
                                                                            index,
                                                                            "country_Id",
                                                                            value.value
                                                                        )
                                                                    }
                                                                    key={d?.id}
                                                                    className="activeSellerListInput countrySelectBox"
                                                                    isDisabled={props?.editMode}
                                                                    defaultValue={{
                                                                        label: d?.country,
                                                                        value: d?.country_Id,
                                                                    }}
                                                                    classNamePrefix="list"
                                                                />
                                                                {errors[`addressModels_${index}`] &&
                                                                    /* istanbul ignore next */
                                                                    errors[`addressModels_${index}`]
                                                                        .country_Id && (
                                                                        <p className="error dalogError">
                                                                            {
                                                                                errors[`addressModels_${index}`]
                                                                                    .country_Id
                                                                            }
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row modal_input Edit-Form-company">
                                                        <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                                            <span className="input-label">Zip Code:</span>
                                                        </div>
                                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                                            <div className={"select_react"}>
                                                                <input
                                                                    value={d?.zipCode}
                                                                    onChange={(e) =>
                                                                        handleChangeAddress(
                                                                            index,
                                                                            "zipCode",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                        handleInputValueTypes(e, /^[0-9-]$/)
                                                                    }
                                                                    className="form-control availableBalanceInput zipCode"
                                                                    type="text"
                                                                    disabled={props?.editMode}
                                                                    pattern="\d{5}(-\d{4})?"
                                                                    title="Please enter a valid zip code format: xxxxx or xxxxx-xxxx"
                                                                />
                                                                {errors[`addressModels_${index}`] &&
                                                                    /* istanbul ignore next */
                                                                    errors[`addressModels_${index}`]
                                                                        .zipCode && (
                                                                        <p className="error dalogError">
                                                                            {
                                                                                errors[`addressModels_${index}`]
                                                                                    .zipCode
                                                                            }
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {d?.country_Id === 218 ? (
                                                        <div className="row modal_input Edit-Form-company">
                                                            <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                                                <span className="input-label">State:</span>
                                                            </div>
                                                            <div className="col-lg-8 col-sm-12 mobMb-10">
                                                                <Select
                                                                    options={stateList}
                                                                    onChange={(value) =>
                                                                        handleChangeAddress(
                                                                            index,
                                                                            "state",
                                                                            value.label
                                                                        )
                                                                    }
                                                                    className="activeSellerListInput state"
                                                                    isDisabled={props?.editMode}
                                                                    defaultValue={{
                                                                        label: d?.state,
                                                                        value: "",
                                                                    }}
                                                                    classNamePrefix="list"
                                                                />
                                                                {errors[`addressModels_${index}`] &&
                                                                    /* istanbul ignore next */
                                                                    errors[`addressModels_${index}`]
                                                                        .state && (
                                                                        <p className="error dalogError">
                                                                            {
                                                                                errors[`addressModels_${index}`]
                                                                                    .state
                                                                            }
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="row modal_input Edit-Form-company">
                                                            <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                                                <span className="input-label">State:</span>
                                                            </div>
                                                            <div className="col-lg-8 col-sm-12 mobMb-10">
                                                                <input
                                                                    value={d?.state}
                                                                    onChange={(e) =>
                                                                        handleChangeAddress(
                                                                            index,
                                                                            "state",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                        handleInputValueTypes(e, /^[A-Za-z]$/)
                                                                    }
                                                                    className="form-control availableBalanceInput state"
                                                                    disabled={props?.editMode}
                                                                    type="text"
                                                                />
                                                                {errors[`addressModels_${index}`] &&
                                                                    /* istanbul ignore next */
                                                                    errors[`addressModels_${index}`]
                                                                        .state && (
                                                                        <p className="error dalogError">
                                                                            {
                                                                                errors[`addressModels_${index}`]
                                                                                    .state
                                                                            }
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="row modal_input Edit-Form-company">
                                                        <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                                            <span className="input-label">City:</span>
                                                        </div>
                                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                                            <div className={"select_react"}>
                                                                <input
                                                                    value={d?.city}
                                                                    onChange={(e) =>
                                                                        handleChangeAddress(
                                                                            index,
                                                                            "city",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                        handleInputValueTypes(e, /^[A-Za-z]$/)
                                                                    }
                                                                    className="form-control availableBalanceInput city"
                                                                    disabled={props?.editMode}
                                                                    type="text"
                                                                />
                                                                {errors[`addressModels_${index}`] &&
                                                                    /* istanbul ignore next */
                                                                    errors[`addressModels_${index}`].city && (
                                                                        <p className="error dalogError">
                                                                            {
                                                                                errors[`addressModels_${index}`]
                                                                                    .city
                                                                            }
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row modal_input Edit-Form-company">
                                                        <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                                            <span className="input-label">
                                                                Phone Number:
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                                            <div className={"select_react"}>
                                                                <Input
                                                                    value={d?.phoneNumber}
                                                                    onChange={(e) => {
                                                                        handleChangeAddress(
                                                                            index,
                                                                            "phoneNumber",
                                                                            e);
                                                                    }}
                                                                    className="form-control availableBalanceInput phoneNumber"
                                                                    disabled={props?.editMode}
                                                                />
                                                                {errors[`addressModels_${index}`] &&
                                                                    /* istanbul ignore next */
                                                                    errors[`addressModels_${index}`]
                                                                        .phoneNumber && (
                                                                        <p className="error dalogError countrytype-error">
                                                                            {
                                                                                errors[`addressModels_${index}`]
                                                                                    .phoneNumber
                                                                            }
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row modal_input Edit-Form-company">
                                                        <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                                            <span className="input-label">
                                                                Address 1:
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                                            <div className={"select_react"}>
                                                                <input
                                                                    value={d?.addressFirst}
                                                                    onChange={(e) =>
                                                                        handleChangeAddress(
                                                                            index,
                                                                            "addressFirst",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                        handleInputValueTypes(
                                                                            e,
                                                                            /^[a-zA-Z0-9\s-]$/
                                                                        )
                                                                    }
                                                                    className="form-control availableBalanceInput addressFirst"
                                                                    disabled={props?.editMode}
                                                                    type="text"
                                                                />
                                                                {errors[`addressModels_${index}`] &&
                                                                    /* istanbul ignore next */
                                                                    errors[`addressModels_${index}`]
                                                                        .addressFirst && (
                                                                        <p className="error dalogError">
                                                                            {
                                                                                errors[`addressModels_${index}`]
                                                                                    .addressFirst
                                                                            }
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row modal_input Edit-Form-company">
                                                        <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                                            <span className="input-label">
                                                                Address 2:
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                                            <div className={"select_react"}>
                                                                <input
                                                                    value={d?.addressSecond}
                                                                    onChange={(e) =>
                                                                        handleChangeAddress(
                                                                            index,
                                                                            "addressSecond",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                        handleInputValueTypes(
                                                                            e,
                                                                            /^[a-zA-Z0-9\s-]$/
                                                                        )
                                                                    }
                                                                    className="form-control availableBalanceInput addressSecond"
                                                                    disabled={props?.editMode}
                                                                    type="text"
                                                                />
                                                                {errors[`addressModels_${index}`] &&
                                                                    /* istanbul ignore next */
                                                                    errors[`addressModels_${index}`]
                                                                        .addressSecond && (
                                                                        <p className="error dalogError">
                                                                            {
                                                                                errors[`addressModels_${index}`]
                                                                                    .addressSecond
                                                                            }
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row modal_input Edit-Form-company">
                                                        <div className="col-lg-4 col-sm-12 mobMb-10  mobMb-10 formLabel Edit">
                                                            <span className="input-label">
                                                                Address 3:
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                                            <div className={"select_react"}>
                                                                <input
                                                                    value={d?.addressThird}
                                                                    onChange={(e) =>
                                                                        handleChangeAddress(
                                                                            index,
                                                                            "addressThird",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                        handleInputValueTypes(
                                                                            e,
                                                                            /^[a-zA-Z0-9\s-]$/
                                                                        )
                                                                    }
                                                                    className="form-control availableBalanceInput addressThird"
                                                                    disabled={props?.editMode}
                                                                    type="text"
                                                                />
                                                                {errors[`addressModels_${index}`] &&
                                                                    /* istanbul ignore next */
                                                                    errors[`addressModels_${index}`]
                                                                        .addressThird && (
                                                                        <p className="error dalogError">
                                                                            {
                                                                                errors[`addressModels_${index}`]
                                                                                    .addressThird
                                                                            }
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ))}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <AlertDialogModal
                modalClass={"modalDeleteWrapper"}
                modalShow={statusModalShow}
                handleCancelDeleteAlert={
            /* istanbul ignore next */ handleCancelAlert
                }
                handleYesDeleteAlert={handleYesDeleteAlert}
                default={isDefault}
                messageText={isDefault ? `You cannot Delete the default Address of Seller .` : `Are you sure you want to delete this address?`}
                headingText={"Delete Address"}
            />
        </div>
    );
}

export default AddressModalComponent;