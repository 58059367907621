import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../fundManagement/fundmanagement.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCompanyDetails,
} from "../../store/actions/rateCard";
import { Button } from "bootstrap-4-react/lib/components";
import {
  getAllAssociatedUsers,
  getAllCountries,
  getSellersforMultipleCompany,
  getUserRole,
  updateUserDetails,
} from "../../store/actions";
import Input from 'react-phone-number-input/input'
import { checkSellersInCompanyList, filterFromList, getSelectList, getUserRoleName } from "../../config/utils";
import _ from "lodash";

export function EditUserManagement({ userData, type, editMode, seteditMode, setAssociatedUser, currentPage, numberOfPage, setTotalPages, filterValue, sellerId, companyId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const topUpFormActiveRef = useRef(false);
  const { getAllCompany } = useSelector((state) => state.RateCardReducer);
  const [defaultSellerList, setDefaultSellerList] = useState([]);
  const [errors, setErrors] = useState({});
  const [sellerList, setSellerList] = useState([]);
  const [roleList, setRoleList] = useState();
  const [companyList, setCompanyList] = useState([]);
  const { countries } = useSelector((state) => state?.shipment);
  const UserValue = useSelector((state) => state.auth.user);
  const getSellersValue = useSelector(
    (state) => state?.company?.getMultipleCompanySellers
  );

  const editValue = location?.state?.editable;
  const [formData, setFormData] = useState({});
  const [rowData, setrowData] = useState(location?.state?.userdata || userData);
  const [sellerCompanyError, setSellerCompanyError] = useState('');

  useEffect(() => {
    if (!countries.length) {
      dispatch(getAllCountries());
    }
  }, [countries]);

  useEffect(() => {
    /* istanbul ignore next */
    if (!topUpFormActiveRef.current && companyList.length && sellerList && rowData) {
      setFormData({
        ...formData,
        name: rowData?.name,
        newUserName: rowData?.newUserName,
        phoneNumber: rowData?.phoneNumber,
        userEmailId: rowData?.userEmailId,
        id: rowData?.id,
        createdDate: new Date(),
        modifiedDate: new Date(),
        country_Id: rowData?.country_Id,
        isActive: true,
        userRole:
          (roleList?.find(
            (record) => record.id === rowData?.userRole || rowData?.role
          )) || 0,
        UserCompanyIdList:
          (companyList?.filter((record) => rowData?.UserCompanyIdList.includes(record.Id))),
        UserSellerIdList:
          (sellerList?.filter((record) => rowData?.UserSellerIdList.includes(record.id))),
        defaultCompanyId: (companyList?.filter((record) => record.Id === rowData?.defaultCompanyId)[0]),
        defaultSellerId: (sellerList?.filter((record) => record.id === rowData?.defaultSellerId)[0]),
      });
    }
  }, [companyList, rowData, roleList]);

  useEffect(() => {
    /* istanbul ignore next */
    if (
      getAllCompany?.Entity &&
      getAllCompany?.Entity?.listCompanyMasterOrdersEntity.length
    ) {
      let data = getAllCompany?.Entity?.listCompanyMasterOrdersEntity.map(
        (item) => {
          return {
            ...item,
            value: item.Id,
            label: item.name,
          };
        }
      );
      setCompanyList(data);
    }
  }, [getAllCompany]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {

    dispatch(getAllCompanyDetails(null, 500, 1));
    const roleData = await getUserRole();
    /* istanbul ignore next */
    if (roleData?.data?.EntityList && roleData?.data?.EntityList?.length) {
      let data = roleData?.data?.EntityList?.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.name,
        };
      });
      setRoleList(data);
    }
  }, [UserValue]);
  useEffect(() => {
    /*istanbul ignore next*/
    if (getSellersValue && getSellersValue?.Entity?.listSellerMasterOrdersEntity?.length) {
      let sellerListData = [...getSellersValue?.Entity?.listSellerMasterOrdersEntity];
      let prepareListData = getSelectList(sellerListData, "id", "sellerAndCompany")
      setSellerList(prepareListData);
      const result = formData?.UserSellerIdList?.filter(c => sellerListData.some(s => s.id === c.id));
      const filterDefaultSeller = formData.defaultCompanyId && formData.UserSellerIdList?.filter(seller => Number(seller.companyIds) === Number(formData.defaultCompanyId.Id));
      setDefaultSellerList(filterDefaultSeller);
      setFormData({ ...formData, UserSellerIdList: result });
    } else {
      setSellerList([]);
      setFormData({ ...formData, UserSellerIdList: [] });
    }
  }, [getSellersValue, formData.defaultCompanyId, formData.userCompanyIdList]);

  useEffect(() => {
    if (rowData?.UserCompanyIdList?.length) {
      dispatch(getSellersforMultipleCompany(rowData?.UserCompanyIdList));
    }
  }, [rowData?.UserCompanyIdList]);

  const handleCompanyChange = (item, isArray) => {
    setrowData(null);
    dispatch(getSellersforMultipleCompany(_.map(isArray ? item : [item], 'Id')));
    if (formData.defaultCompanyId) {
      const result = item.filter(c => formData.defaultCompanyId.value === c.value);
      if (result.length) {
        setFormData({ ...formData, UserCompanyIdList: isArray ? item : [item] });
      } else {
        setFormData({ ...formData, UserCompanyIdList: isArray ? item : [item], defaultCompanyId: null, defaultSellerId: null });
      }
    } else {
      setFormData({ ...formData, UserCompanyIdList: isArray ? item : [item] });
    }
  }
  /*istanbul ignore next*/
  const handleUserRoleChange = (item, name) => {
    if (item?.id === 3) {
      setFormData({ ...formData, [name]: item, UserSellerIdList: [], UserCompanyIdList: [], defaultCompanyId: null, defaultSellerId: null });
    } else {
      setFormData({ ...formData, [name]: item });
    }
  }

  /*istanbul ignore next*/
  const TopUpForm = (item, name) => {
    topUpFormActiveRef.current = true;
    let isArray = Array.isArray(item);
    if (name === "UserCompanyIdList") {
      handleCompanyChange(item, isArray);
    } else if (name === 'country_Id') {
      let getSelectedCountry = filterFromList(countries, item)[0];
      setFormData({ ...formData, [name]: item, phoneNumber: `+ ${getSelectedCountry.phoneCountryCode}` });
    } else if (name === "UserSellerIdList") {
      setFormData({ ...formData, [name]: isArray ? item : [item] });
      setSellerCompanyError('');
    } else if (name === 'defaultCompanyId') {
      setFormData({ ...formData, [name]: item, defaultSellerId: null });
    } else if (name === 'userRole') {
      handleUserRoleChange(item, name);
    } else {
      setFormData({ ...formData, [name]: item });
    }
    setErrors({
      ...errors,
      [name]: "",
    });
    topUpFormActiveRef.current = false;
  };

  useEffect(() => {
    if (formData.country_Id && countries.length) {
      let getSelectedCountry = filterFromList(countries, formData.country_Id)[0];
      setFormData({ ...formData, phoneNumber: `+ ${getSelectedCountry.phoneCountryCode}` })
    }
  }, []);

  const handleEditable = (e) => {
    navigate(`/editUser/user-${rowData?.id}`, {
      state: {
        data: rowData,
        editable: false,
      },
    });
  };

  const handleOnSave = () => {
    setAssociatedUser(false);
    dispatch(
      getAllAssociatedUsers(
        setTotalPages,
        numberOfPage,
        currentPage,
        filterValue,
        companyId,
        sellerId
      )
    );
  }

  const saveData = (i) => {
    const requiredField = ["name", "newUserName", "userEmailId", "phoneNumber"];
    let isValid = true;
    const newErrors = {};

    // Email validation regular expression
    const emailRegexValue = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    requiredField.forEach((field) => {
      if (!formData[field]) {
        isValid = false;
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required`;
      }
    });

    if (formData.userEmailId && !emailRegexValue.test(formData.userEmailId)) {
      isValid = false;
      newErrors.userEmailId = "Invalid email address";
    }

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "This is required";
    }

    if (!formData.UserCompanyIdList?.length) {
      isValid = false;
      newErrors.UserCompanyIdList = "This is required";
    }
    if (!formData.UserSellerIdList?.length) {
      isValid = false;
      newErrors.UserSellerIdList = "This is required";
    }

    if (!formData.userRole) {
      isValid = false;
      newErrors.userRole = "This is required";
    }

    if (!formData.defaultCompanyId) {
      isValid = false;
      newErrors.defaultCompanyId = "This is required";
    }

    if (!formData.defaultSellerId) {
      isValid = false;
      newErrors.defaultSellerId = "This is required";
    }

    if (!checkSellersInCompanyList(formData.UserCompanyIdList, formData.UserSellerIdList)) {
      setSellerCompanyError("Select atleast one seller for every company");
      isValid = false;
    } else {
      setSellerCompanyError("");
    }

    if (isValid) {
      dispatch(
        updateUserDetails(
          formData,
          navigate,
          handleOnSave,
          type,
          setTotalPages,
          numberOfPage,
          currentPage,
          filterValue,
        )
      );
    } else {
      setErrors({ ...errors, ...newErrors });
    }
  };

  return (
    <>
      {type ? (
        <div>
          <div className="main__container">
            <div className="container-fluid">
              <div className="cus-page-content-sec">
                <div className="cus-tab-heading-sec">
                  <div className="tabs">
                    <div className="main_content_table">
                      <div className="main__tile_heading cus-btn-include-sec pt-3">
                        User Details
                        <div className="top_right_button_container">
                          <>
                            {editMode ? (
                              <>
                                <Button
                                  className="cus-seconday-bg-btn top-btn"
                                  onClick={() => handleEditable()}
                                >
                                  Edit
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  className="cus-seconday-bg-btn top-btn"
                                  onClick={() => saveData()}
                                >
                                  Save
                                </Button>
                              </>
                            )}
                          </>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                        <span className="input-label">Basic Details:</span>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Full Name:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <input
                              value={formData?.name}
                              onChange={(e) =>
                                TopUpForm(e.target.value, "name")
                              }
                              disabled={editMode}
                              className="form-control availableBalanceInput"
                              type="text"
                            />
                            <p className="error dialogError fntError">{errors.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">User Name:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <input
                              value={formData?.newUserName}
                              onChange={(e) =>
                                TopUpForm(e.target.value, "newUserName")
                              }
                              disabled={editMode}
                              className="form-control availableBalanceInput"
                              type="text"
                            />
                            <p className="error dialogError fntError">
                              {errors.newUserName}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Role:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Select
                              options={roleList}
                              onChange={(value) => TopUpForm(value, "userRole")}
                              className="activeSellerListInput"
                              isDisabled={editMode}
                              value={formData?.userRole}
                              classNamePrefix="list"
                            />
                            <p className="error dialogError fntError">{errors.userRole}</p>
                          </div>
                        </div>
                      </div>

                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Company:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Select
                              options={companyList}
                              onChange={(d) => TopUpForm(d, "UserCompanyIdList")}
                              className="activeSellerListInput"
                              isDisabled={editMode}
                              value={formData?.UserCompanyIdList}
                              classNamePrefix="list"
                              placeholder="Select Company..."
                              isMulti={!getUserRoleName(roleList, 'Super Admin', formData?.userRole)}
                              closeMenuOnSelect={false}
                            />
                            <p className="error dialogError fntError">
                              {errors.UserCompanyIdList}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Seller:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Select
                              options={sellerList}
                              onChange={(value) => TopUpForm(value, "UserSellerIdList")}
                              className="activeSellerListInput"
                              isDisabled={editMode}
                              value={formData?.UserSellerIdList}
                              classNamePrefix="list-value"
                              placeholder="Select Seller..."
                              isMulti={!getUserRoleName(roleList, 'Super Admin', formData?.userRole)}
                              closeMenuOnSelect={false}
                            />

                            <p className="error dialogError fntError">{errors.UserSellerIdList}</p>
                            <p className="error dialogError fntError"> {sellerCompanyError}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Default Company:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Select
                              key={122}
                              options={formData?.UserCompanyIdList}
                              onChange={(value) => TopUpForm(value, "defaultCompanyId")}
                              className="activeSellerListInput"
                              isDisabled={editMode}
                              value={formData?.defaultCompanyId}
                              classNamePrefix="list-value"
                              isMulti={false}
                              placeholder="Select Default Company..."
                            />

                            <p className="error dialogError fntError">
                              {errors.defaultCompanyId}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Default Seller:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Select
                              options={defaultSellerList}
                              onChange={(value) => TopUpForm(value, "defaultSellerId")}
                              className="activeSellerListInput"
                              isDisabled={editMode}
                              value={formData?.defaultSellerId}
                              classNamePrefix="list-value"
                              isMulti={false}
                              placeholder="Select Default Seller..."
                            />

                            <p className="error dialogError fntError">
                              {errors.defaultSellerId}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Email Address:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <input
                              value={formData?.userEmailId}
                              onChange={(e) =>
                                TopUpForm(e.target.value, "userEmailId")
                              }
                              className="form-control availableBalanceInput"
                              type="text"
                              disabled={editMode}
                            />
                            <p className="error dialogError fntError">
                              {errors.userEmailId}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Country:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <select
                              value={formData.country_Id}
                              disabled={editMode}
                              className="form-control"
                              onChange={(e) => TopUpForm(Number(e.target.value), "country_Id")}
                            >
                              {countries.map((country) => (
                                <option key={country.id} value={country.id}>{country.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Phone Number:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Input
                              value={formData?.phoneNumber}
                              name="phoneNumber"
                              onChange={(e) =>
                                TopUpForm(e, "phoneNumber")
                              }
                              className={"form-control availableBalanceInput"}
                              disabled={editMode}
                            />
                            <p className="error dialogError fntError">
                              {errors.phoneNumber}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <main>
          <div className="main__container">
            <div className="container-fluid">
              <div className="cus-page-content-sec">
                <div className="cus-tab-heading-sec">
                  <div className="tabs">
                    <div className="main_content_table">
                      <div className="main__tile_heading cus-btn-include-sec pt-3">
                        User Details
                        <div className="top_right_button_container">
                          <>
                            {editValue ? (
                              <>
                                <Button
                                  className="cus-seconday-bg-btn top-btn"
                                  onClick={() => handleEditable()}
                                >
                                  Edit
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  className="cus-seconday-bg-btn top-btn"
                                  onClick={() => saveData()}
                                >
                                  Save
                                </Button>
                              </>
                            )}
                            <Button
                              className="cus-primary-transparent-btn "
                              onClick={() => {
                                navigate("/userManagement");
                              }}
                            >
                              Back
                            </Button>
                          </>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                        <span className="input-label">Basic Details:</span>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Full Name:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <input
                              value={formData?.name}
                              onChange={(e) =>
                                TopUpForm(e.target.value, "name")
                              }
                              disabled={editValue}
                              className="form-control availableBalanceInput"
                              type="text"
                            />
                            <p className="error dialogError fntError">{errors.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">User Name:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <input
                              value={formData?.newUserName}
                              onChange={(e) =>
                                TopUpForm(e.target.value, "newUserName")
                              }
                              disabled={editValue}
                              className="form-control availableBalanceInput"
                              type="text"
                            />
                            <p className="error dialogError fntError">
                              {errors.newUserName}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Role:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Select
                              options={roleList}
                              onChange={(value) => TopUpForm(value, "userRole")}
                              className="activeSellerListInput"
                              isDisabled={editValue}
                              value={formData?.userRole}
                              classNamePrefix="list"
                            />
                            <p className="error dialogError fntError">{errors.userRole}</p>
                          </div>
                        </div>
                      </div>

                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Company:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Select
                              options={companyList}
                              onChange={(d) => TopUpForm(d, "UserCompanyIdList")}
                              className="activeSellerListInput"
                              isDisabled={editValue}
                              value={formData?.UserCompanyIdList}
                              classNamePrefix="list"
                              placeholder="Select Company..."
                              isMulti={!getUserRoleName(roleList, 'Super Admin', formData?.userRole)}
                              closeMenuOnSelect={false}
                            />
                            <p className="error dialogError fntError">
                              {errors.UserCompanyIdList}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Seller:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Select
                              options={sellerList}
                              onChange={(value) => TopUpForm(value, "UserSellerIdList")}
                              className="activeSellerListInput"
                              isDisabled={editValue}
                              value={formData?.UserSellerIdList}
                              classNamePrefix="list-value"
                              placeholder="Select Seller..."
                              isMulti={!getUserRoleName(roleList, 'Super Admin', formData?.userRole)}
                              closeMenuOnSelect={false}
                            />

                            <p className="error dialogError fntError">{errors.UserSellerIdList}</p>
                            <p className="error dialogError fntError"> {sellerCompanyError}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Default Company:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Select
                              key={122}
                              options={formData?.UserCompanyIdList}
                              onChange={(value) => TopUpForm(value, "defaultCompanyId")}
                              className="activeSellerListInput"
                              isDisabled={editValue}
                              value={formData?.defaultCompanyId}
                              classNamePrefix="list-value"
                              isMulti={false}
                              placeholder="Select Default Company..."
                            />

                            <p className="error dialogError fntError">
                              {errors.defaultCompanyId}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Default Seller:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Select
                              options={defaultSellerList}
                              onChange={(value) => TopUpForm(value, "defaultSellerId")}
                              className="activeSellerListInput"
                              isDisabled={editValue}
                              value={formData?.defaultSellerId}
                              classNamePrefix="list-value"
                              isMulti={false}
                              placeholder="Select Default Seller..."
                            />

                            <p className="error dialogError fntError">
                              {errors.defaultSellerId}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Email Address:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <input
                              value={formData?.userEmailId}
                              onChange={(e) =>
                                TopUpForm(e.target.value, "userEmailId")
                              }
                              className="form-control availableBalanceInput"
                              type="text"
                              disabled={editValue}
                            />
                            <p className="error dialogError fntError">
                              {errors.userEmailId}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Country:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <select
                              value={formData.country_Id}
                              disabled={editValue}
                              className="form-control"
                              onChange={(e) => TopUpForm(Number(e.target.value), "country_Id")}
                            >
                              {countries.map((country) => (
                                <option key={country.id} value={country.id}>{country.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row modal_input Edit-Form">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                          <span className="input-label">Phone Number:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12 mobMb-10">
                          <div className={"select_react"}>
                            <Input
                              value={formData?.phoneNumber}
                              name="phoneNumber"
                              onChange={(e) =>
                                TopUpForm(e, "phoneNumber")
                              }
                              className={"form-control availableBalanceInput"}
                              disabled={editValue}
                            />
                            <p className="error dialogError fntError">
                              {errors.phoneNumber}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}
