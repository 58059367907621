import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Table } from "bootstrap-4-react/lib/components";
import { Tooltip as WmsTooltip } from "react-tooltip";
import { getAllSellerCarriers, sellerCarrierBackDetails } from "../../store/actions";
import edit_icon from "../../assets/edit_icon.svg";
import eye_icon from "../../assets/eye_icon.svg";
import { featureSubmodulePermission, numOfSellerToDisplay, permissionSellerCarrierEdit, permissionSellerCarrierView, settings, SUBF_SERVICECONFIGIURATION } from "../../constant";
import CompanyCarrierDialog from "../common/CompanyCarrierDialog";
import { getAllCompanyDetails, getFilterSellers } from "../../store/actions/rateCard";
import {
  getAllSellersList
} from "../../store/actions/company";
import { checkPermission, getSelectList } from "../../config/utils";
import Select from "react-select";
import _ from "lodash";

const CompanyCarrierList = (props) => {
  const { userDetails } = props;
  const { sellerCarrierprevState } = useSelector((state) => state.MarginReducer);
  let dataLoad = {
    sortColumn: '',
    sortOrder: 'DESCENDING',
    sellerIdList: [],
    companyIdList: [],
  }
  const [filterData, setfilterData] = useState({});
  const [carrierList, setCarrierList] = useState([]);
  const [CompanyCarrierModalShow, setCompanyCarrierModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const sellerCarriers = useSelector(
    (state) => state.shipment.sellerCarrierList
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const { getAllCompany, filterAllSellers } = useSelector((state) => state.RateCardReducer);
  const [allSellers, setAllSellers] = useState([]);
  const UserValue = useSelector((state) => state.auth.user);

  useEffect(() => {
    let companyList = getAllCompany?.Entity?.listCompanyMasterOrdersEntity || [];
    let sellerList = filterAllSellers?.EntityList || [];
    if (companyList.length) {
      if (userDetails?.Value?.isSuperAdmin === true) {
        setCompanyList(getSelectList(getAllCompany?.Entity?.listCompanyMasterOrdersEntity, 'Id', 'name'));
      } else {
        let filterCompany = companyList.filter((company) => company.name.toLowerCase() === userDetails?.Value?.companyName.toLowerCase());
        setCompanyList(getSelectList(filterCompany, 'Id', 'name'));

      }
    }
    if (sellerList.length) {
      setAllSellers(getSelectList(sellerList, 'id', 'sellerName'));
      setSellerList(getSelectList(sellerList, 'id', 'sellerName'));
    }
  }, [getAllCompany, filterAllSellers]);

  useEffect(() => {
    if (!_.isEmpty(sellerCarrierprevState)) {
      setfilterData({ ...filterData, ...sellerCarrierprevState });
    } else {
      setfilterData(dataLoad);
    }
  }, []);

  useEffect(() => {
    let sellerData = {
      "pageIndex": 1,
      "pageSize": 10,
      "sortColumn": "name",
      "sortOrder": "ASCENDING",
      "companyIdList": [0],
      screenType: 2
    };
    dispatch(getAllCompanyDetails(null, 500, 1));
    dispatch(getFilterSellers(sellerData));
  }, [userDetails]);

  const fetchSellers = async (companyList) => {
    if (companyList.length === 0) {
      setSellerList(allSellers);
      return;
    }
    let data = {
      companyIds: companyList,
    };
    let res = await getAllSellersList(data);
    if (res?.data?.Status === 200) {
      let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
        (item) => {
          return {
            ...item,
            label: item.sellerAndCompany,
            value: item.id,
          };
        }
      );
      setSellerList(dataList);
    }
  };

  const handleMultiSelect = (value, fieldName) => {
    setfilterData({
      ...filterData,
      [fieldName]: value
    });
    if (fieldName === "companyIdList") {
      let companyData = _.map(value, "Id");
      fetchSellers(companyData);
      if (filterData?.sellerIdList?.length) {
        const filteredSellers = filterData.sellerIdList.filter(seller =>
          companyData.includes(parseInt(seller.companyIds))
        );
        setfilterData({ ...filterData, sellerIdList: filteredSellers, companyIdList: value })
      }
    }
  }

  const handleResetFilter = async () => {
    setfilterData(dataLoad)
    setSellerList([]);
    let data = {
      sortColumn: '',
      sortOrder: 'DESCENDING',
      sellerIdList: [],
      companyIdList: []
    }
    dispatch(getAllSellerCarriers(data));
    setSellerList(allSellers);
  };

  useEffect(() => {
    if (sellerCarriers.length) {
      const myItemsWithIds = sellerCarriers.map((item, index) => ({ ...item, id: index }));
      setCarrierList(myItemsWithIds);
    }
  }, [sellerCarriers]);

  useEffect(() => {
    if (!_.isEmpty(filterData)) {
      fetchAllCarrierServices();
    }
  }, [userDetails, filterData.sortOrder, filterData.sortingColumn]);


  /* istanbul ignore next */
  const handleSortingCoulmn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setfilterData({
      ...filterData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return filterData.sortColumn === columnName && filterData.sortOrder === "ASCENDING"
        ? "fa fa-arrow-up"
        : "fa fa-arrow-down";
    }
    return filterData.sortColumn === columnName ? "active" : "sortColumn";
  };

  const handleViewEdit = (e, company, action) => {
    saveFilterSellerCarrierDetails()
    e.preventDefault();
    if (action === "view") {
      let id = company.carrierCompanyId;
      navigate(`${id}`, {
        state: {
          company: company,
          editable: false,
        },
      });
    } else if (action === "sellernames") {
      setSelectedItem({ carrierCompanyName: company.carrierCompanyName, sellerNames: company.sellerNames });
      setCompanyCarrierModalShow(true);
    } else {
      let id = company.carrierCompanyId;
      navigate(`${id}`, {
        state: {
          company: company,
          editable: true,
        },
      });
    }
  };


  const fetchAllCarrierServices = () => {
    let data = {
      ...filterData,
      companyIdList: _.map(filterData.companyIdList, 'Id'),
      sellerIdList: _.map(filterData.sellerIdList, 'id'),
    }
    dispatch(getAllSellerCarriers(data))
  }

  const handleFilter = () => {
    fetchAllCarrierServices();
  }

  const saveFilterSellerCarrierDetails = () => {
    dispatch(sellerCarrierBackDetails(filterData))
  }

  return (
    <div className="incoming_wrapper main_content_table incomingTbl">
      <div>
        {UserValue?.Value?.roleName !== 'Seller' && (
          <>
            <div className="row mb10 pt-3 zIndex2">
              <div className="col-lg-4 col-sm-12 mobMb-10">
                <div className="row">
                  <div className="col-12">
                    <label className="labelHead">Company Name</label>
                  </div>
                  <div className="col-12 fieldAndLabel">
                    <div className={"select_react carrierListSelect"}>
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={companyList}
                        value={filterData.companyIdList}
                        onChange={(e) => handleMultiSelect(e, "companyIdList")}
                        className="activeSellerListInput"
                        classNamePrefix="list"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <label className="labelHead">Seller Name</label>
                  </div>
                  <div className="col-12 fieldAndLabel">
                    <div className={"select_react carrierListSelect"}>
                      <Select
                        isMulti
                        options={sellerList}
                        value={filterData.sellerIdList}
                        onChange={(e) => handleMultiSelect(e, "sellerIdList")}
                        className="activeSellerListInput"
                        classNamePrefix="list-seller"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-12">
                <div className="col-12">
                  <label
                    className="labelHead"
                    style={{ marginBottom: "26px" }}
                  ></label>
                </div>
                <button
                  className="btn cus-primary-transparent-btn top-btn"
                  onClick={handleResetFilter}
                  disabled={filterData.companyIdList?.length === 0 ? true : false}
                >Clear Filter</button>
                <button
                  className="btn cus-seconday-bg-btn top-btn filterButton"
                  onClick={handleFilter}
                >Apply Filter</button>
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        <div className="tableFixHead zIndex1">
          <Table className="table table-borderless keyGenerate">
            <thead>
              <tr className="trow">
                <th className="trows-s" width={"20%"}>
                  <div
                    className={handleSortingClass("CarrierCompanyName")}
                    onClick={(e) =>
                      handleSortingCoulmn(e, "CarrierCompanyName")
                    }
                    id="CarrierCompanyName"
                  >
                    Carrier
                    <i
                      className={handleSortingClass("CarrierCompanyName", true)}
                      aria-hidden="true"
                    ></i>
                  </div>
                </th>

                <th className="trows-s" width={"30%"}>
                  <div
                    className={handleSortingClass("Name")}
                    onClick={(e) =>
                      handleSortingCoulmn(e, "Name")
                    }
                    id="Name"
                  >
                    Associate Company
                    <i
                      className={handleSortingClass("Name", true)}
                      aria-hidden="true"
                    ></i>
                  </div>
                </th>

                <th className="trows-s" width={"40%"}>
                  Sellers
                </th>
                <th className="trows-s" width={"10%"}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {carrierList &&
                carrierList.length > 0 &&
                carrierList.map((item, sellerIndex) => (
                  <tr key={`${item.id}`}>
                    <td className="trows-s">{item.carrierCompanyName}</td>
                    <td className="trows-s">{item.companyName}</td>
                    <td className="trows-s">
                      <div className="chipContainer">
                        {item.sellerNames.length > 0 &&
                          item.sellerNames.map((seller, index) => (
                            <>
                              {index < numOfSellerToDisplay && (
                                <span className="sellerChip" key={seller}>{seller}</span>
                              )}
                            </>
                          ))}
                        {item.sellerNames.length > numOfSellerToDisplay && (
                          <>
                            <button
                              id={`${item.companyId}-${sellerIndex}`}
                              className="sellerChip showAddition"
                              onClick={(e) => handleViewEdit(e, item, "sellernames")}
                            >
                              <span>
                                + {item.sellerNames.length - numOfSellerToDisplay}
                              </span>
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      <>
                        {checkPermission(settings, SUBF_SERVICECONFIGIURATION, permissionSellerCarrierView, featureSubmodulePermission, props.employeePermissions) && (
                          <img
                            className="View-Icon"
                            src={eye_icon}
                            alt="View"
                            onClick={(e) => handleViewEdit(e, item, "view")}
                            id={`${item.carrierCompanyId}-view`}
                          />
                        )}
                        {checkPermission(settings, SUBF_SERVICECONFIGIURATION, permissionSellerCarrierEdit, featureSubmodulePermission, props.employeePermissions) && (
                          <img
                            className="listActionIcon"
                            src={edit_icon}
                            alt="Edit"
                            onClick={(e) => handleViewEdit(e, item, "edit")}
                            id={`${item.carrierCompanyId}-edit`}
                          />
                        )}
                        <WmsTooltip
                          anchorId={`${item.id}-view`}
                          content="View"
                          place="right"
                          className="tooltipWidth"
                        />
                        <WmsTooltip
                          anchorId={`${item.id}-edit`}
                          content="Edit"
                          place="right"
                          className="tooltipWidth"
                        />
                      </>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      {CompanyCarrierModalShow && (
        <CompanyCarrierDialog
          Item={selectedItem}
          modalShow={CompanyCarrierModalShow}
          setModalShow={setCompanyCarrierModalShow}
        />
      )}
    </div>
  );
};

export default CompanyCarrierList;
