import axios from "axios";
import { apiUrl } from "../constant";

const createBatchService = (data) => {
  return axios.post(`${apiUrl}/CreateUpdateOrderBatch`, data);
};
const getOrderBatchService = (data) => {
  return axios.post(`${apiUrl}/GetOrderBatch`, data);
};
const getAllBatchesService = (data) => {
  return axios.post(`${apiUrl}/GetOrderBatchDetails`, data);
};
const getAllBatchPendingOrderService = (data) => {
  return axios.post(`${apiUrl}/api/batchpendingorders`, data);
};
const bulkCreateUpdateShipmentService = (data) => {
  return axios.post(`${apiUrl}/api/Shipment/BulkCreateUpdateShipment`, data);
};
const updateOrderStatusService = (data) => {
  return axios.post(`${apiUrl}/api/UpdateOrderStatus`, data);
};
const getAllcarriersService = () => {
  return axios.post(apiUrl + "/api/Carrier/GetAllCarrierCompany", {});
};
const saveRateCardService = (data) => {
  return axios.post(`${apiUrl}/api/CreateBaseRateCard`, data);
};
const getMergedPdfService = (data) => {
  return axios.post(`${apiUrl}/api/mergePdf`, data);
};
const saveRateService = (data) => {
  return axios.post(`${apiUrl}/api/UpdateSellerZoneRateMaster`, {
    ...data,
  });
};
const getHistoryService = (data) => {
  return axios.post(`${apiUrl}/api/getHistoryTrack`, data);
};
const saveMailLabelService = (data) => {
  return axios.post(`${apiUrl}/api/sendLabelEmail`, data);
};

const getOrderCsvService = (data) => {
  return axios.post(`${apiUrl}/api/getShipmentCSV`, data);
};

const getRecentBatchHistoryService = (data) => {
  return axios.post(`${apiUrl}/api/RecentBatchHistory`, data);
};

const createScanFormService = (url, data) => {
  return axios.post(`${apiUrl}/api/Shipment/CreateScanForm?${url}`, data);
};

const getScanFormListService = (data) => {
  return axios.post(`${apiUrl}/api/Shipment/GetScanFormHistory`, data);
};

const getOrderDetailsByIdService = (orderId) => {
  return axios.get(`${apiUrl}/api/GetPendingOrderDetailsById?id=${orderId}`);
};

const cancelLabelService = (orderId) => {
  return axios.post(`${apiUrl}/api/cancelLabel`, orderId);
};

const packingSlipService = (orderId) => {
  return axios.get(`${apiUrl}/api/PackingSlip?orderId=${orderId}`);
};

export const batchService = {
  createBatchService,
  getOrderBatchService,
  getAllBatchPendingOrderService,
  getAllBatchesService,
  bulkCreateUpdateShipmentService,
  updateOrderStatusService,
  getAllcarriersService,
  saveRateCardService,
  saveRateService,
  getHistoryService,
  saveMailLabelService,
  getMergedPdfService,
  getOrderCsvService,
  getRecentBatchHistoryService,
  createScanFormService,
  getScanFormListService,
  getOrderDetailsByIdService,
  cancelLabelService,
  packingSlipService
};
