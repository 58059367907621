import React, { useEffect, useState } from 'react';
import { Button } from "bootstrap-4-react/lib/components";
import Table from "react-bootstrap/Table";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getConsignmentListing } from '../../../store/actions/inventory.action';
import BatchPagination from '../../batch/BatchPagination';
import { featureSubmodulePermission, inventoryManagement, numOfPage, permssionCreate, permssionEdit, permssionView, SUBF_INBOUND } from '../../../constant';
import LoaderSpinner from '../../common/Loader';
import SearchProduct from '../Product/searchProduct';
import ActionItems from '../../common/showActions';
import InboundFilter from './InboundFilter';
import _ from 'lodash';
import { checkPermission, renderTableRow } from '../../../config/utils';
import { Tooltip as WmsTooltip } from "react-tooltip";
import { inboundBackDetails } from '../../../store/actions/employee';

const InboundComponent = (props) => {
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showFilter, setShowFilter] = useState(false);
    let dataLoad = {
        searchKeyword: '',
        pageIndex: 1,
        pageSize: 30,
        totalPages: 1,
        totalRecords: 0,
        sortColumn: '',
        sortOrder: 'DESCENDING',
        consignmentTypeIdList: [],
        carrierIdList: [],
        countryOfOriginIdList: [],
        consignmentStatusIdList: [],
        trackingNumber: '',
        consignmentNumber: '',
        sellerIdList: [],
        companyIdList: [],
        showFilter: false,
        showSearch: false
    }
    const [filterData, setfilterData] = useState({});
    const [showSearch, setShowSearch] = useState(false);
    const [productList, setProductList] = useState([]);
    const { consignmentData, consignmentStatus } = useSelector((state) => state.InventoryManagement);
    const { inboundPrevState } = useSelector((state) => state.employee);
    const userDetails = useSelector((state) => state.auth.user);
    const [sellerList, setSellerList] = useState([]);
    const [allSellers, setAllSellers] = useState([]);

    useEffect(() => {
        if (showFilter === true) {
            setShowSearch(false);
        } else if (showSearch === true) {
            setShowFilter(false);
        }
    }, [showFilter, showSearch]);

    useEffect(() => {
        if (consignmentData?.TotalRecords) {
            setfilterData({ ...filterData, totalRecords: consignmentData?.TotalRecords, totalPages: Math.ceil(consignmentData?.TotalRecords / filterData.pageSize) });
        }
        if (consignmentData?.Consignments) {
            setProductList(consignmentData?.Consignments);
        }
    }, [consignmentData]);

    useEffect(() => {
        if (!_.isEmpty(inboundPrevState)) {
            setfilterData({ ...filterData, ...inboundPrevState });
        } else {
            setfilterData(dataLoad);
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(filterData)) {
            handleFilter();
        }
    }, [filterData.pageSize, filterData.pageIndex, filterData.sortColumn, filterData.sortOrder]);

    useEffect(() => {
        if (consignmentStatus === 'pending') {
            setLoader(true);
        } else {
            setLoader(false);
        }
    }, [consignmentStatus])

    const handleSortingClass = (columnName, isArrow = false) => {
        if (isArrow) {
            return filterData.sortColumn === columnName && filterData.sortOrder === "ASCENDING" ? "fa fa-arrow-up" : "fa fa-arrow-down";
        }
        return filterData.sortColumn === columnName ? 'active' : 'sortColumn'
    };

    const handleSortingColumn = (event, sortColumn) => {
        event.preventDefault();
        let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
        setfilterData({
            ...filterData,
            sortColumn: sortColumn,
            sortOrder: newSortOrder,
            searchKeyword: filterData.searchTxt,
        });
    };


    const handleSearchFilter = (e, type) => {
        if (type === "clear") {
            let data = JSON.parse(JSON.stringify(dataLoad));
            data.showFilter = true;
            data.pageSize = filterData.pageSize;
            setfilterData(data);
            dispatch(getConsignmentListing(data));
            setSellerList(allSellers);
            return;
        }
        handleFilter();
    }

    const handleFilter = () => {
        let data = {
            ...filterData,
            consignmentTypeIdList: _.map(filterData.consignmentTypeIdList, 'id'),
            carrierIdList: _.map(filterData.carrierIdList, 'id'),
            countryOfOriginIdList: _.map(filterData.countryOfOriginIdList, 'id'),
            consignmentStatusIdList: _.map(filterData.consignmentStatusIdList, 'id'),
            sellerIdList: _.map(filterData.sellerIdList, 'id'),
            companyIdList: _.map(filterData.companyIdList, 'Id')
        }
        dispatch(getConsignmentListing(data));
    }

    const saveCurrentDetails = () => {
        dispatch(inboundBackDetails(filterData));
    }

    const handleNavigateToProduct = (item, type = '') => {
        saveCurrentDetails();
        if (type) {
            navigate(`/inbound/${item}`, {
                state: {

                    data: item,
                    isEdit: type === 'edit' ? true : false
                }
            });
            return
        }
        navigate(`/inbound/${item}`, {
            state: {
                data: item,
                isEdit: true
            }
        });
    };

    const handleHistoryPage = (item) => {
        saveCurrentDetails();
        navigate(`/inbound/history-${item.id}`, {
            state: {
                data: item,
                editable: false,
                type: "inbound",
            },
        });
    }

    const handleFilterToggle = (key, value) => {
        setfilterData({ ...filterData, [key]: value });
    }

    const setCurrentPagePagination = (value) => {
        setfilterData({ ...filterData, pageIndex: value });
    }

    const setTotalNumberOfPage = (value) => {
        setfilterData({ ...filterData, pageSize: value });
    }

    const handleSearchText = (value) => {
        setfilterData({
            ...filterData,
            searchKeyword: value
        })
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    <span>Inbound</span>
                    <div className="top_right_button_container">
                        <div className="top_right_button_container">
                            {checkPermission(inventoryManagement, SUBF_INBOUND, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
                                <button
                                    className="btn cus-seconday-bg-btn top-btn"
                                    onClick={() => handleNavigateToProduct(0)}
                                >Add New</button>
                            )}
                            <Button
                                className="cus-seconday-bg-btn top-btn addNewProduct"
                                onClick={() => handleFilterToggle('showFilter', !filterData.showFilter)}
                            >Filter</Button>
                            <Button
                                className="cus-seconday-bg-btn openSearch"
                                onClick={() => handleFilterToggle('showSearch', !filterData.showSearch)}
                            >Search</Button>
                        </div>
                    </div>
                </div>

                <SearchProduct
                    showSearch={filterData.showSearch}
                    searchTxt={filterData.searchKeyword}
                    setSearchTxt={handleSearchText}
                    handleReset={handleSearchFilter}
                    handleSearchFilter={handleSearchFilter}
                    placeHolderText="Search by Consignment Id, Type, Carrier, Tracking Number or Status"
                />

                {filterData.showFilter && (
                    <InboundFilter
                        showFilter={filterData.showFilter}
                        dispatch={dispatch}
                        useSelector={useSelector}
                        filterData={filterData}
                        setfilterData={setfilterData}
                        handleFilter={handleFilter}
                        handleReset={handleSearchFilter}
                        sellerList={sellerList}
                        setSellerList={setSellerList}
                        allSellers={allSellers}
                        setAllSellers={setAllSellers}
                    />
                )}

                {loader && <LoaderSpinner />}

                <div className="main_content cus-page-content-sec">
                    <div className="main_content_table_history">
                        <div className='tableFixHead incomingTbl'>
                            <Table className={`tableWide table-height cus-left-right-col-layout ${loader ? 'disableTble' : ''}`}>
                                <thead className="tbl-btch">
                                    <tr className="table-rw-batch">
                                        <th>
                                            <div
                                                className={handleSortingClass('consignmentNumber')}
                                                onClick={(e) => handleSortingColumn(e, "consignmentNumber")}
                                                id="consignmentNumber"
                                            >
                                                Consignment Id
                                                <i
                                                    className={handleSortingClass('consignmentNumber', true)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </th>
                                        {userDetails?.Value?.roleName !== 'Seller' && (
                                            <th>Company / Seller Name</th>
                                        )}
                                        <th>
                                            <div
                                                className={handleSortingClass('consignmentType')}
                                                onClick={(e) => handleSortingColumn(e, "consignmentType")}
                                                id="consignmentType"
                                            >
                                                Consignment Type
                                                <i
                                                    className={handleSortingClass('consignmentType', true)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </th>
                                        <th>
                                            <div
                                                className={handleSortingClass('carrierName')}
                                                onClick={(e) => handleSortingColumn(e, "carrierName")}
                                                id="carrierName"
                                            >
                                                Carrier
                                                <i
                                                    className={handleSortingClass('carrierName', true)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </th>
                                        <th>
                                            <div
                                                className={handleSortingClass('trackingNumber')}
                                                onClick={(e) => handleSortingColumn(e, "trackingNumber")}
                                                id="trackingNumber"
                                            >
                                                Tracking Number
                                                <i
                                                    className={handleSortingClass('consignmentStatus', true)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </th>

                                        <th><div
                                            className={handleSortingClass('consignmentStatus')}
                                            onClick={(e) => handleSortingColumn(e, "consignmentStatus")}
                                            id="consignmentStatus"
                                        >
                                            Status
                                            <i
                                                className={handleSortingClass('consignmentStatus', true)}
                                                aria-hidden="true"
                                            ></i>
                                        </div></th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="tbl-bt-body">
                                    {productList.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.consignmentNumber}</td>
                                            {userDetails?.Value?.roleName !== 'Seller' && (
                                                <td>{item.companySellerName}</td>
                                            )}
                                            <td>{item.consignmentType}</td>
                                            <td>{item.carrierName}</td>
                                            <td>{item.trackingNumber}</td>
                                            <td>
                                                {item.consignmentStatus}
                                                {item?.consignmentStatusList && item?.consignmentStatusList.length > 0 &&
                                                    <>
                                                        <i className="fa fa-info-circle ml-2" aria-hidden="true" id={item.id}></i>
                                                        <WmsTooltip
                                                            anchorId={item.id}
                                                            id={item.id}
                                                            place="bottom"
                                                            data-tooltip-html={true}
                                                            className="tooltipWidth"
                                                        >
                                                            <ul>
                                                                {item?.consignmentStatusList && item.consignmentStatusList.map((list) => (
                                                                    <li key={list.name}>{list.name} - {list.status}</li>
                                                                ))}
                                                            </ul>
                                                        </WmsTooltip>
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {checkPermission(inventoryManagement, SUBF_INBOUND, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                                                    <ActionItems type={'View'} item={item} handleAction={() => handleNavigateToProduct(item.id, 'view')} />
                                                )}
                                                {checkPermission(inventoryManagement, SUBF_INBOUND, permssionEdit, featureSubmodulePermission, props.employeePermissions) && (
                                                    <ActionItems type={'Edit'} item={item} handleAction={() => handleNavigateToProduct(item.id, 'edit')} />
                                                )}
                                                <ActionItems type={'History'} item={item} handleAction={() => handleHistoryPage(item)} />
                                            </td>
                                        </tr>
                                    ))}

                                    {consignmentStatus === 'success' && productList.length === 0 && (
                                        <>{renderTableRow(7, "No Result Found...")}</>
                                    )}
                                </tbody>
                            </Table >
                        </div>
                    </div>
                    <BatchPagination
                        gridData={productList}
                        totalRecords={filterData.totalRecords}
                        numberOfPage={filterData.pageSize}
                        numOfPage={numOfPage}
                        loader={loader}
                        currentPage={filterData.pageIndex}
                        totalPages={filterData.totalPages}
                        setCurrentPage={setCurrentPagePagination}
                        setNumberOfPage={setTotalNumberOfPage}
                    />
                </div>
            </div>
        </main>
    );
}

export default InboundComponent;