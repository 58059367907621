import React, { useEffect, useState } from "react";
import { Button } from "bootstrap-4-react/lib/components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { fetchMarginById, updateMargin } from "../../store/actions";
import LoaderSpinner from "../common/Loader";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AccordionList from "../common/AccordionList";
import MarginCarrierFilter from "./CarrierFilter";
import _ from "lodash";
import { convertToFixed } from "../../config/utils";

const CarrierListing = () => {
    const { id } = useParams();
    let payloadDataObj = {
        minMarginPercentage: 0,
        maxMarginPercentage: 0,
        minMarginFlat: 0,
        maxMarginFlat: 0,
        filterCarrierCompanyIds: [],
        filterCarrierServiceIds: [],
        filtercompanySellerId: Number(id)
    };
    const navigate = useNavigate();
    const userDetails = useSelector((state) => state.auth.user);
    const marginByIdResult = useSelector((state) => state.MarginReducer.marginByIdResult);
    const marginByIdResultStatus = useSelector((state) => state.MarginReducer.marginByIdResultStatus);
    const location = useLocation();
    const marginDetails = location?.state?.data;
    const edit = location?.state?.edit;
    const [editable, setEditable] = React.useState(edit);
    const dispatch = useDispatch();
    const [marginData, setMarginData] = useState({});
    const [error, setError] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [payloadData, setPayloadData] = useState(payloadDataObj);
    const [isFormEdited, setIsFormEdited] = useState(false);

    const handleFilter = () => {
        if (handleFilterValidation()) {
            let data = { ...payloadData };
            data.filterCarrierCompanyIds = _.map(data.filterCarrierCompanyIds, "id");
            data.filterCarrierServiceIds = _.map(data.filterCarrierServiceIds, "id");
            dispatch(fetchMarginById(data));
        }
    }

    useEffect(() => {
        if (!showFilter) {
            setError({});
        }
    }, [showFilter]);

    const handleFilterValidation = () => {
        let isValidated = true;
        let allErrors = {};
        if (Number(payloadData.minMarginPercentage) > Number(payloadData.maxMarginPercentage)) {
            isValidated = false;
            allErrors.minMarginPercentage = 'Min margin percent cannot be greater than max margin percent';
        }
        if (Number(payloadData.minMarginFlat) > Number(payloadData.maxMarginFlat)) {
            isValidated = false;
            allErrors.minMarginFlat = 'Min flat margin cannot be greater than max flat margin';
        }

        setError(allErrors);
        return isValidated;
    }

    const handleResetFilter = () => {
        setPayloadData({ ...payloadDataObj });
        setError({});
        dispatch(fetchMarginById(payloadDataObj));
    }

    useEffect(() => {
        if (marginByIdResultStatus === 'pending') {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [marginByIdResultStatus]);

    useEffect(() => {
        getCarrierListing()
    }, []);

    /* istanbul ignore next */
    const getCarrierListing = () => {
        dispatch(fetchMarginById(payloadData));
    }

    useEffect(() => {
        setMarginData(marginByIdResult?.Entity || []);
    }, [marginByIdResult])

    const handleStatusChange = (status, index, serviceIndex) => {
        let data = [...marginData];
        data[index].services[serviceIndex].isActive = status;
        data[index].services[serviceIndex].isModified = true;
        setMarginData(data);
    }

    /**redirect user if not super admin */
    useEffect(() => {
        if (!userDetails?.Value?.isSuperAdmin) {
            navigate("/margin-configuration");
        }
    }, [userDetails]);

    /* istanbul ignore next */
    const handleSave = async (type, item) => {
        let payloadData = {
            ...item,
            marginFlat: item.marginFlatUpdated,
            marginPercentage: item.marginPercentageUpdated,
        };
        setShowLoader(true);
        let updateMarginRes = await Promise.resolve(dispatch(updateMargin(payloadData)));
        if (updateMarginRes && updateMarginRes.data.Status === 200) {
            setShowLoader(false);
            toast.success('Margin updated successfully');
            dispatch(fetchMarginById(id));
        }
    }
    /* istanbul ignore next */
    const editColumn = (index, type) => {
        let data = [...marginData];
        let updatedData = data.map((item) => {
            return {
                ...item,
                isEditMarginPercentage: false,
                isEditMarginFlat: false,
                marginFlatUpdated: item.marginFlat,
                marginPercentageUpdated: item.marginPercentage
            }
        });
        if (type === 'marginPercent') {
            updatedData[index].isEditMarginPercentage = true;
        } else {
            updatedData[index].isEditMarginFlat = true;
        }
        setMarginData(updatedData);
    };

    const handleMarginValue = (e, index, serviceIndex, isBlur = false) => {
        let { value, name } = e.target;
        value = value === "" ? 0 : value
        let data = [...marginData];
        data[index].services[serviceIndex][name] = isBlur ? convertToFixed(value, 2) : value;
        data[index].services[serviceIndex].isModified = true;
        setMarginData(data);
    }

    useEffect(() => {
        if (marginData.length) {
            const findModified = marginData.flatMap(item =>
                item.services.filter(service => service.isModified === true)
            );
            setIsFormEdited(findModified.length ? true : false);
        }
    }, [marginData])

    /* istanbul ignore next */
    const handleSaveMargin = async () => {
        const filteredServices = marginData.flatMap(item =>
            item.services.filter(service => service.isModified === true)
        );
        const updatePayload = filteredServices.map(item => ({ ...item, companySellerId: Number(id) }));
        setShowLoader(true);
        let response = await Promise.resolve(dispatch(updateMargin(updatePayload)));
        if (response?.data?.Status === 200) {
            setShowLoader(false);
            toast.success('Margin details saved successfully!');
        }
        getCarrierListing();
    }

    const handleFilterValue = (value, type) => {
        setPayloadData({ ...payloadData, [type]: value });
    }

    const handleNoRecord = () => {
        return (
            marginByIdResultStatus === 'success' && marginData.length === 0 ? (
                <div className="alignCenter">No Record Found</div>
            ) : (
                <></>
            )
        )
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    <div>Edit Configuration</div>
                    <div className="top_right_button_container">
                        <Button
                            className="cus-seconday-bg-btn top-btn"
                            onClick={() => setShowFilter(!showFilter)}
                        >
                            <i className="fa fa-filter" aria-hidden="true"></i> Filter
                        </Button>
                        <Button
                            className="cus-seconday-bg-btn top-btn editButton"
                            onClick={() => setEditable(true)}
                            disabled={editable}
                        >
                            Edit
                        </Button>
                        <button
                            className="btn cus-seconday-bg-btn top-btn"
                            onClick={handleSaveMargin}
                            disabled={!isFormEdited}
                        >
                            Save
                        </button>
                        <Button
                            className="cus-primary-transparent-btn"
                            onClick={() => {
                                navigate("/margin-configuration");
                            }}
                        >
                            Back
                        </Button>

                    </div>
                </div>

                <div className="mb-3">
                    <Row>
                        <div className="col-lg-3 col-sm-12 mobMb-10">
                            <div className="input-label-Created-date">
                                Company Name : {marginDetails?.companyName}
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-12">
                            <div className="input-label-Created-date">
                                Seller Name : {marginDetails?.sellerName}
                            </div>
                        </div>
                    </Row>
                </div>
                {showLoader && <LoaderSpinner />}

                <MarginCarrierFilter
                    showFilter={showFilter}
                    userDetails={userDetails}
                    handleResetFilter={handleResetFilter}
                    handleFilter={handleFilter}
                    error={error}
                    payloadData={payloadData}
                    setPayloadData={setPayloadData}
                    handleFilterValue={handleFilterValue}
                    isMarginFilter={true}
                />

                <div className="cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className="incoming_wrapper main_content_table incomingTbl marginConfigWrapper">
                            <div>
                                {marginByIdResultStatus === 'success' && marginData.length > 0 ? (
                                    <AccordionList
                                        handleSave={handleSave}
                                        handleMarginValue={handleMarginValue}
                                        userDetails={userDetails}
                                        editColumn={editColumn}
                                        handleStatusChange={handleStatusChange}
                                        data={marginData}
                                        edit={editable}
                                    />
                                ) : handleNoRecord()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default CarrierListing;