import React from "react";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import AlertDialogModal from "../common/AlertDialog";
import { deleteIntegration } from "../../store/actions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { checkPermission, renderTableRow } from "../../config/utils";
import { featureSubmodulePermission, permssionDelete, permssionEdit, settings, SUBF_CSVMAPPING } from "../../constant";

const MappingList = (props) => {
  const {
    gridData,
    getCsvAllIntegration,
    handleSortingColumn,
    handleSortingClass,
    saveCurrentDetails,
    loader
  } = props;

  const [currentSelectedRecord, setCurrentSelectedRecord] = React.useState({});
  const [showDeleteShipDialog, setShowDeleteShipDialog] = React.useState(false);
  const createdBy = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const handleCancelDeleteAlert = () => {
    setCurrentSelectedRecord({});
    setShowDeleteShipDialog(false);
  };

  const handleYesDeleteAlert = async () => {
    let currentOrderSelected = { ...currentSelectedRecord };

    let postData = {
      companyId: createdBy?.Value?.companyId,
      sellerId: createdBy?.Value?.sellerId,
      createdBy: createdBy?.Value?.employeeId,
    };
    let deleteOrderResponse = await Promise.resolve(dispatch(
      deleteIntegration(currentOrderSelected.Id, postData)
    ));
    /* istanbul ignore next */
    if (deleteOrderResponse?.data?.Status === 200) {
      getCsvAllIntegration();
      toast.success("Mapping deleted Successfully");
    } else {
      toast.error("Something went wrong", 1000);
    }
    handleCancelDeleteAlert();
  };

  return (
    <div className="main_content_table incomingTbl tableFixHead">
      <Table className="table-borderless tableMobile">
        <thead border="Green">
          <tr className="ccol">
            <th>Type</th>
            <th>
              <div
                className={handleSortingClass("Name")}
                onClick={(e) => handleSortingColumn(e, "Name")}
              >
                Name
                <i
                  className={handleSortingClass("Name", true)}
                  aria-hidden="true"
                ></i>
              </div>
            </th>

            <th>Active</th>
            <th>
              <div
                className={handleSortingClass("orderQty")}
                onClick={(e) => handleSortingColumn(e, "orderQty")}
              >
                Order Qty
                <i
                  className={handleSortingClass("orderQty", true)}
                  aria-hidden="true"
                ></i>
              </div>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {gridData && gridData.map((i) => {
            return (
              <tr key={i?.id}>
                <td className="td-data">{i.Type}</td>
                <td className="td-data">
                  {checkPermission(settings, SUBF_CSVMAPPING, permssionEdit, featureSubmodulePermission, props.employeePermissions) && (
                    <span onClick={() => saveCurrentDetails()}>
                      <Link to={`/createIntegration/csv-3pl-${i.Id}`}>{i.Name}</Link>
                    </span>
                  )}
                </td>
                <td className="td-data">
                  {i.Active ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  )}
                </td>
                <td className="td-data" align="left">-</td>
                <td className="td-data" align="center">
                  {checkPermission(settings, SUBF_CSVMAPPING, permssionDelete, featureSubmodulePermission, props.employeePermissions) && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                      color="red"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteShipDialog(true);
                        setCurrentSelectedRecord(i);
                      }}
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                      <path
                        fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                      />
                    </svg>
                  )}
                </td>
              </tr>
            );
          })}
          {!loader && gridData.length === 0 && (
            (renderTableRow(5, 'No Record Found'))
          )}
        </tbody>
      </Table>
      <AlertDialogModal
        modalShow={showDeleteShipDialog}
        modalClass={"modalDeleteWrapper"}
        handleCancelDeleteAlert={handleCancelDeleteAlert}
        handleYesDeleteAlert={handleYesDeleteAlert}
      />
    </div>
  );
};

export default MappingList;
