import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { validEmailRe } from '../../constant';
import { createUserKeyAction, fetchAllApiKeyList, getAllSellersList } from '../../store/actions';
import { getSelectList } from '../../config/utils';
import { fetchCompanyFilters, getFilterSellers } from '../../store/actions/rateCard';
import { v4 as uuidv4 } from 'uuid';

const GenerateKeyPopup = (props) => {
    const { showKeyGenratePopup, setShowKeyGenratePopup, numberOfPage } = props;
    const dispatch = useDispatch();
    const [companyList, setCompanyList] = useState([]);
    const [activeSellerList, setActiveSellerList] = useState([]);
    const [allSellers, setAllSellers] = useState([]);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({ keyCompanyId: '', keySellerId: '' });
    const getSellersValue = useSelector(
        (state) => state?.FundManagementReducer?.getSellersDetail?.EntityList
    );
    const { filterCompanies, filterAllSellers } = useSelector((state) => state.RateCardReducer);

    useEffect(() => {
        let companyList = filterCompanies?.Entity?.listCompanyMasterOrdersEntity || [];
        let sellerList = filterAllSellers?.EntityList || [];
        if (companyList.length) {
            setCompanyList(getSelectList(filterCompanies?.Entity?.listCompanyMasterOrdersEntity, 'Id', 'name'));
        }
        if (sellerList.length) {
            const formattedSellers = getSelectList(sellerList, 'id', 'sellerName');
            setAllSellers(formattedSellers);
            setActiveSellerList(getSelectList(sellerList, 'id', 'sellerName'));
        }
    }, [filterCompanies, filterAllSellers]);

    useEffect(() => {
        if (getSellersValue && getSellersValue.length) {
            setActiveSellerList(getSellersValue);
        }
    }, [getSellersValue]);

    useEffect(() => {
        let sellerData = {
            "pageIndex": 1,
            "pageSize": 10,
            "sortColumn": "name",
            "sortOrder": "ASCENDING",
            "companyIdList": [0],
            screenType: 2,
          };
          let data = {
            pageIndex: 1,
            pageSize: 10,
            sortColumn: 'name',
            sortOrder: 'ascending'
        };
        dispatch(fetchCompanyFilters(data));
        dispatch(getFilterSellers(sellerData));
    }, []);

    const validateForm = () => {
        let isValid = true;
        let errorsObj = {};
        if (!Number(formData.keyCompanyId)) {
            isValid = false;
            errorsObj.keyCompanyId = "Required field";
        }
        if (!Number(formData.keySellerId)) {
            isValid = false;
            errorsObj.keySellerId = "Required field";
        }
        if (!formData.keyEmail) {
            isValid = false;
            errorsObj.keyEmail = "Required field";
        }
        if (formData.keyEmail) {
            if (!validEmailRe.test(formData.keyEmail)) {
                isValid = false;
                errorsObj.keyEmail = "Enter valid email address";
            }
        }
        setErrors(errorsObj);
        return isValid;
    };

    const handleGenerateKey = async () => {
        if (validateForm()) {
            let payloadData = {
                "keyEmail": formData.keyEmail,
                "keyCompanyId": Number(formData.keyCompanyId),
                "keySellerId": Number(formData.keySellerId)
            }
            let generateKeyRes = await Promise.resolve(dispatch(createUserKeyAction(payloadData)));
            if (generateKeyRes && generateKeyRes.data && generateKeyRes.data.Status === 200) {
                toast.success(generateKeyRes.data.Entity);
                fetchAllApiKeys();
                setShowKeyGenratePopup(!showKeyGenratePopup);
            } else {
                toast.error(generateKeyRes.data.Entity);
            }
        }
    }

    const fetchAllApiKeys = () => {
        let data = {
            DateFrom: null,
            DateTo: null,
            SellerIdList: [],
            CompanyIdList: [],
            pageSize: numberOfPage,
            pageIndex: 1
        }
        dispatch(fetchAllApiKeyList(data));
    }

    const selectCompanySeller = (event) => {
        let { name, value } = event.target;
        if (name === "keyCompanyId") {
            if (Number(value) === 0) {
                setActiveSellerList(allSellers);
            } else {
                fetchSellers([value]);
            }
            setFormData({ ...formData, [name]: value, keySellerId: '' });
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' })
    }

    const fetchSellers = async (companyList) => {
        if (companyList.length === 0) {
            setActiveSellerList(allSellers);
            return;
        }
        let data = {
            companyIds: companyList,
        };
        let res = await getAllSellersList(data);
        if (res?.data?.Status === 200) {
            let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map((item) => {
                    return {
                        ...item,
                        sellerId: item.id,
                        sellerName: item.newSellerName,
                    };
                }
            );
            setActiveSellerList(dataList);
        }
    }

    return (
        <Modal
            {...props}
            show={showKeyGenratePopup}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => {
                setShowKeyGenratePopup(!showKeyGenratePopup)
            }}
            className='filterShippingListModal generateKey modalDeleteWrapper'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Generate Key
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className='row'>
                    <div className='col-3 formLabel'>Company</div>
                    <div className='col-9'>
                        <select
                            value={formData.keyCompanyId}
                            onChange={selectCompanySeller}
                            className='form-control keyCompanyId'
                            name='keyCompanyId'
                        >
                            <option value={0}>Select</option>
                            {companyList.map((item) => (
                                <option key={item.Id} value={item.Id}>{item.name}</option>
                            ))}
                        </select>
                        <p className="error dialogError">{errors.keyCompanyId}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3 formLabel'>Seller</div>
                    <div className='col-9'>
                        <select
                            key={uuidv4()}
                            value={formData.keySellerId}
                            onChange={selectCompanySeller}
                            className='form-control keySellerId'
                            name='keySellerId'
                        >
                            <option value={0}>Select</option>
                            {activeSellerList.map((item) => (
                                <option key={item.sellerId} value={item.sellerId}>{item.sellerName}</option>
                            ))}
                        </select>
                        <p className="error dialogError">{errors.keySellerId}</p>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-3 formLabel'>Email</div>
                    <div className='col-9'>
                        <input
                            onChange={selectCompanySeller}
                            value={formData.keyEmail}
                            className='form-control keyEmail'
                            name='keyEmail'
                            type='text'
                            placeholder='Enter email address'
                        />
                        <p className="error dialogError">{errors.keyEmail}</p>
                    </div>
                    <div className='modaL_footer'>
                        <button
                            className="btn cus-seconday-bg-btn marginLeft5"
                            id='filterBtnPopup'
                            onClick={handleGenerateKey}
                        >Generate
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
}

export default GenerateKeyPopup;